import React, { useCallback, useEffect, useState } from 'react'
import { useLoading } from '../../../../../hooks/loading'

import CustomDataTable from '../../../../../components/CustomDataTable'
import api from '../../../../../services/api'
import Modal from '../../../../../components/Modal'
import { FormalizationForm } from '../FormalizationForm'
import { CustomModal } from '../CustomModal'
import moment from 'moment'
import { useToast } from '../../../../../hooks/toast'
import { handleErrorMessage } from 'utlis/handleErros'
import { error } from 'console'
import { FormQueryApi } from '../FormQueryApi'
import { FormInitialQuerySim } from '../FormInitialQuerySim'
import {
  SIM_STATUS,
  SIM_TYPES_FORM,
  STEP_SIM
} from 'common/constants/Commercial/Prospects'
import { unMaskCpfCnpj } from 'utlis/mask'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}
type Steps = {
  title: string
  stepID: number
  Proposta?: {
    ProposalId: number
    Checklist: {
      name: string
    }[]
  }
}
type Client = {
  id: string
  cpf: string
  birth_date: string
  contacts: {
    pessoa: {
      bank: {
        bankCode: string
        agencyCode: string
        accountCode: string
        accountType: number
        agencyDigit: string
        accountDigit: string
      }
      cpf: string
      emails: {
        email: string
        ranking: number
        possui_cookie: boolean
      }[]
      celulares: {
        ddd: number
        plus: boolean
        numero: string
        ranking: number
        whatsapp: boolean
      }[]
    }
  }
  name: string
}
type ProspectQueryApi = {
  steps: Steps[]
  client: Client
  prospect?: Client
  query_date: string
  prospect_query_api_id: number
  api_name: string
  status: string
  id: number
  stepID: number
}
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ProspectQueryApi & {
    idUpdate: number
  }
}
export const ListSteps = ({
  initialValues,
  isOpenInModal
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [modalSimulation, setModalSimulation] = useState(false)
  const [modalProposal, setModalProposal] = useState(false)
  const [defaultValues, setDefaultValues] = useState<ProspectQueryApi>()
  const [modalFormalization, setModalFormalization] = useState<any>()
  const [currentItemSimulation, setCurrentItemSimulation] = useState<any>({})
  const [currentItemProposal, setCurrentItemProposal] = useState<any>({})
  const [currentItemQueryApi, setCurrentItemQueryApi] = useState<any>({})
  const [modalQueryApi, setModalQueryApi] = useState(false)
  const dateInputFormat = 'DD/MM/YYYY'
  const { handleOnClose } = isOpenInModal
  const handleSortSteps = (data: Steps[]) => {
    return data.sort(
      (currentStep, nextStep) => nextStep.stepID - currentStep.stepID
    )
  }
  useEffect(() => {
    initialValues.steps = handleSortSteps(initialValues.steps)

    setDefaultValues(initialValues)
  }, [initialValues])

  const handleChecklistSteps = useCallback((data: any) => {
    const proposalSteps = Object.entries(data).map(([key, value]: any) => {
      if (typeof value === 'object') {
        return {
          step: key,
          status: value?.status,
          finished_date: value?.finished_date
        }
      }
      return {
        step: key,
        status: value,
        finished_date: ''
      }
    })
    return proposalSteps
  }, [])

  const loadSimulationData = useCallback(
    async (proscpect_query_id: number) => {
      activeLoading()

      try {
        const response = await api.post('/apis/sim/listOffers', {
          customer: {
            name: initialValues?.client?.name,
            email:
              initialValues?.client?.contacts?.pessoa?.emails[0].email || '',
            dateOfBirthFoundation: moment(
              initialValues?.client?.birth_date,
              dateInputFormat
            ).format('YYYY-MM-DD'),
            document:
              unMaskCpfCnpj(initialValues?.client?.contacts?.pessoa?.cpf) ||
              unMaskCpfCnpj(initialValues?.client.cpf),
            cellPhone: {
              ddd: initialValues?.client?.contacts?.pessoa?.celulares[0]?.ddd,
              number:
                initialValues?.client?.contacts?.pessoa?.celulares[0]?.numero
            }
          },
          hasConcatResult: false,
          prospect_id: Number(initialValues?.client?.id),
          prospect_query_api_id: Number(proscpect_query_id)
        })
        disableLoading()
        return response.data
      } catch (err: any) {
        disableLoading()
        addToast({
          ...handleErrorMessage(err)
        })
      }
    },
    [activeLoading, addToast, disableLoading, initialValues?.client]
  )
  const loadChecklistProposal = useCallback(async () => {
    const findProposalStep = initialValues.steps.find(step =>
      step.title.toLocaleLowerCase().includes('proposta')
    )

    if (!findProposalStep) {
      return
    }
    activeLoading()
    const proposalId = findProposalStep?.Proposta?.ProposalId
    try {
      const proposalDetailsResponse = await api.post(
        '/apis/sim/proposals/listDetails',
        {
          proposalId,
          prospect_query_api_id: initialValues.prospect_query_api_id
        }
      )
      const newSteps = [
        ...handleSortSteps(proposalDetailsResponse.data.prospectQueryApi.result)
      ]
      const findStepProposal = newSteps.find(
        step => step.title.toLowerCase() === STEP_SIM.PROPOSAL.toLowerCase()
      )
      const proposalSteps = handleChecklistSteps(
        findStepProposal?.Proposta?.Checklist
      )
      setDefaultValues({
        ...defaultValues,

        steps: [...newSteps]
      })

      setCurrentItemProposal((oldCurrentItemProposal: any) => ({
        ...oldCurrentItemProposal,
        ...findStepProposal,
        proposalSteps
      }))

      disableLoading()
    } catch (err: any) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao fazer simulação',
        description:
          err?.response?.data?.message?.message ||
          'Houve um error ao fazer simulação, tente novamente mais tarde!'
      })
    }
  }, [
    activeLoading,
    addToast,
    defaultValues,
    disableLoading,
    handleChecklistSteps,
    initialValues?.prospect_query_api_id,
    initialValues?.steps
  ])
  const handleClickOnClose = async () => {
    setModalSimulation(false)
    setModalProposal(false)
    setModalFormalization({})
    await loadChecklistProposal()
  }

  const handleClickOnCloseProposal = async () => {
    setModalProposal(false)
  }

  const handleClickOnCloseFormalization = async () => {
    setModalFormalization({})
  }

  const handleClickOnCloseFormalizationWithUpdateChecklist = async () => {
    setModalFormalization({})
    await loadChecklistProposal()
  }

  const handleClickOnOpenModalView = async (currentItem: any) => {
    const copyItem = { ...currentItem }
    if (
      currentItem?.stepStatus?.toLocaleLowerCase() ===
      SIM_STATUS.FINISHED.toLocaleLowerCase()
    ) {
      addToast({
        title: 'Etapa Já Concluída',
        description:
          'Essa Etapa Já foi Concluída Com Sucesso. Siga Com Os Próximos Passos',
        type: 'info'
      })
      return
    }
    if (
      currentItem?.title?.toLocaleLowerCase() === 'simulação' &&
      currentItem?.stepStatus?.toLocaleLowerCase().includes('sem ofertas')
    ) {
      copyItem.client = {
        ...defaultValues?.client,
        prospect_query_api_id: copyItem.prospect_query_api_id
      }
      setCurrentItemQueryApi(copyItem)
      setModalQueryApi(true)
      return
    }
    if (currentItem?.title?.toLocaleLowerCase() === 'simulação') {
      copyItem.client = { ...defaultValues?.client }

      if (
        moment().diff(
          moment(currentItem.query_date, `${dateInputFormat} HH:mm:ss`).add(
            3,
            'h'
          ),
          'h'
        ) >= 1
      ) {
        addToast({
          type: 'info',
          title: 'Dados de Simulação Expirados',
          description:
            'Os dados de simulação estão expirados e vamos atualizar, aguarde um momento por favor'
        })
        const simulationData = await loadSimulationData(
          currentItem.prospect_query_api_id
        )
        if (simulationData) {
          copyItem.result = { ...copyItem.result, ...simulationData }

          setCurrentItemSimulation(copyItem)
          setModalSimulation(true)
        } else {
          handleClickOnClose()
        }
      } else {
        setCurrentItemSimulation(copyItem)
        setModalSimulation(true)
      }
    }
    if (currentItem?.title?.toLocaleLowerCase() === 'proposta') {
      const proposalSteps = handleChecklistSteps(
        currentItem?.Proposta?.Checklist
      )
      setCurrentItemProposal({
        ...currentItem,
        proposalSteps
      })
      setModalFormalization({
        ...Object.assign(
          {},
          ...proposalSteps.map(item => ({ [item.step]: false }))
        )
      })
      setModalProposal(true)
    }
  }

  const handleClickOnOpenModalFormalization = (currentItem: any) => {
    if (
      currentItem?.status?.toLocaleLowerCase() ===
      SIM_STATUS.FINISHED.toLocaleLowerCase()
    ) {
      addToast({
        title: 'Etapa Já Concluída',
        description:
          'Essa Etapa Já foi Concluída Com Sucesso. Siga Com Os Próximos Passos',
        type: 'info'
      })
      return
    }
    setModalFormalization({
      [currentItem.step]: true
    })
  }
  const handleClickModalQueryOnClose = async () => {
    handleOnClose?.()
    setModalQueryApi(false)
  }

  return (
    <div>
      <CustomDataTable
        customItems={defaultValues?.steps || []}
        headers={[
          { name: 'Titulo', field: 'title', sortable: true },
          {
            name: 'Status',
            field: 'stepStatus',
            sortable: true
          },
          { name: 'Ações', field: 'actions', sortable: false }
        ]}
        actions={[
          {
            name: 'view',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            onClick: item =>
              handleClickOnOpenModalView({
                ...item,
                query_date: initialValues.query_date,
                prospect_query_api_id: initialValues.prospect_query_api_id,
                prospect: { ...initialValues.client }
              })
          }
        ]}
      />
      {modalSimulation && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalSimulation}
          pageTitle={'Visualizar'}
          styles={{ padding: '30px 5px', overflow: 'auto' }}
          sectionStyle={{ minWidth: '80vw' }}
          Children={
            <CustomModal
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                handleParentOnClose: () => {
                  handleOnClose?.()
                }
              }}
              type={initialValues?.api_name}
              initialValues={currentItemSimulation}
            />
          }
        />
      )}
      {modalProposal && (
        <Modal
          onClickButtonCancel={handleClickOnCloseProposal}
          isOpenModal={modalProposal}
          pageTitle={'Visualizar'}
          styles={{ padding: '30px 5px', overflow: 'auto' }}
          sectionStyle={{ minWidth: '80vw' }}
          Children={
            <CustomDataTable
              customItems={currentItemProposal?.proposalSteps}
              headers={[
                { name: 'Etapa', field: 'step', sortable: true },
                {
                  name: 'Status',
                  field: 'status',
                  sortable: true
                },
                {
                  name: 'Concluído em',
                  field: 'finished_date',
                  sortable: true,
                  custom: true
                },
                { name: 'Ações', field: 'actions', sortable: false }
              ]}
              customHeaders={[
                {
                  name: 'Concluído em',
                  field: 'finished_date',
                  sortable: true,
                  element: (item: { finished_date: string }) => (
                    <p>
                      {item?.finished_date
                        ? moment(item?.finished_date).format(
                            dateInputFormat + ' HH:mm:ss'
                          )
                        : ''}
                    </p>
                  )
                }
              ]}
              actions={[
                {
                  name: 'view',
                  title: 'Visualizar',
                  spanIcon: 'fa fa-edit',
                  onClick: item => handleClickOnOpenModalFormalization(item)
                }
              ]}
            />
          }
        />
      )}
      {currentItemProposal?.proposalSteps?.map(
        (proposalStep: { step: string }) =>
          modalFormalization[proposalStep.step] && (
            <Modal
              key={`${Math.random() * 9999}`}
              onClickButtonCancel={handleClickOnCloseFormalization}
              isOpenModal={modalFormalization[proposalStep.step]}
              pageTitle={proposalStep.step}
              styles={{ padding: '30px 5px', overflow: 'auto' }}
              sectionStyle={{ minWidth: '80vw' }}
              Children={
                <FormalizationForm
                  type={proposalStep.step}
                  isOpenInModal={{
                    handleOnClose:
                      handleClickOnCloseFormalizationWithUpdateChecklist,
                    handleClickOnCloseParent: handleOnClose,
                    idParent: Number(initialValues?.id)
                  }}
                  initialValues={currentItemProposal}
                />
              }
            />
          )
      )}
      {modalQueryApi && (
        <Modal
          onClickButtonCancel={handleClickModalQueryOnClose}
          isOpenModal={modalQueryApi}
          pageTitle="Consultar Banco"
          styles={{ padding: '30px 5px', overflow: 'auto' }}
          Children={
            <FormInitialQuerySim
              initialValues={currentItemQueryApi.client}
              isOpenInModal={{ handleOnClose: handleClickModalQueryOnClose }}
            />
          }
        />
      )}
    </div>
  )
}
