import React, { useCallback } from 'react'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from 'hooks/auth'
import { MANAGER_ROLES, ROLE_PARTNER_TEAM } from 'common/constants'
interface Menu {
  id: number
  parent_id?: number
  method?: string
  name: string
  url?: string
  permission: boolean
  children?: Menu[]
  type: string
  icon: string
}
interface AsideMenu {
  menuChildren?: Menu
}



export const AsideMenuMain: React.FC<AsideMenu> = ({ menuChildren }) => {
  const { user } = useAuth()
  const mountMenu = useCallback((menu: Menu[], subMenus?: Menu[]) => {
    return menu?.map(item => {
      if(item.id === 15 && !MANAGER_ROLES.includes(user?.role_id)) {
        return null
      }
      if(user?.role?.team === ROLE_PARTNER_TEAM && !MANAGER_ROLES.includes(user?.role_id)) {
        if(item.url.includes('commercial/prospects/reconciliationPartners')) {
          return null
        }
      }
      if (item?.children?.length) {
        return (
          <AsideMenuItemWithSub
            key={`${Math.random() * 9999}`}
            to={item.url}
            title={item.name}
            fontIcon={item.icon}
          >
            {mountMenu(item.children)}
          </AsideMenuItemWithSub>
        )
      }
      return (
        <AsideMenuItem
          key={`${Math.random() * 9999}`}
          to={item.url}
          title={item.name}
          hasBullet={true}
        />
      )
    })
  }, [])
  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {menuChildren?.name}
          </span>
        </div>
      </div>
      {mountMenu(menuChildren?.children)}
    </>
  )
}
