import { FaTrashAlt } from 'react-icons/fa'
import styled from 'styled-components'

export const FormContent = styled.div``

export const IconRemove = styled(FaTrashAlt).attrs(() => ({
  size: '16px'
}))`
  margin: 0 auto;
  cursor: pointer;
`
