import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import { toolsList } from '../domain/tools'
import { breadcrumbList } from '../domain/breadcrumb'
import { headers } from '../domain/headers'
import { FilterContainer } from './style'
import { useLoading } from 'hooks/loading'
import api from 'services/api'
import { useToast } from 'hooks/toast'
import { useHistory } from 'react-router-dom'
import { Datalist } from 'components/Datalist'
import CustomDataTable from 'components/CustomDataTable'
import { Loading } from 'components/Loading'
import { Date as DatePicker } from '../../../../components/Form/date'
import { IMaskInput } from 'react-imask'
import moment from 'moment'

type OnChangePageProps = {
  partner_id?: number
  page?: number
  perPage?: number
  startDate?: Date
  endDate?: Date
}
const List: React.FC = () => {
  const { addToast } = useToast()
  const history = useHistory()
  const { activeLoading, disableLoading } = useLoading()
  const [partners, setPartners] = useState([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const [partnerValue, setPartnerValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [rows, setRows] = useState<IReportPartnerResponse['items']>([])
  const [totalItems, setTotalItems] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState<number>()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const getPartners = useCallback(async () => {
    activeLoading()
    try {
      const partnersResponse = await api.get('/commercial/partners/block')
      const filterPartners = partnersResponse.data?.filter(
        (partner: { id: number; person: { name: string }; active: boolean }) =>
          partner.active
      )
      const partnersSelect = filterPartners?.map(
        (partner: {
          id: number
          store_name: string
          person_cnpj_cpf: string
          active: boolean
        }) =>
          partner.active && {
            value: partner.id,
            name: `${partner.store_name} - ${partner.person_cnpj_cpf}`,
            id: partner.id
          }
      )
      setPartners(partnersSelect)
      disableLoading()
    } catch (error: any) {
      const statusCode = error?.response.status
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title:
          statusCode === 403
            ? 'Você não tem permissão'
            : 'Error ao carregar os parceiros',
        description:
          statusCode === 403
            ? 'Você não tem permissão para acessar essa página'
            : 'Houve um error ao carregar os parceiros, tente novamente mais tarde!'
      })
      if (path.includes('update')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history])

  useEffect(() => {
    getPartners()
  }, [getPartners])

  const handleLoadRows = useCallback(
    async (data?: OnChangePageProps) => {
      setIsLoading(true)
      try {
        const responseReport = await api.post<IReportPartnerResponse>(
          '/commercial/reports/partner',
          {
            page: data?.page || 1,
            perPage: data?.perPage || 100,
            partner_id: data?.partner_id === 0 ? undefined : partnerValue.id,
            start_date: data?.startDate === null ? null : startDate,
            end_date: data?.endDate === null ? null : endDate
          }
        )
        setTotalItems(responseReport.data.totalItems)
        setRows(responseReport.data.items)
        setCurrentPage(undefined)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    },
    [endDate, partnerValue?.id, startDate]
  )

  const handleExportRows = useCallback(
    async (data?: OnChangePageProps) => {
      const date = moment().format('DD-MM-YYYY')
      setIsLoading(true)
      try {
        const responseReport = await api.post<IReportPartnerResponse>(
          '/commercial/reports/partner/export',
          {
            page: data?.page || 1,
            perPage: data?.perPage || 100,
            partner_id: data?.partner_id === 0 ? undefined : partnerValue.id,
            start_date: data?.startDate === null ? null : startDate,
            end_date: data?.endDate === null ? null : endDate
          }
        )
        const blob = new Blob(['\ufeff' + responseReport.data], {
          type: 'text/csv;charset=utf-8"'
        })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = `relatório-parceiros-${date}.csv`
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    },
    [endDate, partnerValue?.id, startDate]
  )

  const handleBlockPartner = useCallback(
    async (partner?: any) => {
      setIsLoading(true)
      try {
        await api.put<IReportPartnerResponse>(
          `/commercial/partners/block/${partner.partner_id}`
        )
        await handleLoadRows({ page: currentPage })
        await getPartners()
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    },
    [currentPage, getPartners, handleLoadRows]
  )

  const handleAllowPartner = useCallback(
    async (partner?: any) => {
      setIsLoading(true)
      try {
        await api.put<IReportPartnerResponse>(
          `/commercial/partners/allow/${partner.partner_id}`
        )
        await handleLoadRows({ page: currentPage })
        await getPartners()
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    },
    [currentPage, getPartners, handleLoadRows]
  )

  useEffect(() => {
    handleLoadRows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Loading isActive={isLoading} />
      <Container
        pageTitle="Listagem"
        breadcrumb={breadcrumbList}
        tools={[
          ...toolsList,
          {
            name: 'Exportar',
            to: '#',
            icon: '',
            hasParams: false,
            color: 'btn-light-primary',
            handleOnClick: handleExportRows
          }
        ]}
      >
        <FilterContainer>
          <div className="row">
            <Datalist
              data={partners}
              label="Parceiro"
              setValue={setPartnerValue}
              value={partnerValue}
            />
            <DatePicker
              name="start_date"
              className="col-3"
              label="Data inicio"
              placeholderText="DD/MM/AAAA"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format('DD/MM/YYYY')
                  }}
                  parse={value => {
                    return moment(value, 'DD/MM/YYYY').toDate()
                  }}
                />
              }
              onChange={date => {
                setStartDate(date)
              }}
              controlled
            />
            <DatePicker
              name="end_date"
              className="col-3"
              label="Data final"
              placeholderText="DD/MM/AAAA"
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format('DD/MM/YYYY')
                  }}
                  parse={value => {
                    return moment(value, 'DD/MM/YYYY').toDate()
                  }}
                />
              }
              onChange={date => {
                setEndDate(date)
              }}
              controlled
            />
          </div>
          <footer>
            <button
              className="btn btn-light-primary"
              onClick={async () => {
                setPartnerValue({
                  name: '',
                  value: 0,
                  id: 0
                })
                setCurrentPage(1)
                setStartDate(undefined)
                setEndDate(undefined)
                await handleLoadRows({
                  partner_id: 0,
                  startDate: null,
                  endDate: null
                })
              }}
            >
              LIMPAR
            </button>
            <button
              className="btn btn-primary"
              onClick={async () => {
                setCurrentPage(1)
                await handleLoadRows()
              }}
            >
              BUSCAR
            </button>
          </footer>
          <div className="separator" />
        </FilterContainer>
        <CustomDataTable
          customItems={rows}
          headers={headers}
          onChangePage={handleLoadRows}
          currentTotalItems={totalItems}
          customCurrentPage={currentPage}
          pagination
          customHeaders={[
            {
              name: 'Ações',
              field: 'actions',
              sortable: false,
              element: item => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    <div>
                      {item.partner_deleted_at ? (
                        <span
                          className="fa fa-check text-primary fa-lg"
                          onClick={() => handleAllowPartner(item)}
                        ></span>
                      ) : (
                        <span
                          className="fa fa-ban text-danger fa-lg"
                          onClick={() => handleBlockPartner(item)}
                        ></span>
                      )}
                    </div>
                  </div>
                )
              }
            }
          ]}
        />
      </Container>
    </>
  )
}
export default List
