/* eslint-disable no-unreachable-loop */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLoading } from '../../../../../hooks/loading'
import CustomDataTable from '../../../../../components/CustomDataTable'
import Modal from '../../../../../components/Modal'
import { SimulationForm } from '../SimulationForm'
import {
  CREDCESTA_STATUS,
  LEMIT_COLUMN_NAME_DDD,
  LEMIT_COLUMN_NAME_NUMBER,
  LEMIT_COLUMN_NAME_WHATSAPP,
  SIM_STATUS,
  STEP_SIM
} from 'common/constants/Commercial/Prospects'
import { BRL, phoneMask, unMaskCpfCnpj } from 'utlis/mask'
import { APIS_NAMES, dateInputFormat, SPACING_ROW_FORM } from 'common/constants'
import { FormProposalCredcesta } from '../FormProposalCredcesta'
import moment from 'moment'
import Form, { Input } from 'components/Form'
import { ApisOptions, TypePerson } from '../FormInitialQuerySim'
import api from 'services/api'
import { useUpdateDataTable } from 'hooks/dataTable'
import { useToast } from 'hooks/toast'
import { handleErrorMessage } from 'utlis/handleErros'
import { FormQueryApi } from '../FormQueryApi'
import { FormProposalFgts } from '../FormProposalFgts'

type IsOpenInModalProps = {
  handleParentOnClose?: () => void
  handleOnClose: () => void
}
type Steps = {
  title: string
}

type Customer = {
  name: string
  email: string
  dateOfBirthFoundation: string
  document: string
  cellPhone: {
    ddd: number
    number: string
  }
}

type Contacts = {
  pessoa: {
    nome: string
    nome_mae: string
    sexo: string
    cpf: string
    ocupacao?: string
    emails: {
      email: string
      ranking: number
      possui_cookie: boolean
    }[]
    celulares: {
      ddd: number
      plus: boolean
      numero: string
      ranking: number
      whatsapp: boolean
    }[]
  }
}

type ProspectData = {
  id?: number
  agreement_group_id?: string
  prospect_query_api_id?: string
  name?: string
  birth_date?: string
  cpf?: string
  cnpj?: string
  customer?: Customer
  contacts?: Contacts
}
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?:
    | any
    | (any & {
        idUpdate: number
      })
  type: string
  status?: string
}
export const CustomModal = ({
  initialValues,
  type,
  status,
  isOpenInModal
}: TypesFormProps): JSX.Element => {
  const typeToLowerCase = type.toLocaleLowerCase()
  const statusToLowerCase = status?.toLocaleLowerCase()

  const verifyIfTypeIsEqualToSim =
    typeToLowerCase === APIS_NAMES.SIM.toLowerCase()

  const verifyIfTypeIsEqualToCredcesta =
    typeToLowerCase === APIS_NAMES.CREDCESTA.toLowerCase()

  const verifyIfTypeIsEqualToFgts =
    typeToLowerCase === APIS_NAMES.FGTS.toLowerCase()

  const verifyIfStatusIsEqualToInReview =
    statusToLowerCase === CREDCESTA_STATUS.IN_REVIEW.toLowerCase()

  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const { updateDataTable } = useUpdateDataTable()
  const [modalView, setModalView] = useState(false)
  const [defaultValues, setDefaultValues] = useState<any>()
  const [withoutResultSim, setWithoutResultSim] = useState(true)
  const [withoutResultCredcesta, setWithoutResultCredcesta] = useState(true)
  const handleParentOnClose = isOpenInModal?.handleParentOnClose
  const { handleOnClose } = isOpenInModal
  const queryApiUrls = useMemo(
    () => ({
      1: '/apis/sim/listOffers',
      2: '/apis/lemit/query',
      3: '/apis/credcesta/queryInstallmentsPerCpf'
    }),
    []
  )

  const handleSelectedApiDefaultValues = useMemo(
    () => ({
      [ApisOptions.SIM]: (data: ProspectData) => {
        return {
          ...data,
          customer: {
            name: data?.name,
            email: data?.contacts?.pessoa?.emails[0]?.email || '',
            dateOfBirthFoundation: data?.birth_date,
            document: data?.contacts?.pessoa?.cpf,
            cellPhone: {
              ddd: data?.contacts?.pessoa?.celulares[0]?.ddd,
              number: phoneMask(data?.contacts?.pessoa?.celulares[0]?.numero, {
                whitoutDDD: true
              })
            }
          }
        }
      }
    }),
    []
  )

  useEffect(() => {
    activeLoading()
    if (initialValues) {
      setWithoutResultSim(
        !!initialValues?.steps?.[0] && initialValues?.return !== undefined
          ? !initialValues?.return
          : false
      )
      setWithoutResultCredcesta(
        initialValues?.return !== undefined ? !initialValues?.return : false
      )

      if (typeToLowerCase === APIS_NAMES.LEMIT.toLowerCase()) {
        setDefaultValues(initialValues)
      } else {
        setDefaultValues({
          ...initialValues,
          steps: initialValues?.steps?.reduce(
            (previousStep: Steps, nextStep: Steps) => ({
              ...previousStep,
              [nextStep.title]: nextStep
            }),
            {}
          ),
          ...handleSelectedApiDefaultValues[ApisOptions.SIM]?.(
            initialValues?.client
          )
        })
      }
      disableLoading()
    }
  }, [
    activeLoading,
    disableLoading,
    handleSelectedApiDefaultValues,
    initialValues,
    typeToLowerCase
  ])
  const serializedKey = useCallback((value: string) => {
    const removedUnderline = value.split('_').join(' ')
    return removedUnderline
      .trim()
      .toLowerCase()
      .replace(/\w\S*/g, letter =>
        letter.replace(/^\w/, firstLetter => firstLetter.toUpperCase())
      )
  }, [])

  const handleClickOnOpenModalView = (currentItem: any) => {
    initialValues.type = currentItem.key
    setModalView(true)
  }

  const handleClickOnClose = () => {
    setModalView(false)
  }

  const onSubmitForm = useCallback(
    async (data: ProspectData) => {
      const selectedApiKeyof = 1
      const dataForm: { [key: string]: any } = {
        [ApisOptions.SIM]: {
          customer: {
            ...data?.customer,
            dateOfBirthFoundation: moment(
              defaultValues?.customer?.dateOfBirthFoundation,
              'DD/MM/YYYY'
            )
              .add(3, 'hours')
              .format('YYYY-MM-DD'),
            document: unMaskCpfCnpj(defaultValues?.cpf),
            name: defaultValues?.name
          },
          prospect_id: defaultValues?.id,
          prospect_query_api_id: defaultValues?.prospect_query_api_id,
          hasConcatResult: false
        },
        [ApisOptions.LEMIT]: {
          document: defaultValues?.cpf,
          prospect_id: defaultValues?.id,
          type: TypePerson.PF
        }
      }
      activeLoading()

      try {
        await api.post(
          queryApiUrls[selectedApiKeyof],
          dataForm[selectedApiKeyof]
        )
        handleOnClose()
        disableLoading()
        updateDataTable()
        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Registro criado com sucesso'
        })
      } catch (error: any) {
        addToast({
          ...handleErrorMessage(error)
        })
        disableLoading()
        // updateDataTable()
      }
    },
    [
      activeLoading,
      addToast,
      defaultValues?.cpf,
      defaultValues?.customer?.dateOfBirthFoundation,
      defaultValues?.id,
      defaultValues?.name,
      defaultValues?.prospect_query_api_id,
      disableLoading,
      handleOnClose,
      queryApiUrls,
      updateDataTable
    ]
  )

  if (verifyIfTypeIsEqualToFgts) {
    return (
      <FormProposalFgts
        typeForm="create"
        isOpenInModal={{
          handleOnClose: isOpenInModal?.handleOnClose,
          handleParentOnClose: () => {
            handleParentOnClose?.()
          }
        }}
        initialValues={initialValues}
      />
    )
  }

  if (verifyIfTypeIsEqualToSim && withoutResultSim) {
    return (
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
          <Input
            name="customer.email"
            label="Email"
            rules={{
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Email inválido'
              }
            }}
            className="col-6"
          />
        </div>
        <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
          <Input
            name="customer.cellPhone.ddd"
            label="DDD"
            mask={{
              mask: '99'
            }}
            controlled
            className="col-2"
            rules={{ required: true }}
          />
          <Input
            name="customer.cellPhone.number"
            label="Número"
            mask={{
              mask: '99999-9999'
            }}
            controlled
            className="col-2"
            rules={{
              required: true,
              minLength: { message: 'Número Invalido', value: 10 }
            }}
          />
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Consultar
          </button>
        </div>
      </Form>
    )
  }
  if (verifyIfTypeIsEqualToCredcesta && withoutResultCredcesta) {
    return (
      <FormQueryApi
        defaultApi="3"
        initialValues={initialValues?.prospect}
        isOpenInModal={{
          handleOnClose: handleClickOnClose
        }}
      />
    )
  }

  if (verifyIfTypeIsEqualToCredcesta && verifyIfStatusIsEqualToInReview) {
    return (
      <div>
        <div className="row mb-5">
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="id" className=" fw-bold fs-6">
                Cód. NSU
              </label>
              <p className="fs-6 text-gray-800">
                {defaultValues?.selectedInstallment?.nsu}
              </p>
            </div>
          </div>

          <div className="col-2">
            <div className="form-group">
              <label htmlFor="id" className=" fw-bold fs-6">
                Valor Financiado
              </label>
              <p className="fs-6 text-gray-800">
                {BRL(
                  defaultValues?.selectedInstallment?.valorFinanciado
                ).format()}
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="id" className=" fw-bold fs-6">
                Valor Principal
              </label>
              <p className="fs-6 text-gray-800">
                {BRL(
                  defaultValues?.selectedInstallment?.valorPrincipal
                ).format()}
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                Valor da Parcela
              </label>
              <p className=" fs-6 text-gray-800">
                {BRL(defaultValues?.selectedInstallment?.valorParcela).format()}
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="id" className=" fw-bold fs-6">
                Quantidade de Parcelas
              </label>
              <p className="fs-6 text-gray-800">
                {defaultValues?.selectedInstallment?.numeroParcelas}
              </p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                Juros a.m
              </label>
              <p className=" fs-6 text-gray-800">
                {BRL(defaultValues?.selectedInstallment?.taxaCliente).format()}
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                Juros a.m CET
              </label>
              <p className=" fs-6 text-gray-800">
                {BRL(defaultValues?.selectedInstallment?.taxaCETMes).format()}
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                1º vencimento
              </label>
              <p className=" fs-6 text-gray-800">
                {moment(defaultValues?.selectedInstallment?.data1Vcto).format(
                  dateInputFormat
                )}
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                Ultimo vencimento
              </label>
              <p className=" fs-6 text-gray-800">
                {moment(defaultValues?.selectedInstallment?.dataUltVcto).format(
                  dateInputFormat
                )}
              </p>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="role_id" className=" fw-bold fs-6">
                Status
              </label>
              <p className="fs-6 text-gray-800">{defaultValues?.status}</p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="role_id" className=" fw-bold fs-6">
                Criado em
              </label>
              <p className="fs-6 text-gray-800">{defaultValues?.created_at}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (
    status &&
    verifyIfTypeIsEqualToSim &&
    (statusToLowerCase !== SIM_STATUS.WITH_PENDING.toLowerCase() ||
      statusToLowerCase !== SIM_STATUS.PENDING.toLowerCase())
  ) {
    return (
      <div>
        <div className="row mb-5">
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="id" className=" fw-bold fs-6">
                Cód. Proposta
              </label>
              <p className="fs-6 text-gray-800">
                {
                  defaultValues?.steps?.[STEP_SIM.PROPOSAL]?.Proposta
                    ?.ProposalId
                }
              </p>
            </div>
          </div>
          <div className="col-1">
            <div className="form-group">
              <label htmlFor="id" className=" fw-bold fs-6">
                Tipo
              </label>
              <p className="fs-6 text-gray-800">
                {
                  defaultValues?.steps?.[STEP_SIM.PROPOSAL]?.Produto?.[
                    'Tipo Produto'
                  ]
                }
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                Valo Total
              </label>
              <p className="fs-6 text-gray-800">
                {BRL(
                  defaultValues?.steps?.[STEP_SIM.SIMULATION]
                    ?.simulationResponse?.cet?.valorTotalPagoFinal
                ).format()}
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="id" className=" fw-bold fs-6">
                Valor Contratado
              </label>
              <p className="fs-6 text-gray-800">
                {
                  defaultValues?.steps?.[STEP_SIM.PROPOSAL]?.Produto?.[
                    'Valor Contratado'
                  ]
                }
              </p>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                Valor da Parcela
              </label>
              <p className="fs-6 text-gray-800">
                {BRL(
                  defaultValues?.steps?.[STEP_SIM.SIMULATION]
                    ?.simulationResponse?.installmentValue
                ).format()}
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                Valor da Ultima Parcela
              </label>
              <p className="fs-6 text-gray-800">
                {BRL(
                  `${
                    defaultValues?.steps?.[STEP_SIM.SIMULATION]
                      ?.simulationResponse?.lastInstallmentValue
                  }`
                ).format()}
              </p>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-2">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                Juros CET ao Mês
              </label>
              <p className="fs-6 text-gray-800">
                {BRL(
                  Number(
                    defaultValues?.steps?.[STEP_SIM.SIMULATION]
                      ?.simulationResponse?.cet?.cETMensal
                  ) * 100,
                  {
                    symbol: '%',
                    pattern: '# !'
                  }
                ).format()}
              </p>
            </div>
          </div>
          <div className="col-1">
            <div className="form-group">
              <label htmlFor="name" className=" fw-bold fs-6">
                IOF
              </label>
              <p className="fs-6 text-gray-800">
                {BRL(
                  Number(
                    defaultValues?.steps?.[STEP_SIM.SIMULATION]
                      ?.simulationResponse?.cet?.imposto
                      ?.pcTotalImpostosFinanciado
                  ) * 100,
                  {
                    symbol: '%',
                    pattern: '# !'
                  }
                ).format()}
              </p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="role_id" className=" fw-bold fs-6">
                Status
              </label>
              <p className="fs-6 text-gray-800">{defaultValues?.status}</p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="role_id" className=" fw-bold fs-6">
                Criado em
              </label>
              <p className="fs-6 text-gray-800">{defaultValues?.created_at}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (verifyIfTypeIsEqualToSim) {
    return (
      <>
        <CustomDataTable
          customItems={[
            // initialValues?.repescResponse?.cpauto,
            initialValues?.repescResponse?.cppuro
            // initialValues?.repescResponse?.cpfgts
          ]}
          headers={[
            { name: 'Tipo', field: 'key', sortable: true, custom: true },
            {
              name: 'Limite Disponivel',
              field: 'title',
              sortable: true,
              custom: true
            },
            {
              name: 'Quantidade de Parcelas',
              field: 'message',
              sortable: true
            },
            { name: 'Ações', field: 'actions', sortable: false }
          ]}
          actions={[
            {
              name: 'view',
              title: 'Visualizar',
              spanIcon: 'fa fa-bank',
              onClick: item => handleClickOnOpenModalView(item)
            }
          ]}
          customHeaders={[
            {
              name: 'Tipo',
              field: 'key',
              sortable: true,
              element: item => {
                if (item?.key?.toLocaleLowerCase() === 'cppuro') {
                  return <p>Empréstimo Simples</p>
                }
                if (item?.key?.toLocaleLowerCase() === 'cpauto') {
                  return <p>Empréstimo Com Garantia de Carro</p>
                }
                if (item?.key?.toLocaleLowerCase() === 'cpfgts') {
                  return <p>Antecipação FGTS</p>
                }
              }
            },
            {
              name: 'Limite Disponível',
              field: 'title',
              sortable: true,
              element: item => {
                if (!item?.title?.length) {
                  return <p>Sem limite</p>
                }
                if (item?.title?.length === 2) {
                  return <p>{item?.title + '.000,00'}</p>
                }
                return item?.title
              }
            }
          ]}
        />
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalView}
          pageTitle={'Visualizar'}
          styles={{ padding: '30px 5px', overflow: 'auto' }}
          sectionStyle={{ minWidth: '80vw' }}
          Children={
            <SimulationForm
              typeForm="create"
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                handleParentOnClose: () => {
                  handleParentOnClose?.()
                }
              }}
              initialValues={initialValues}
            />
          }
        />
      </>
    )
  }
  if (verifyIfTypeIsEqualToCredcesta) {
    return (
      <FormProposalCredcesta
        typeForm="create"
        isOpenInModal={{
          handleOnClose: isOpenInModal?.handleOnClose,
          handleParentOnClose: () => {
            handleParentOnClose?.()
          }
        }}
        initialValues={initialValues}
      />
    )
  }

  return (
    <div>
      {defaultValues?.map?.(
        (item: {
          child: any[]
          title:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
          type: string
          value:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
        }) => {
          const hasSubItems = !!item?.child?.length
          const subItems = item?.child
          const subItemsIsObject = item?.child?.every(
            (subItem: any) => typeof subItem === 'object'
          )
          const subHeaders = hasSubItems
            ? Object.keys(subItems[0])
                .filter(
                  key =>
                    key.toLocaleLowerCase() !== LEMIT_COLUMN_NAME_DDD &&
                    key.toLocaleLowerCase() !== 'donotdisturb'
                )
                .map(key => {
                  if (key.toLocaleLowerCase() === LEMIT_COLUMN_NAME_NUMBER) {
                    if (item?.child[0]?.[LEMIT_COLUMN_NAME_WHATSAPP]) {
                      return {
                        name: serializedKey(key),
                        field: key,
                        sortable: true,
                        custom: true
                      }
                    }
                  }
                  return {
                    name: serializedKey(key),
                    field: key,
                    sortable: true
                  }
                })
            : []
          return (
            <div key={Math.random() * 1000 + 1} className="row mb-2">
              <h1 style={{ textTransform: 'capitalize' }}>{item?.title}</h1>
              {hasSubItems ? (
                item.type === 'array' ? (
                  subItemsIsObject ? (
                    <CustomDataTable
                      customItems={item?.child}
                      headers={subHeaders}
                      customHeaders={[
                        {
                          name: 'Número',
                          field: 'numero',
                          sortable: true,
                          element: item => {
                            const fullNumber = `${item?.[LEMIT_COLUMN_NAME_DDD]}${item?.[LEMIT_COLUMN_NAME_NUMBER]}`
                            return (
                              <p>
                                {item?.doNotDisturb && (
                                  <button
                                    className="btn py-0"
                                    style={{ cursor: 'default' }}
                                  >
                                    <span className="fa-solid fa-ban fa-xl text-danger"></span>
                                  </button>
                                )}
                                {phoneMask(fullNumber)}

                                {!!item?.[LEMIT_COLUMN_NAME_WHATSAPP] && (
                                  <button
                                    className="btn py-0"
                                    disabled={item?.doNotDisturb}
                                    onClick={() => {
                                      const a = document.createElement('a')
                                      a.href = `https://api.whatsapp.com/send?phone=${fullNumber}&text=`
                                      a.target = '_blank'
                                      a.click()
                                      a.remove()
                                    }}
                                  >
                                    <span
                                      className={`fab fa-whatsapp fa-xl ${
                                        item?.doNotDisturb
                                          ? ''
                                          : ' text-primary'
                                      }`}
                                    ></span>
                                  </button>
                                )}
                              </p>
                            )
                          }
                        }
                      ]}
                    />
                  ) : (
                    item?.child?.map((subItem: any) => {
                      return (
                        <div className="col" key={Math.random() * 1000 + 1}>
                          <p
                            className="fw-bolder fs-6 text-gray-800"
                            style={{
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize'
                            }}
                          >
                            {subItem.value}
                          </p>
                        </div>
                      )
                    })
                  )
                ) : (
                  item?.child?.map((subItem: any) => {
                    return (
                      <div className="col" key={Math.random() * 1000 + 1}>
                        <label
                          className=" fw-bold fs-6"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {subItem.title}
                        </label>
                        <p
                          className="fw-bolder fs-6 text-gray-800"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {subItem.value}
                        </p>
                      </div>
                    )
                  })
                )
              ) : (
                <p
                  className="fw-bolder fs-6 text-gray-800"
                  style={{ textTransform: 'capitalize' }}
                >
                  {item?.value}
                </p>
              )}
            </div>
          )
        }
      )}
    </div>
  )
}
