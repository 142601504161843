export const filterDuplicatedsObject = (data: any[], fieldUnique: string) => {
  const uniqueItems: any[] = []

  const unique = data.filter(item => {
    const isDuplicate = uniqueItems.includes(item[fieldUnique]?.toLowerCase())
    if (!isDuplicate) {
      uniqueItems.push(item[fieldUnique]?.toLowerCase())

      return true
    }

    return false
  })

  return unique
}
