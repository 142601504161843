export const apiCreate = (): string =>
  '/commercial/prospects/reconciliationPartners'
export const apiList = (id?: string): string =>
  !id
    ? '/commercial/reconciliationPartners/'
    : `/commercial/reconciliationPartners/view/${id}`
export const apiUpdate = (id: string): string =>
  `/commercial/reconciliationPartners/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/reconciliationPartners/delete/${id}`
