export const headers = [
  { name: 'Convênio', field: 'agreementGroup.name', sortable: true },
  { name: 'Tipo', field: 'api_name', sortable: true, custom: true },
  {
    name: 'Qtd. solicitado',
    field: 'quantity_requested',
    sortable: false
  },
  { name: 'Qtd. Realizado', field: 'quantity_realized', sortable: true },
  {
    name: 'Exportado em',
    field: 'exported_at',
    sortable: false,
    custom: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]
