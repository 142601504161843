import clsx from 'clsx'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useLayout, usePageData } from '../../assets/layout/core'
import { DefaultTitle } from '../../assets/layout/components/header/page-title/DefaultTitle'

const Toolbar: FC = () => {
  const { classes } = useLayout()
  const { pageTools } = usePageData()
  return (
    <div
      className="d-flex container-fluid justify-content-end mb-5 toolbar"
      id="kt_toolbar"
    >
      <div
        id="kt_toolbar_container"
        className={clsx(
          classes.toolbarContainer.join(' '),
          'd-flex flex-stack '
        )}
      >
        {/* <DefaultTitle /> */}

        <div className="d-flex align-items-center py-1">
          <div className="">
            {pageTools.map((tool, index) => (
              <Link
                key={tool.name}
                to={tool.to}
                className={`btn btn-sm btn ${
                  tool.color || 'btn-light-primary'
                } fw-bolder`}
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
                style={{
                  marginLeft: index !== 0 ? '5px' : '0px',
                  ...tool.style
                }}
                onClick={e => {
                  if (tool.handleOnClick) {
                    e.preventDefault()
                    tool.handleOnClick(tool)
                  }
                }}
              >
                {tool.icon && <i className={tool.icon} />}
                {tool.name}
                {tool.iconRight && <i className={tool.iconRight} />}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export { Toolbar }
