import styled from 'styled-components'

export const Container = styled.div`
  .table-bordered {
    table,
    th,
    td {
      border: 1px solid #ebedf3 !important;
    }
  }
`

export const LinkContainer = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #666;

  div {
    display: flex !important;
    align-items: center;
  }

  .link {
    margin-left: 5px;
    cursor: pointer;
  }
`
