import React from 'react'
import PrivateRoutes from '../Route'
import UpdatePasswordSim from '../../pages/UpdatePasswordSim'
import { CustomSwitch } from '../../components/CustomSwitch'

export const UpdatePasswordSimRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes
      path="/updatePasswordSim"
      exact
      component={UpdatePasswordSim}
    />
  </CustomSwitch>
)
