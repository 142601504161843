import React, { useEffect } from 'react'
import { Content } from '../../../assets/layout/components/Content'

import { AsideDefault } from '../../../assets/layout/components/aside/AsideDefault'

import { HeaderWrapper } from '../../../assets/layout/components/header/HeaderWrapper'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../../../assets/ts/components'

import { ScrollTop } from '../../../assets/layout/components/ScrollTop'

import { Toolbar } from '../../../components/Toolbar'

const DefaultLayout: React.FC = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <HeaderWrapper />

          <div
            id="kt_content"
            className="content d-flex flex-column flex-column-fluid"
          >
            <Toolbar />
            <Content>{children}</Content>
          </div>
        </div>
      </div>
      <ScrollTop />
    </div>
  )
}

export default DefaultLayout
