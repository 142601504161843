import React, { useCallback, useEffect } from 'react'
import { useAuth } from '../../hooks/auth'
import { useParams, useHistory } from 'react-router-dom'
const Autenticate = (): JSX.Element => {
  const { signIn } = useAuth()
  const history = useHistory()
  const { data } = useParams<{ data: string }>()
  const onSubmit = useCallback(async () => {
    try {
      const currentDate = Date.now().valueOf() / 1000
      const decode64 = atob(data)
      const { username, password, expires } = JSON.parse(decode64)
      if (expires < currentDate) {
        throw new Error('This token is expired')
      }
      await signIn({
        username,
        password
      })
      window.location.href = 'https://cedibrasistema.com.br'
    } catch (error) {
      history.push('/')
    }
  }, [data, history, signIn])

  useEffect(() => {
    async function handleSubmit() {
      await onSubmit()
    }
    handleSubmit()
  }, [onSubmit])

  return <div></div>
}
export default Autenticate
