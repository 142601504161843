/* eslint-disable quote-props */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import moment from 'moment'
import {
  BRL,
  convertValueMaskInNumberWithTwoZero,
  phoneMask,
  unMaskCpfCnpj,
  unMaskPhone,
  unMaskZipCode
} from '../../../../../utlis/mask'
import { IMaskInput } from 'react-imask'
import { ID_CONVENIO_CREDCESTA } from '../../../../../common/constants/Commercial/Prospects'
import { handleErrorMessage } from 'utlis/handleErros'
type Option = {
  qtdParcelas: number
  vlLimite: number
  vlLimiteParcela: number
}
type QueryData = {
  cpf: string
  nome: string
  idConvenio: string
  convention?: Convention
  matricula: string
  vlMultiploSaque?: number
  limiteUtilizado?: number
  limiteTotal?: number
  limiteDisponivel?: number
  vlLimiteParcela?: number
  limiteParcelaUtilizado?: number
  limiteParcelaDisponivel?: number
  vlMultiploCompra?: number
  vlLimiteCompra?: number
  prazo?: string
  vlLimite?: string
  opcoes: Option[]
}

type Customer = {
  name: string
  email: string
  dateOfBirthFoundation: string
  document: string
  cellPhone: {
    ddd: number
    number: string
  }
}

type Contacts = {
  pessoa: {
    nome: string
    nome_mae: string
    sexo: string
    cpf: string
    emails: {
      email: string
      ranking: number
      possui_cookie: boolean
    }[]
    celulares: {
      ddd: number
      plus: boolean
      numero: string
      ranking: number
      whatsapp: boolean
    }[]
  }
}

type ProspectData = {
  id?: number
  agreement_group_id?: string
  prospect_query_api_id?: string
  name?: string
  birth_date?: string
  cpf?: string
  cnpj?: string
  customer?: Customer
  contacts?: Contacts
}

export enum TypePerson {
  PF = 'pessoa'
}

export enum ApisOptions {
  SIM = '1',
  LEMIT = '2',
  CREDCESTA = '3'
}

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ProspectData
  typeForm?: 'sim' | 'lemit'
}

type Convention = {
  idConvenio?: number
  nome?: string
  limiteManual?: boolean
  limiteSaque?: number
  limiteCompra?: number
  percentualMargemSaque?: number
  percentualMargemCompra?: number
  averbacaoObrigatoria?: boolean
  obrigatorioIdAverbacao?: boolean
  orgaos?: {
    codigo: string
    descricao: string
  }[]
  logos?: {
    cdLogo: string
    descricaoGestor: string
  }[]
  multiplicadores?: {
    valorMultiploSaque: number
    prazo: number
    idadeMinima: number
    idadeMaxima: number
    tipoDescricao: string
  }[]
}
type IsOpenInModalProps = {
  idParent?: number
  handleOnClose: () => void
}
const dateInputFormat = 'DD/MM/YYYY'
export const FormInitialQuerySim = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<ProspectData>()
  const { handleOnClose } = isOpenInModal

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues,
        customer: {
          name: initialValues?.name,
          email: initialValues?.contacts?.pessoa?.emails[0]?.email || '',
          dateOfBirthFoundation: initialValues?.birth_date,
          document: initialValues?.contacts?.pessoa?.cpf,
          cellPhone: {
            ddd: initialValues?.contacts?.pessoa?.celulares[0]?.ddd,
            number: phoneMask(
              initialValues?.contacts?.pessoa?.celulares[0]?.numero,
              {
                whitoutDDD: true
              }
            )
          }
        }
      })
    }
  }, [initialValues])

  const onSubmitForm = useCallback(
    async (data: ProspectData) => {
      const dataForm = {
        customer: {
          ...data?.customer,
          dateOfBirthFoundation: moment(
            defaultValues?.customer?.dateOfBirthFoundation,
            'DD/MM/YYYY'
          )
            .add(6, 'hours')
            .format('YYYY-MM-DD'),
          document: unMaskCpfCnpj(defaultValues?.cpf),
          name: defaultValues?.name
        },
        prospect_id: defaultValues?.id,
        prospect_query_api_id: defaultValues?.prospect_query_api_id,
        hasConcatResult: false
      }
      activeLoading()

      try {
        await api.post('/apis/sim/listOffers', dataForm)
        handleOnClose()
        disableLoading()
        updateDataTable()
        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Registro criado com sucesso'
        })
      } catch (error: any) {
        addToast({
          ...handleErrorMessage(error)
        })
        handleOnClose()
        disableLoading()
        updateDataTable()
      }
    },
    [
      activeLoading,
      addToast,
      defaultValues?.cpf,
      defaultValues?.customer?.dateOfBirthFoundation,
      defaultValues?.id,
      defaultValues?.name,
      defaultValues?.prospect_query_api_id,
      disableLoading,
      handleOnClose,
      updateDataTable
    ]
  )
  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <div className="form">
        <div>
          <div className="row mb-2">
            <Input
              name="customer.email"
              label="Email"
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email inválido'
                }
              }}
              className="col-6"
            />
          </div>
          <div className="row mb-2">
            <Input
              name="customer.cellPhone.ddd"
              label="DDD"
              mask={{
                mask: '99'
              }}
              controlled
              className="col-2"
              rules={{ required: true }}
            />
            <Input
              name="customer.cellPhone.number"
              label="Número"
              mask={{
                mask: '99999-9999'
              }}
              controlled
              className="col-2"
              rules={{
                required: true,
                minLength: { message: 'Número Invalido', value: 10 }
              }}
            />
          </div>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <button type="submit" className="btn btn-primary">
          Consultar
        </button>
      </div>
    </Form>
  )
}
