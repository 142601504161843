import { Loading } from 'components/Loading'
import { TooltipComponent } from 'components/TooltipComponent'
import { cpf, cnpj } from 'cpf-cnpj-validator'
import React, {
  InputHTMLAttributes,
  ReactElement,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useState
} from 'react'
import {
  Controller,
  FormProvider,
  RegisterOptions,
  useForm,
  useFormContext,
  UseFormRegister
} from 'react-hook-form'
import { IconBaseProps } from 'react-icons'
import { FiAlertCircle } from 'react-icons/fi'
import IMaskInput, { ReactInputMask } from 'react-input-mask'
import api from '../../services/api'
import { genericMaskWithTwoZeroWithPoint } from '../../utlis/mask'
import { Container, Error, SelectContainer, TextAreaContainer } from './styles'

export default function Form({
  defaultValues,
  children,
  onSubmit,
  hasErrors
}: any) {
  const methods = useForm({ defaultValues, shouldUnregister: true })
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState: { errors }
  } = methods

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  if (hasErrors) hasErrors(errors)

  const registeredField = useCallback(
    (child: ReactElement) => {
      if (child.props.controlled) {
        child.props.value && setValue(child.props.name, child.props.value)
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      if (child.props.fullControlled) {
        setValue(child.props.name, child.props.value)
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      if (child.props.price) {
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                  },
                  value: genericMaskWithTwoZeroWithPoint(field.value),
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      return React.createElement(child.type, {
        ...{
          ...child.props,
          register,
          errors,
          key: child.props.name
        }
      })
    },
    [control, errors, register, setValue]
  )

  const buildChildren = useCallback(
    (children: ReactElement, key = 0): any => {
      if (Array.isArray(children)) {
        return children.map((child: ReactElement, index) => {
          return buildChildren(child, index)
        })
      }

      if (children?.props?.children) {
        const childCopy = React.cloneElement(children, {
          key,
          children: buildChildren(children.props.children)
        })
        return childCopy
      }
      return children?.props?.name ? registeredField(children) : children
    },
    [registeredField]
  )

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {buildChildren(children)}
      </form>
    </FormProvider>
  )
}

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  register?: UseFormRegister<any>
  name: string
  rules?: RegisterOptions
  hasError?: any
  errors?: any
  label?: string
  labelError?: string
  controlled?: boolean
  fullControlled?: boolean
  price?: boolean
  icon?: React.ComponentType<IconBaseProps>
  inputOptions?: InputHTMLAttributes<HTMLInputElement>
  tooltip?: {
    title?: string
    message: string
  }
  CustomLabelContainer?: React.FC
  mask?: {
    mask: any
    type?: string
    prefixInputsData?: string
    excludeFields?: string[]
    customFields?: {
      name: string
      type: 'street' | 'district' | 'city' | 'state'
    }[]
  }
}

export function Input({
  register,
  name,
  label,
  labelError,
  icon: Icon,
  rules,
  hasError,
  errors,
  className,
  mask,
  inputOptions,
  tooltip,
  type,
  CustomLabelContainer,
  ...rest
}: InputProps) {
  const [loading, setLoading] = useState(false)
  const methods = useFormContext()
  const handleOnChangeInputMask = useCallback(
    async event => {
      if (methods) {
        const { setError, clearErrors, setValue } = methods
        const type = mask?.type
        const watchField = event.target.value

        setValue(name, watchField, {
          shouldDirty: true
        })
        clearErrors(name)
        if (type === 'cpf') {
          if (cpf.isValid(watchField)) {
            clearErrors(name)
          } else {
            setError(
              name,
              {
                type: 'focus',
                message: 'CPF Inválido'
              },
              {
                shouldFocus: true
              }
            )
          }
        }
        if (type === 'cnpj') {
          if (cnpj.isValid(watchField)) {
            clearErrors(name)
          } else {
            setError(
              name,
              {
                type: 'focus',
                message: 'Cnpj Inválido'
              },
              {
                shouldFocus: true
              }
            )
          }
        }
        if (type === 'zipCode') {
          const zipCodeSearch = watchField?.replaceAll(/[.\-/]/g, '')

          if (zipCodeSearch.length === 8 && !loading) {
            setLoading(true)
            try {
              const addressResponse = await api.get(
                `/apis/sim/domains/zipcode/${zipCodeSearch}`
              )
              const customFields = [
                {
                  name: 'street',
                  type: 'street'
                },
                {
                  name: 'district',
                  type: 'district'
                },
                {
                  name: 'city',
                  type: 'city'
                },
                {
                  name: 'state',
                  type: 'state'
                }
              ].map(field => {
                const findCustomField = mask?.customFields?.find(
                  customField => customField.type === field.type
                )
                if (findCustomField) {
                  return findCustomField
                }
                return field
              })

              customFields.forEach(({ name, type }) => {
                if (
                  !mask?.excludeFields?.includes(mask?.prefixInputsData + name)
                ) {
                  setValue(
                    mask?.prefixInputsData + name,
                    addressResponse.data?.[type],
                    {
                      shouldDirty: true
                    }
                  )
                  clearErrors(mask?.prefixInputsData + name)
                }
              })
            } catch (error) {}
            setLoading(false)
          }
        }
      }
      return event.target.value
    },
    [
      loading,
      mask?.customFields,
      mask?.excludeFields,
      mask?.prefixInputsData,
      mask?.type,
      methods,
      name
    ]
  )
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error
  return type === 'radio' ? (
    <>
      <Loading isActive={loading} />
      <Container
        erro={error || hasError?.error}
        className={
          className + `${type === 'radio' ? ' d-flex align-items-center' : ''}`
        }
      >
        {Icon && <Icon size={20} />}
        <div>
          <input
            type="radio"
            {...(register && register(name, rules))}
            {...rest}
            className="form-control form-control-solid"
            {...inputOptions}
          />

          {error?.message && (
            <Error title={error.message}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
          {hasError?.error && (
            <Error title={hasError?.message}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
          {error?.type === 'required' && (
            <Error title={`O campo ${labelError || label} é obrigatório`}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
        </div>
        {label && (
          <label
            htmlFor={name}
            className="col-form-label fw-bold fs-6 d-flex ms-2"
          >
            {label}
            {tooltip && (
              <div className="w-24px">
                <TooltipComponent
                  label={tooltip?.title || ''}
                  message={tooltip.message}
                />
              </div>
            )}
          </label>
        )}
      </Container>
    </>
  ) : (
    <>
      <Loading isActive={loading} />
      <Container
        erro={error || hasError?.error}
        className={className + `${type === 'radio' ? ' d-flex' : ''}`}
      >
        {Icon && <Icon size={20} />}
        {label && (
          <>
            {CustomLabelContainer ? (
              <CustomLabelContainer>
                {label}
                {tooltip && (
                  <div className="w-24px">
                    <TooltipComponent
                      label={tooltip?.title || ''}
                      message={tooltip.message}
                    />
                  </div>
                )}
              </CustomLabelContainer>
            ) : (
              <label
                htmlFor={name}
                className="col-form-label fw-bold fs-6 d-flex"
              >
                {label}
                {tooltip && (
                  <div className="w-24px">
                    <TooltipComponent
                      label={tooltip?.title || ''}
                      message={tooltip.message}
                    />
                  </div>
                )}
              </label>
            )}
          </>
        )}
        <div>
          {mask ? (
            <IMaskInput
              {...(rest as ReactInputMask)}
              onChange={handleOnChangeInputMask}
              mask={mask.mask}
              maskChar={null}
              className="form-control form-control-solid"
            >
              {(inputProps: any) => (
                <input
                  // {...(register &&
                  //   register(name, {
                  //     ...rules
                  //     // onChange:
                  //   }))}
                  {...inputProps}
                  //
                  type="text"
                />
              )}
            </IMaskInput>
          ) : (
            <input
              {...(register && register(name, rules))}
              {...rest}
              className="form-control form-control-solid"
              {...inputOptions}
              type={type}
            />
          )}

          {error?.message && (
            <Error title={error.message}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
          {hasError?.error && (
            <Error title={hasError?.message}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
          {error?.type === 'required' && (
            <Error title={`O campo ${labelError || label} é obrigatório`}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
        </div>
      </Container>
    </>
  )
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  register?: UseFormRegister<any>
  options: Array<{
    value: string | number
    name: string | number
  }>
  name: string
  label?: string
  rules?: RegisterOptions
  errors?: any
  controlled?: boolean
  blank?: boolean
}

export function Select({
  register,
  options,
  name,
  label,
  rules,
  errors,
  blank,
  className,
  ...rest
}: SelectProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error
  return (
    <SelectContainer erro={error} className={className}>
      {label && (
        <label htmlFor={name} className="col-form-label fw-bold fs-6">
          {label}
        </label>
      )}
      <div>
        <select
          {...(register && register(name, rules))}
          {...rest}
          className="form-select form-select-solid fw-boldl"
        >
          {blank && (
            <option key={0} value="" selected>
              Selecione
            </option>
          )}
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </SelectContainer>
  )
}

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  register?: UseFormRegister<any>
  name: string
  rules?: RegisterOptions
  errors?: any
  label?: string
}

export function Textarea({
  register,
  name,
  label,
  rules,
  errors,
  className,
  ...rest
}: TextareaProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error

  return (
    <TextAreaContainer erro={error} className={className}>
      {label && (
        <label htmlFor={name} className="col-form-label fw-bold fs-6">
          {label}
        </label>
      )}
      <div>
        <textarea
          {...(register && register(name, rules))}
          {...rest}
          className="form-control form-control-lg form-control-solid"
        />
      </div>
    </TextAreaContainer>
  )
}
