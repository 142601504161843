import React, { useCallback, useEffect, useState } from 'react'
import { useToast } from 'hooks/toast'
import Container from 'components/Container'
import Form, { Input } from 'components/Form'
import { Loading } from 'components/Loading'
import api from 'services/api'

type ApiSettingProps = {
  sim_password: string
  sim_user: string
}
const UpdatePasswordSIm = (): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [apiSetting, setApiSetting] = useState<ApiSettingProps | null>()
  const { addToast } = useToast()

  const onSubmit = useCallback(
    async data => {
      setLoading(true)
      try {
        await api.put('/commercial/apisSettings/updatePasswordSim/1', {
          ...data
        })
        addToast({
          title: 'Senha alterada com sucesso',
          description: 'Senha alterada com sucesso',
          type: 'success'
        })
      } catch (error: any) {
        console.log(error)
        addToast({
          title: 'Erro ao atualizar',
          description: 'Erro ao atualizar a senha',
          type: 'error'
        })
      }
      setLoading(false)
    },
    [addToast]
  )

  const loadApiSetting = useCallback(async () => {
    try {
      const responseApiSetting = await api.get('/commercial/apisSettings/1')
      setApiSetting({ ...responseApiSetting.data })
    } catch (error) {
      addToast({
        title: 'Erro ao carregar dados',
        description: 'Ocorreu um erro ao carregar os dados',
        type: 'error'
      })
    }
  }, [addToast])

  useEffect(() => {
    loadApiSetting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <Loading isActive />
  }
  return (
    <Container pageTitle="Listagem" breadcrumb={[]} tools={[]}>
      <Form
        onSubmit={onSubmit}
        defaultValues={{
          sim_user: apiSetting?.sim_user,
          sim_password: apiSetting?.sim_password
        }}
      >
        <div className="row mb-5">
          <Input className="col-md-3" name="sim_user" label="Usuário" />
          <Input className="col-md-3" name="sim_password" label="Senha" />
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </Form>
    </Container>
  )
}
export default UpdatePasswordSIm
