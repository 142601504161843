import styled from 'styled-components'

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 1rem;

  > div {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1fr 1fr 0.7fr;
    margin-left: 1rem;
  }
  .formContainerFourColumns {
    grid-template-columns: 1fr 1fr 0.33fr 0.35fr;
  }
`
