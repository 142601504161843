import React, { useEffect, useState, useMemo } from 'react'
import Pagination from 'react-bootstrap/Pagination'

interface TablePaginationProps {
  total: number
  itemsPerPage: number
  currentPage: number
  onPageChange(page: number): void
}

const PaginationComponent: React.FC<TablePaginationProps> = ({
  total = 0,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange
}) => {
  const [totalPages, setTotalPages] = useState(0)
  const [minPages, setMinPages] = useState(1)
  const [maxPages, setMaxPages] = useState(5)

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0) {
      setTotalPages(Math.ceil(total / itemsPerPage))
    }
  }, [total, itemsPerPage])

  function handleChangePage(page: number) {
    if (page === maxPages) {
      setMinPages(page)
      setMaxPages(maxPages + 5)
    }
    if (page < minPages) {
      setMaxPages(maxPages - 5)
      setMinPages(page - 4)
    }
    onPageChange(page)
  }

  const paginationItems = useMemo(() => {
    const pages = []

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handleChangePage(i)}
        >
          {i}
        </Pagination.Item>
      )
    }

    return pages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages, currentPage])

  if (totalPages === 0) return null

  return (
    <Pagination>
      {currentPage === 1 ? (
        <Pagination.Prev disabled={true} />
      ) : (
        <Pagination.Prev onClick={() => handleChangePage(currentPage - 1)} />
      )}
      {paginationItems.map(
        (item, index) => index + 1 >= minPages && index + 1 <= maxPages && item
      )}
      {currentPage === totalPages ? (
        <Pagination.Next disabled={true} />
      ) : (
        <Pagination.Next onClick={() => handleChangePage(currentPage + 1)} />
      )}
    </Pagination>
  )
}

export default PaginationComponent
