import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Config/Users/Permission/List'
import Update from '../../pages/Config/Users/Permission/Update'
import Create from '../../pages/Config/Users/Permission/Create'
import View from '../../pages/Config/Users/Permission/View'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PermissionsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/users/permissions" exact component={List} />
    <PrivateRoutes
      path="/users/permissions/update/:id"
      component={Update}
      exact
    />
    <PrivateRoutes path="/users/permissions/create" component={Create} exact />
    <PrivateRoutes path="/users/permissions/view/:id" component={View} exact />
  </CustomSwitch>
)
