import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  .search-container {
    position: absolute;
    margin-right: 2%;
    z-index: 1;
  }
`
