import React from 'react'
import RolesList from '../../pages/Config/Users/Roles/List'
import RolesUpdate from '../../pages/Config/Users/Roles/Update'
import RolesCreate from '../../pages/Config/Users/Roles/Create'
import RolesView from '../../pages/Config/Users/Roles/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const RolesRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/users/roles" exact component={RolesList} />
    <Route path="/users/roles/create" component={RolesCreate} exact />
    <Route path="/users/roles/view/:id" component={RolesView} exact />
    <Route path="/users/roles/update/:id" component={RolesUpdate} exact />
  </CustomSwitch>
)
