import styled from 'styled-components'

export const Container = styled.div`
  max-width: 300px;
  min-width: 300px;
  flex-grow: 1;
  overflow: hidden;
  .fs-medium {
    font-size: 1.5rem;
  }
`
