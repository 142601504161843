import { SPACING_ROW_FORM } from 'common/constants'
import { Loading } from 'components/Loading'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { IMaskInput } from 'react-imask'
import { useHistory } from 'react-router-dom'
import { handleErrorMessage } from 'utlis/handleErros'
import Form, { Input, Select } from '../../../../../components/Form'
import { Date as Datepicker } from '../../../../../components/Form/date'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'
import api from '../../../../../services/api'
import {
  BRL,
  convertValueMaskInNumberWithTwoZero,
  genericMaskWithTwoZeroWithPoint,
  unMaskCpfCnpj,
  unMaskPhone
} from '../../../../../utlis/mask'
import { FormContainer } from './styles'

type IsOpenInModalProps = {
  handleParentOnClose?: () => void
  handleOnClose: () => void
}
type BankOptions = {
  description: string
  id: string
}

type ProspectContacts = {
  customer?: {
    name: string
    document: string
    dateOfBirthFoundation: string
    email: string
    address?: {
      zipCode: string
      street: string
      number: string
      complement: string
      neighborhood: string
      state: string
      city: string
      type: string
    }
    natural?: {
      gender: string
      mother: string
      monthlyIncome: string | number
      patrimony: string | number
      documentNumber: string
      documentId: number
      documentState: string
      dateOfIssue: string
      issuingBodyDocument: string
      maritalStatus: number
      birthState: string
      birthCity: string
      nationality: string
      occupation: number
      cellphone?: {
        ddd: string
        number: string
      }
    }
    incomeList?: {
      incomeType: string
      income: number
    }[]
  }
}

type ProspectData = {
  atxResponse: {
    paymentForms?: {
      description: string
      id: number
    }[]
    firstInstallment?: {
      maximumDate: string
      minimumDate: string
      recommendedDate: string
    }
    entry?: {
      maximumValue: number
      minimumValue: number
      recommendedValue: number
    }
    terms?: {
      times: number
    }[]
  }
  uuid?: string
  prospect_query_api_id?: number
  client?: {
    id?: number
    cpf?: string
    name?: string
    birth_date?: string
    contacts?: {
      pessoa: {
        cpf: string
        nome: string
        sexo: string
        fixos: {
          ddd: number
          plus: boolean
          numero: string
          ranking: number
          whatsapp: boolean
        }[]
        renda: number
        carros: {
          placa: string
          marca: string
          ano_fabricacao: number
          ano_modelo: number
          renavan: string
          chassi: string
          data_licenciamento: string
          ranking: number
        }[]
        emails: {
          email: string
          ranking: number
          possui_cookie: boolean
        }[]
        falecido: boolean
        nome_mae: string
        ocupacao?: string
        vinculos: {
          cpf_vinculo: string
          nome_vinculo: string
          tipo_vinculo: string
        }[]
        celulares: {
          ddd: number
          plus: boolean
          numero: string
          ranking: number
          whatsapp: boolean
        }[]
        enderecos: {
          uf: string
          cep: string
          tipo: string
          bairro: string
          cidade: string
          numero: string
          ranking: number
          endereco: string
          logradouro: string
          complemento?: string
          tipo_logradouro: string
          titulo_logradouro?: string
        }[]
        situacao_cpf: string
        risco_credito: {
          cpf_cnpj: string
          score_credito: string
        }
        data_nascimento: string
        participacao_societaria: {
          nome: string
          cnpj: string
          capital_social: number
          participacao_socio: number
          data_fundacao: string
          situacao_cadastral: string
        }[]
      }
      data_consulta: string
    }
  }
  type?: string
  payment?: {
    totalValue: string
    incomeValue: string
    firstPaymentDate: string
  }
  insurance?: {
    id: string
    insurancerCode: string
  }
  fleet?: {
    id: string
  }
  vehicle?: {
    taxi: string | boolean
    adapted: string | boolean
    vehicleTypeId?: string
  }
  offers?: {
    key: string
    tab: number
    offer: string
    sourceChannel: string
  }[]
  repescResponse: {
    [key: string]: {
      title: string
      message: string
    }
  }
}
type OptionsProps = { name: number | string; value: number | string }
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ProspectData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

const handleSimulationValue = (value: string) => {
  if (value?.length === 2) {
    return `${value}.000,00`
  }
  return value
}

export const SimulationForm = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [isLoading, setIsLoading] = useState(true)
  // const [isLoading, setIsLoading] = useState(true)
  const [defaultValues, setDefaultValues] = useState<ProspectData>()
  const [contractValues, setContractValues] = useState<ProspectContacts>()
  const [firstPaymentDate, setFirstPaymentDate] = useState<Date>()
  const [minFirstPaymentDate, setMinFirstPaymentDate] = useState<Date>()
  const [maxFirstPaymentDate, setMaxFirstPaymentDate] = useState<Date>()
  const [issueDocumentDate, setIssueDocumentDate] = useState<Date>()
  const [minTotalValue, setMinTotalValue] = useState(510)
  const [maxTotalValue, setMaxTotalValue] = useState(36000)
  const [totalValue, setTotalValue] = useState('')
  const [incomeValue, setIncomeValue] = useState('')
  const [installmentQuantity, setInstallmentAmount] = useState<OptionsProps[]>(
    []
  )
  const [inputTotalValueError, setInputTotalValueError] = useState({
    error: false,
    message: ''
  })
  const [paymentForms, setPaymentForms] = useState<OptionsProps[]>([])
  const [nationalities, setNationalities] = useState<OptionsProps[]>([])
  const [occupations, setOccuppations] = useState<OptionsProps[]>([])
  const [addressCities, setAddressCities] = useState<OptionsProps[]>([])
  const [birthCities, setBirthCities] = useState<OptionsProps[]>([])
  const [banks, setBanks] = useState<OptionsProps[]>([])
  const [simulationData, setSimulationData] = useState<any>()
  const { activeLoading, disableLoading } = useLoading()
  const stateFields: { address: string; birth: string } = {
    address: 'customer.address.state',
    birth: 'customer.natural.birthState'
  }
  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
      setContractValues({
        customer: {
          name: initialValues?.client?.name,
          document: initialValues?.client?.contacts?.pessoa?.cpf,
          email:
            initialValues?.client?.contacts?.pessoa?.emails[0]?.email || '',
          dateOfBirthFoundation: initialValues?.client?.birth_date,
          address: {
            zipCode:
              initialValues?.client?.contacts?.pessoa?.enderecos?.[0]?.cep,
            street:
              initialValues?.client?.contacts?.pessoa?.enderecos?.[0]
                ?.logradouro,
            number:
              initialValues?.client?.contacts?.pessoa?.enderecos?.[0]?.numero,
            complement:
              initialValues?.client?.contacts?.pessoa?.enderecos?.[0]
                ?.complemento,
            neighborhood:
              initialValues?.client?.contacts?.pessoa?.enderecos?.[0]?.bairro,
            state: '',
            city:
              initialValues?.client?.contacts?.pessoa?.enderecos?.[0]?.cidade ||
              '',
            type:
              initialValues?.client?.contacts?.pessoa?.enderecos?.[0]
                ?.tipo_logradouro || ''
          },
          natural: {
            gender: initialValues?.client?.contacts?.pessoa?.sexo,
            mother: initialValues?.client?.contacts?.pessoa?.nome_mae,
            monthlyIncome: '0,00',
            patrimony: '',
            documentNumber: '',
            documentId: 3,
            documentState: '',
            dateOfIssue: '',
            issuingBodyDocument: '',
            maritalStatus: 1,
            birthState: '',
            birthCity: '',
            nationality: '23',
            occupation: 0,
            cellphone: {
              ddd: `${initialValues?.client?.contacts?.pessoa?.celulares[0]?.ddd}`,
              number:
                initialValues?.client?.contacts?.pessoa?.celulares[0]?.numero
            }
          }
        }
      })
      setPaymentForms(
        initialValues?.atxResponse?.paymentForms?.map(
          paymentForm =>
            paymentForm && {
              name: paymentForm.description,
              value: paymentForm.id
            }
        )
      )
      setInstallmentAmount(
        initialValues.atxResponse?.terms?.map(
          installment =>
            installment && {
              name: installment.times,
              value: installment.times
            }
        )
      )
      if (
        moment(
          initialValues?.atxResponse?.firstInstallment.minimumDate
        ).isBefore(moment())
      ) {
        setMinFirstPaymentDate(moment().toDate())
      } else {
        setMinFirstPaymentDate(
          moment(
            initialValues?.atxResponse?.firstInstallment.minimumDate
          ).toDate()
        )
      }
      setMaxFirstPaymentDate(
        moment(
          initialValues?.atxResponse?.firstInstallment.maximumDate
        ).toDate()
      )

      setTotalValue(
        genericMaskWithTwoZeroWithPoint(
          `${initialValues?.atxResponse?.entry.recommendedValue}`
        )
      )
      setFirstPaymentDate(moment().add(30, 'days').toDate())
    }
  }, [initialValues, isOpenInModal.handleOnClose])
  const optionsStates = [
    {
      value: 'AC',
      name: 'ACRE'
    },
    {
      value: 'AL',
      name: 'ALAGOAS'
    },
    {
      value: 'AM',
      name: 'AMAZONAS'
    },
    {
      value: 'AP',
      name: 'AMAPÁ'
    },
    {
      value: 'BA',
      name: 'BAHIA'
    },
    {
      value: 'CE',
      name: 'CEARA'
    },
    {
      value: 'DF',
      name: 'DISTRITO FEDERAL'
    },
    {
      value: 'ES',
      name: 'ESPIRITO SANTO'
    },
    {
      value: 'GO',
      name: 'GOIAS'
    },
    {
      value: 'MA',
      name: 'MARANHÃO'
    },
    {
      value: 'MG',
      name: 'MINAS GERAIS'
    },
    {
      value: 'MS',
      name: 'MATO GROSSO DO SUL'
    },
    {
      value: 'MT',
      name: 'MATO GROSSO'
    },
    {
      value: 'PA',
      name: 'PARA'
    },
    {
      value: 'PB',
      name: 'PARAIBA'
    },
    {
      value: 'PE',
      name: 'PERNAMBUCO'
    },
    {
      value: 'PI',
      name: 'PIAUÍ'
    },
    {
      value: 'PR',
      name: 'PARANA'
    },
    {
      value: 'RJ',
      name: 'RIO DE JANEIRO'
    },
    {
      value: 'RN',
      name: 'RIO GRANDE DO NORTE'
    },
    {
      value: 'RO',
      name: 'RONDÔNIA'
    },
    {
      value: 'RR',
      name: 'RORAIMA'
    },
    {
      value: 'RS',
      name: 'RIO GRANDE DO SUL'
    },
    {
      value: 'SC',
      name: 'SANTA CATARINA'
    },
    {
      value: 'SE',
      name: 'SERGIPE'
    },
    {
      value: 'SP',
      name: 'SAO PAULO'
    },
    {
      value: 'TO',
      name: 'TOCANTINS'
    }
  ]
  const typesOccupation = [
    { name: 'Aposentado ou Pensionista', value: 1 },
    { name: 'Assalariado (Privado)', value: 2 },
    { name: 'MEI ou Empresário', value: 3 },
    { name: 'Profissional Liberal', value: 4 },
    { name: 'Autônomo', value: 5 },
    { name: 'Assalariado (Público)', value: 6 }
  ]
  const onSubmitForm = useCallback(
    async (data: any) => {
      if (inputTotalValueError.error) {
        return
      }
      const id = initialValues?.idUpdate
      if (initialValues?.type !== 'cpfgts') {
        data.payment.firstPaymentDate = moment(
          data.payment.firstPaymentDate
        ).format('YYYY-MM-DD')
      }
      if (initialValues?.type === 'cppuro') {
        data.insurance = {
          id: '75',
          insurancerCode: ''
        }
      }
      const findOffer = initialValues?.offers?.find(
        item => item.key.toLowerCase() === initialValues?.type?.toLowerCase()
      )
      data.parameters = {
        channel: findOffer?.sourceChannel,
        tab: findOffer?.tab,
        offer: findOffer?.offer
      }
      if (initialValues?.type === 'cpauto') {
        data.insurance.id = '84'
        data.insurance.insurancerCode = '1'
        data.fleet = {
          id: '1'
        }
        data.vehicle = {
          ...data.vehicle,
          adapted: Number(data.vehicle.taxi) === 1,
          taxi: Number(data.vehicle.taxi) === 1,
          vehicleTypeId: '4'
        }
      }
      // const { handleOnClose } = isOpenInModal
      const dataCreate = {
        ...data,
        uuid: initialValues.uuid,
        customer: {
          // document: '32708037889'
          document: initialValues?.client?.cpf
            ?.replace(/[.,-]/g, '')
            ?.replace(/\s/g, '')
        },
        payment: {
          ...data.payment,
          totalValue: convertValueMaskInNumberWithTwoZero(
            data.payment.totalValue
          ),
          incomeValue:
            initialValues?.type !== 'cpfgts'
              ? convertValueMaskInNumberWithTwoZero(data.payment.incomeValue)
              : undefined,
          firstPaymentDate: moment(firstPaymentDate).format('YYYY-MM-DD')
        },
        prospect_id: initialValues?.client?.id,
        prospect_query_api_id: initialValues?.prospect_query_api_id
      }
      if (data?.customer?.natural?.cellphone?.number) {
        dataCreate.customer.natural.cellphone.number = unMaskPhone(
          dataCreate?.customer?.natural?.cellphone?.number
        )
      }
      setContractValues({
        ...contractValues,
        customer: {
          ...contractValues.customer,
          natural: {
            ...contractValues.customer.natural,
            monthlyIncome: '0,00'
          }
        }
      })
      setIsLoading(true)
      try {
        const response = await api.post(
          initialValues?.type !== 'cpfgts'
            ? '/apis/sim/simulations/'
            : '/apis/sim/simulations/fgts',
          dataCreate
        )
        setSimulationData({
          ...simulationData,
          ...response.data
        })
        // handleOnClose()
        setIsLoading(false)
        // updateDataTable()
        // addToast({
        //   type: 'info',
        //   title: 'Proposta Enviada',
        //   description: 'Proposta Enviada com sucesso'
        // })
      } catch (error: any) {
        addToast({
          ...handleErrorMessage(error)
        })
        // handleOnClose()
        setIsLoading(false)
        // updateDataTable()
      }
    },
    [
      addToast,
      contractValues,
      firstPaymentDate,
      initialValues?.client?.cpf,
      initialValues?.client?.id,
      initialValues?.idUpdate,
      initialValues?.offers,
      initialValues?.prospect_query_api_id,
      initialValues?.type,
      initialValues.uuid,
      inputTotalValueError.error,
      simulationData
    ]
  )

  const handleContract = useCallback(
    async (data: any) => {
      const { handleParentOnClose } = isOpenInModal
      setIsLoading(true)
      data.customer.address.type = 'R'
      data.customer.address.complement = ''
      data.customer.natural.occupation = Number(
        data.customer.natural.occupation
      )
      data.customer.natural.documentId = 3
      data.customer.natural.maritalStatus = 1
      data.customer.natural.nationality = Number(
        data.customer.natural.nationality
      )
      data.customer.natural.dateOfIssue = moment(
        data.customer.natural.dateOfIssue
      ).format('DD/MM/YYYY')
      data.customer.natural.patrimony = convertValueMaskInNumberWithTwoZero(
        data.customer.natural.patrimony || '0,00'
      )
      data.customer.natural.monthlyIncome = convertValueMaskInNumberWithTwoZero(
        incomeValue || '0,00'
      )
      data.customer.natural.birthCity = Number(data.customer.natural.birthCity)
      data.customer.address.city = Number(data.customer.address.city)
      try {
        const response = await api.post('/apis/sim/captures/', {
          prospect_query_api_id: initialValues?.prospect_query_api_id,
          prospect_id: initialValues?.client?.id,
          uuid: simulationData?.simulationResponse?.uuid,
          customer: {
            ...contractValues.customer,
            ...data.customer,
            natural: {
              ...contractValues.customer.natural,
              ...data.customer.natural
            },
            // name: 'Vinicius',
            name: initialValues.client.name,
            // document: '32708037889'
            document: unMaskCpfCnpj(initialValues?.client?.cpf)
          }
          // cdc
        })
        handleParentOnClose?.()
        setIsLoading(false)
        updateDataTable()
        addToast({
          type: 'success',
          title: 'Proposta Enviada',
          description: 'Proposta Enviada com sucesso'
        })
      } catch (error: any) {
        addToast({
          ...handleErrorMessage(error)
        })
        // handleOnClose()
        setIsLoading(false)
        // updateDataTable()
      }
    },
    [
      addToast,
      contractValues,
      incomeValue,
      initialValues.client?.cpf,
      initialValues.client?.id,
      initialValues.client.name,
      initialValues?.prospect_query_api_id,
      isOpenInModal,
      simulationData,
      updateDataTable
    ]
  )

  const loadBanks = useCallback(async () => {
    setIsLoading(true)
    try {
      const listBanksResponse = await api.get('/apis/sim/domains/banks')
      setBanks(
        listBanksResponse.data
          .sort((currentBank: BankOptions, nextBank: BankOptions) =>
            currentBank.description.localeCompare(nextBank.description)
          )
          .map(
            (bank: { id: string; description: string }) =>
              bank && { value: bank.id, name: bank.description }
          )
      )
      setIsLoading(false)
    } catch (error: any) {}
  }, [])

  useEffect(() => {
    loadBanks()
  }, [loadBanks])

  useEffect(() => {
    setIsLoading(true)
    async function loadOptions() {
      try {
        const fetchOptionNationalities = api.get(
          '/apis/sim/domains/nationality'
        )
        const fetchOptionOccupations = api.get('/apis/sim/domains/occupation')
        const responseOptionNationalities = await fetchOptionNationalities
        const responseOptionOccupations = await fetchOptionOccupations
        setNationalities(
          responseOptionNationalities.data.map(
            (nationality: { id: number; description: string }) =>
              nationality && {
                name: nationality.description,
                value: nationality.id
              }
          )
        )
        setOccuppations(
          responseOptionOccupations.data.map(
            (occupation: { id: number; description: string }) =>
              occupation && {
                name: occupation.description,
                value: occupation.id
              }
          )
        )

        setIsLoading(false)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar opções',
          description:
            'Ocorreu um erro ao carregar  opções, por favor, tente novamente.'
        })
        setIsLoading(false)
      }
    }
    loadOptions()
  }, [activeLoading, addToast, disableLoading])

  const loadCities = useCallback(
    async (
      uf: string,
      setOptions: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
      setIsLoading(true)
      try {
        const response = await api.get(`/apis/sim/domains/cities/${uf}`)
        setOptions(
          response.data.map(
            (city: { id: number; description: string }) =>
              city && { value: city.id, name: city.description }
          )
        )
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        addToast({
          type: 'error',
          title: 'Error ao fazer simular',
          description:
            'Houve um error ao fazer simular, tente novamente mais tarde!'
        })
      }
    },
    [addToast]
  )

  const handleFormatValue = (value: string) => {
    return value
  }

  return (
    <div className="card p-5">
      <Loading isActive={isLoading} />
      <Form onSubmit={onSubmitForm}>
        <FormContainer className="form">
          <div className="row">
            <div className="col-2">
              <div className="form-group">
                <label htmlFor="created" className=" fw-bold fs-6">
                  Valor disponível
                </label>
                <p className=" fs-6 text-gray-800">
                  {handleSimulationValue(
                    initialValues?.repescResponse?.[initialValues?.type]?.title
                  )}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label htmlFor="created" className=" fw-bold fs-6">
                  Quantidade de parcelas
                </label>
                <p className=" fs-6 text-gray-800">
                  {
                    initialValues?.repescResponse?.[initialValues?.type]
                      ?.message
                  }
                </p>
              </div>
            </div>
          </div>
          {initialValues?.type !== 'cpfgts' && (
            <div className={'row ' + SPACING_ROW_FORM}>
              <Select
                className="col-3"
                name="payment.bankCode"
                label="Banco"
                rules={{
                  required: true
                }}
                options={banks}
                defaultValue=""
                blank
              />
              <Input
                className="col-1"
                name="payment.agency"
                label="Agência"
                mask={{
                  mask: '9999999999999999'
                }}
                controlled
                rules={{
                  required: true
                }}
              />
              <Input
                className="col-2"
                name="payment.account"
                label="Conta"
                mask={{
                  mask: '9999999999999999'
                }}
                controlled
                rules={{ required: true }}
              />
              <Input
                className="col-2"
                name="payment.digit"
                label="Dígito"
                mask={{
                  mask: '9999999999999999'
                }}
                controlled
                rules={{ required: true }}
              />
              <Select
                className="col-4"
                name="payment.paymentFormId"
                label="Forma de Pagamento"
                options={paymentForms}
                blank
                defaultValue=""
                rules={{ required: true }}
              />
            </div>
          )}
          <div className={'row ' + SPACING_ROW_FORM}>
            <Input
              className="col-md-3"
              name="payment.totalValue"
              label="Valor do Empréstimo"
              rules={{ required: true }}
              value={totalValue}
              hasError={inputTotalValueError}
              onChange={event => {
                setInputTotalValueError({
                  ...inputTotalValueError,
                  error: false
                })
                const value = event.target.value
                const valueNumber = convertValueMaskInNumberWithTwoZero(value)
                if (
                  valueNumber < minTotalValue ||
                  valueNumber > maxTotalValue
                ) {
                  setInputTotalValueError({
                    error: true,
                    message: `Valor dever ser maior que ${minTotalValue} e menor que ${maxTotalValue}`
                  })
                }
                setTotalValue(genericMaskWithTwoZeroWithPoint(value))
              }}
              controlled
            />
            {initialValues?.type !== 'cpfgts' && (
              <>
                <Input
                  className="col-3"
                  name="payment.incomeValue"
                  label="Valor de Renda"
                  rules={{ required: true }}
                  onChange={event => setIncomeValue(event.target.value)}
                  price
                />
                <Select
                  className="col-2"
                  name="payment.installmentAmount"
                  label="Nº de Parcelas"
                  options={installmentQuantity}
                  blank
                  defaultValue=""
                  rules={{ required: true }}
                />
                <Datepicker
                  name="payment.firstPaymentDate"
                  className="col-4"
                  label="Vencimento da Primeira Parcela"
                  placeholderText="DD/MM/AAAA"
                  dateFormat="dd/MM/yyyy"
                  selected={firstPaymentDate}
                  minDate={minFirstPaymentDate}
                  maxDate={maxFirstPaymentDate}
                  rules={{ required: !firstPaymentDate }}
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern={'d/m/Y'}
                      format={date => {
                        return moment(date).format('DD/MM/YYYY')
                      }}
                      parse={value => {
                        return moment(value, 'DD/MM/YYYY').toDate()
                      }}
                    />
                  }
                  onChange={date => {
                    setFirstPaymentDate(date)
                  }}
                  controlled
                />
              </>
            )}
          </div>
          {initialValues?.type === 'cpauto' && (
            <div className={'row ' + SPACING_ROW_FORM}>
              <Input
                className="col-md-3"
                name="vehicle.plate"
                label="Placa do Veículo"
                rules={{ required: true }}
              />
              <Select
                className="col-md-3"
                name="vehicle.ufLicensingCode"
                label="Uf de Licenciamento do Veículo"
                rules={{ required: true }}
                options={optionsStates}
              />
              <Select
                className="col-md-3"
                name="vehicle.taxi"
                label="Veículo é um Taxi?"
                rules={{ required: true }}
                options={[
                  {
                    name: 'Sim',
                    value: 1
                  },
                  {
                    name: 'Não',
                    value: 0
                  }
                ]}
              />
              <Select
                className="col-md-2"
                name="vehicle.adapted"
                label="Veículo é Adaptado?"
                options={[
                  {
                    name: 'Sim',
                    value: 1
                  },
                  {
                    name: 'Não',
                    value: 0
                  }
                ]}
                blank
                defaultValue=""
                rules={{ required: true }}
              />
            </div>
          )}
        </FormContainer>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Simular
          </button>
        </div>
      </Form>
      {!!simulationData && (
        <>
          <div className="card-title">
            <h1>Resultado da Simulação</h1>
          </div>
          <div className="form-body">
            <div className={'row ' + SPACING_ROW_FORM}>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="id" className=" fw-bold fs-6">
                    Valor da Parcela
                  </label>
                  <p className=" fs-6 text-gray-800">
                    {BRL(
                      simulationData?.simulationResponse?.installmentValue
                    ).format()}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="name" className=" fw-bold fs-6">
                    Valor da Ultima Parcela
                  </label>
                  <p className=" fs-6 text-gray-800">
                    {Number(
                      simulationData?.simulationResponse?.lastInstallmentValue
                    ) !== 0
                      ? BRL(
                          `${simulationData?.simulationResponse?.lastInstallmentValue}`
                        ).format()
                      : '0,00'}
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="role_id" className=" fw-bold fs-6">
                    Valo Total
                  </label>
                  <p className=" fs-6 text-gray-800">
                    {BRL(
                      simulationData?.simulationResponse?.cet
                        ?.valorTotalPagoFinal
                    ).format()}
                  </p>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="updated" className=" fw-bold fs-6">
                    Juros CET ao Mês
                  </label>
                  <p className=" fs-6 text-gray-800">
                    {genericMaskWithTwoZeroWithPoint(
                      `${
                        Number(
                          simulationData?.simulationResponse?.cet?.cETMensal
                        ) * 100
                      }`
                    )}{' '}
                    %
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="updated" className=" fw-bold fs-6">
                    IOF
                  </label>
                  <p className=" fs-6 text-gray-800">
                    {genericMaskWithTwoZeroWithPoint(
                      `${
                        Number(
                          simulationData?.simulationResponse?.cet?.imposto
                            ?.pcTotalImpostosFinanciado
                        ) * 100
                      }`
                    )}{' '}
                    %
                  </p>
                </div>
              </div>
            </div>
            {/*
            <div className="row">

               <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="updated"
                    className=" fw-bold fs-6"
                  >
                    Seguradora
                  </label>
                  <p className=" fs-6 text-gray-800">
                    {
                      simulationData?.simulationResponse?.cet?.seguroBem
                        ?.nmSeguradora
                    }
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="updated"
                    className=" fw-bold fs-6"
                  >
                    CNPJ Seguradora
                  </label>
                  <p className=" fs-6 text-gray-800">
                    {cnpjMask(
                      simulationData?.simulationResponse?.cet?.seguroBem
                        ?.cnpjSeguradora || ''
                    )}
                  </p>
                </div>
              </div>
            </div>
            */}
          </div>
          <Form onSubmit={handleContract} defaultValues={contractValues}>
            <FormContainer className="form">
              {initialValues?.type !== 'cpfgts' && (
                <>
                  <div className={'row ' + SPACING_ROW_FORM}>
                    {/* <Input
                      className="col-md-3"
                      name="customer.email"
                      label="Email"
                      rules={{
                        required: true,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Email inválido'
                        }
                      }}
                    /> */}
                    {/* <Input
                      className="col-xl-1"
                      label="DDD"
                      name="customer.natural.cellphone.ddd"
                      rules={{ required: true }}
                      mask={{
                        mask: '99'
                      }}
                      controlled
                    />
                    <Input
                      className="col-xl-2"
                      label="Número"
                      name="customer.natural.cellphone.number"
                      rules={{ required: true }}
                      mask={{
                        mask: '99999-9999'
                      }}
                      controlled
                    /> */}
                    <Select
                      className="col-2"
                      label="Gênero"
                      name="customer.natural.gender"
                      rules={{ required: true }}
                      options={[
                        {
                          name: 'Masculino',
                          value: 'M'
                        },
                        {
                          name: 'Feminino',
                          value: 'F'
                        }
                      ]}
                    />
                    <Input
                      className="col-4"
                      label="Nome da Mãe"
                      name="customer.natural.mother"
                      rules={{
                        required: true,
                        minLength: { value: 10, message: 'Nome e Sobrenome' }
                      }}
                    />
                    <Input
                      className="col-2"
                      label="Patrimônio"
                      name="customer.natural.patrimony"
                      rules={{ required: true }}
                      price
                    />
                    <Select
                      className="col-4"
                      label="Ocupação"
                      name="customer.natural.occupation"
                      blank
                      defaultValue=""
                      options={occupations}
                      rules={{ required: true }}
                      controlled
                    />
                  </div>
                  <div className={'row ' + SPACING_ROW_FORM}>
                    <Input
                      className="col-2"
                      label="CEP"
                      name="customer.address.zipCode"
                      mask={{
                        mask: '99999-999',
                        type: 'zipCode',
                        prefixInputsData: 'customer.address.',
                        customFields: [
                          {
                            name: 'neighborhood',
                            type: 'district'
                          }
                        ],
                        excludeFields: [stateFields.address, stateFields.birth]
                      }}
                      rules={{
                        required: true,
                        minLength: { value: 8, message: 'Cep Inválido' }
                      }}
                      controlled
                    />

                    <Select
                      className="col-2"
                      label="Estado"
                      name={stateFields.address}
                      blank
                      defaultValue=""
                      options={optionsStates}
                      rules={{ required: true }}
                      onChange={async event =>
                        await loadCities(event.target.value, setAddressCities)
                      }
                      controlled
                    />
                    <Select
                      className="col-2"
                      label="Cidade"
                      name="customer.address.city"
                      rules={{ required: true }}
                      options={addressCities}
                      defaultValue=""
                      blank
                      controlled
                    />
                    <Input
                      className="col-2"
                      label="Bairro"
                      name="customer.address.neighborhood"
                      rules={{ required: true }}
                    />
                    <Input
                      className="col-4"
                      label="Endereços"
                      name="customer.address.street"
                      rules={{ required: true }}
                    />
                  </div>
                  <div className={'row ' + SPACING_ROW_FORM}>
                    <Input
                      className="col-2"
                      label="Número"
                      name="customer.address.number"
                      rules={{ required: true }}
                    />
                    <Input
                      className="col-4"
                      label="Complemento"
                      name="customer.address.complement"
                    />
                  </div>
                  <div className={'row ' + SPACING_ROW_FORM}>
                    <Select
                      className="col-3"
                      label="Nacionalidade"
                      name="customer.natural.nationality"
                      blank
                      defaultValue="23"
                      controlled
                      options={nationalities}
                      rules={{ required: true }}
                    />
                    <Select
                      className="col-3"
                      label="Estado de Nascimento"
                      name={stateFields.birth}
                      blank
                      defaultValue=""
                      options={optionsStates}
                      rules={{ required: true }}
                      onChange={async event =>
                        await loadCities(event.target.value, setBirthCities)
                      }
                      controlled
                    />
                    <Select
                      className="col-3"
                      label="Cidade de Nascimento"
                      name="customer.natural.birthCity"
                      rules={{ required: true }}
                      options={birthCities}
                      defaultValue=""
                      blank
                      controlled
                    />
                  </div>
                  <div className={'row ' + SPACING_ROW_FORM}>
                    <Input
                      className="col-3"
                      label="Número do RG"
                      name="customer.natural.documentNumber"
                      rules={{ required: true }}
                    />
                    <Select
                      className="col-3"
                      label="Estado de Emissão do RG"
                      name="customer.natural.documentState"
                      rules={{ required: true }}
                      blank
                      defaultValue=""
                      options={optionsStates}
                      controlled
                    />
                    <Datepicker
                      name="customer.natural.dateOfIssue"
                      className="col-3"
                      label="Data de Emissão do RG"
                      placeholderText="DD/MM/AAAA"
                      dateFormat="dd/MM/yyyy"
                      selected={issueDocumentDate}
                      rules={{ required: true }}
                      customInput={
                        <IMaskInput
                          mask={Date}
                          pattern={'d/m/Y'}
                          format={date => {
                            return moment(date).format('DD/MM/YYYY')
                          }}
                          parse={value => {
                            return moment(value, 'DD/MM/YYYY').toDate()
                          }}
                        />
                      }
                      onChange={date => {
                        setIssueDocumentDate(date)
                      }}
                      controlled
                    />
                    <Input
                      className="col-3"
                      label="Orgão Emissor do RG"
                      name="customer.natural.issuingBodyDocument"
                      rules={{ required: true }}
                    />
                  </div>
                  <div className={'row ' + SPACING_ROW_FORM}>
                    {/* <Select
                      className="col-xl-3"
                      label="Tipo de Ocupação"
                      name="customer.incomeList.incomeType"
                      blank
                      defaultValue=""
                      options={typesOccupation}
                      rules={{ required: true }}
                    />
                    <Input
                      className="col-xl-3"
                      label="Renda Para Essa Ocupação"
                      name="customer.incomeList.income"
                      rules={{ required: true }}
                      price
                    /> */}
                  </div>
                </>
              )}
              {/* <div className={"row " + SPACING_ROW_FORM}>
                <Input
                  className="col-md-3"
                  name="payment.totalValue"
                  label="Valor do Empréstimo"
                  rules={{ required: true }}
                  value={totalValue}
                  hasError={inputTotalValueError}
                  onChange={event => {
                    setInputTotalValueError({})
                    const value = event.target.value
                    const valueNumber =
                      convertValueMaskInNumberWithTwoZero(value)
                    if (
                      valueNumber < minTotalValue ||
                      valueNumber > maxTotalValue
                    ) {
                      setInputTotalValueError({
                        error: true,
                        message: `Valor dever ser maior que ${minTotalValue} e menor que ${maxTotalValue}`
                      })
                    }
                    setTotalValue(genericMaskWithTwoZeroWithPoint(value))
                  }}
                />
                {initialValues?.type !== 'cpfgts' && (
                  <>
                    <Input
                      className="col-md-3"
                      name="payment.incomeValue"
                      label="Valor de Renda"
                      rules={{ required: true }}
                      price
                    />
                    <Select
                      className="col-md-2"
                      name="payment.installmentAmount"
                      label="Nº de Parcelas"
                      options={installmentQuantity}
                      blank
                      defaultValue=""
                      rules={{ required: true }}
                    />
                    <Datepicker
                      name="payment.firstPaymentDate"
                      className="col-md-4"
                      label="Data de Pagamento da Primeira Parcela"
                      placeholderText="DD/MM/AAAA"
                      dateFormat="dd/MM/yyyy"
                      selected={firstPaymentDate}
                      minDate={minFirstPaymentDate}
                      maxDate={maxFirstPaymentDate}
                      rules={{ required: true }}
                      customInput={
                        <IMaskInput
                          mask={Date}
                          pattern={'d/m/Y'}
                          format={date => {
                            return moment(date).format('DD/MM/YYYY')
                          }}
                          parse={value => {
                            return moment(value, 'DD/MM/YYYY').toDate()
                          }}
                        />
                      }
                      onChange={date => {
                        setFirstPaymentDate(date)
                      }}
                      controlled
                    />
                  </>
                )}
              </div> */}
              {initialValues?.type === 'cpauto' && (
                <div className={'row ' + SPACING_ROW_FORM}>
                  <Input
                    className="col-md-3"
                    name="vehicle.plate"
                    label="Placa do Veículo"
                    rules={{ required: true }}
                  />
                  <Select
                    className="col-md-3"
                    name="vehicle.ufLicensingCode"
                    label="Uf de Licenciamento do Veículo"
                    rules={{ required: true }}
                    options={optionsStates}
                  />
                  <Select
                    className="col-md-3"
                    name="vehicle.taxi"
                    label="Veículo é um Taxi?"
                    rules={{ required: true }}
                    options={[
                      {
                        name: 'Sim',
                        value: 1
                      },
                      {
                        name: 'Não',
                        value: 0
                      }
                    ]}
                  />
                  <Select
                    className="col-md-2"
                    name="vehicle.adapted"
                    label="Veículo é Adaptado?"
                    options={[
                      {
                        name: 'Sim',
                        value: 1
                      },
                      {
                        name: 'Não',
                        value: 0
                      }
                    ]}
                    blank
                    defaultValue=""
                    rules={{ required: true }}
                  />
                </div>
              )}
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Contratar
              </button>
            </div>
          </Form>
        </>
      )}
    </div>
  )
}
