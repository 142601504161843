import { ToolsContainerProps } from '../../../../../../components/Container'
import { nameActions } from '../../info'

export const toolsList: ToolsContainerProps[] = [
  {
    name: nameActions.create.name,
    to: nameActions.create.to,
    icon: nameActions.create.icon,
    hasParams: false,
    color: 'btn-light-success'
  }
]

export const toolsExport = (
  handleOnClick: (pamams: any) => void
): ToolsContainerProps => ({
  name: 'Exportar',
  to: '',
  icon: 'fa fa-save',
  color: 'btn-light-primary',
  handleOnClick
})
