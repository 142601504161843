import { PageLink } from '../../../../../../../assets/layout/core'

export const breadcrumbList: PageLink[] = [
  {
    title: 'Grupos de Usuário',
    path: '/users/roles',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
]
