import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Input } from '../Form'
import { Container } from './styles'
import { useOutsideClick } from 'hooks/outsideClick'

interface DatalistProps {
  label: string
  value?: {
    name: string
    value: number
    id: number
  }
  data: {
    name: string
    value: number
    id: number
  }[]
  className?: string
  setValue?: React.Dispatch<
    React.SetStateAction<{
      name: string
      value: number
      id: number
    }>
  >
}

export const Datalist: React.FC<DatalistProps> = ({
  label,
  setValue,
  className,
  data,
  value
}) => {
  const containerReference = useRef(null)
  const [searchValues, setSearchValues] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [selected, setSelected] = useState(false)
  const [display, setDisplay] = useState(false)

  const handleSearch = useCallback(() => {
    const filterItems = data.filter(item =>
      item.name?.toLowerCase().includes(searchValues?.name?.toLowerCase())
    )

    if (!filterItems.length) {
      return (
        <span
          key={Math.random() * 999}
          className="fw-bolder fs-6 text-gray-800 cursor-pointer text-hover-primary py-5"
          style={{ minWidth: '165px', textAlign: 'center' }}
        >
          Nenhum resultado encontrado
        </span>
      )
    }

    return filterItems.map(item => (
      <span
        key={item.id}
        className="fw-bolder fs-6 text-gray-800 cursor-pointer text-hover-primary py-5"
        onClick={() => {
          setSearchValues(prev => {
            const copy = { ...prev }
            copy.name = item.name
            copy.id = item.id
            return copy
          })
          setValue?.(item)
          setSelected(true)
          setDisplay(false)
        }}
      >
        {item.name}
      </span>
    ))
  }, [data, searchValues, setValue])

  const handleClick = () => {
    setSearchValues({
      name: '',
      value: 0,
      id: 0
    })
    setDisplay(true)
  }

  useOutsideClick(containerReference, () => {
    setDisplay(false)
  })

  useEffect(() => {
    if (value) {
      const findData = data.find(item => item.id === value.id)
      if (findData) {
        setValue(oldValue => ({ ...oldValue, name: findData.name }))
        setSelected(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Container className={className || 'col-md-3'} ref={containerReference}>
      <Input
        name=""
        label={label}
        onClick={handleClick}
        onChange={({ target }) => {
          setSearchValues(prev => {
            const copy: any = { ...prev }
            copy.name = target.value
            return copy
          })
          setSelected(false)
        }}
        value={selected ? value?.name : searchValues.name}
      />

      {display && (
        <div
          className="search-container card container-dropdown border scroll pe-10"
          style={{ maxHeight: '165px', minWidth: '96%' }}
        >
          <div className="d-flex flex-column px-5">{handleSearch()}</div>
        </div>
      )}
    </Container>
  )
}
