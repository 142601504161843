import { ToolsContainerProps } from '../../../../../../components/Container'
import { nameActions } from '../../info'

export const toolsList: ToolsContainerProps[] = [
  {
    name: nameActions.create.name,
    to: nameActions.create.to,
    icon: nameActions.create.icon,
    hasParams: false,
    color: 'btn-light-success'
  }
]
