export const headers = [
  { name: 'Nome', field: 'partnerName', sortable: true },
  { name: 'Prospects', field: 'totalProspects', sortable: true },
  { name: 'Prospects Trabalhados', field: 'totalWorked', sortable: true },
  {
    name: 'Lemit Prospect',
    field: 'totalLemit',
    sortable: false
  },
  {
    name: 'Lemit Avulso',
    field: 'totalLemitSingle',
    sortable: false
  },
  {
    name: 'Prospects Não Trabalhados',
    field: 'totalNotWorked',
    sortable: false
  },
  { name: 'Propostas concluídas', field: 'totalProposals', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false, custom: true }
]
