import { User } from 'hooks/auth'

type IOptions = { name: string; value: string }

export default function generateOptions({
  options,
  user
}: {
  options: IOptions[]
  user: User
}): IOptions[] {
  return options.filter(item => {
    if (
      user?.role?.team?.toLowerCase() === 'p' &&
      !user?.partner?.crm_query_lemit &&
      item.value === 'lemit'
    ) {
      return false
    }
    if (
      user?.role?.team?.toLowerCase() === 'p' &&
      !user?.partner?.crm_query_lemit &&
      item.value === '2'
    ) {
      return false
    }
    return true
  })
}
