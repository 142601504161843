import React from 'react'
import List from '../../pages/Config/Menus/List'
import Update from '../../pages/Config/Menus/Update'
import Create from '../../pages/Config/Menus/Create'
import View from '../../pages/Config/Menus/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const MenuRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/users/menus" exact component={List} />
    <Route path="/users/menus/update/:id" component={Update} exact />
    <Route path="/users/menus/create" component={Create} exact />
    <Route path="/users/menus/view/:id" component={View} exact />
  </CustomSwitch>
)
