import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Select } from '../../../../../components/Form'

import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { handleErrorMessage } from 'utlis/handleErros'
import { SPACING_ROW_FORM } from 'common/constants'
import moment from 'moment'
import { Datalist } from 'components/Datalist'

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
}
type IsOpenInModalProps = {
  idParent?: number
  handleOnClose: () => void
}

export const FormExport = ({ isOpenInModal }: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { activeLoading, disableLoading } = useLoading()
  const { updateDataTable } = useUpdateDataTable()
  const [agreementGroupValue, setAgreementGroupValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [partnerValue, setPartnerValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [agreementGroups, setAgreementGroups] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const [partners, setPartners] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const { handleOnClose } = isOpenInModal
  const getAgreementGroups = useCallback(async () => {
    activeLoading()
    const response = await api.get('/operational/agreementGroups')
    const agreementGroupsSelect = response.data?.map(
      (agreementGroup: { id: number; name: string }) => ({
        value: agreementGroup.id,
        name: agreementGroup.name,
        id: agreementGroup.id
      })
    )
    setAgreementGroups(agreementGroupsSelect)
  }, [activeLoading])

  const getPartners = useCallback(async () => {
    activeLoading()
    const partnersResponse = await api.get('/commercial/partners')
    const filterPartners = partnersResponse.data?.filter(
      (partner: { active: boolean }) => partner.active
    )
    const partnersSelect = filterPartners?.map(
      (partner: {
        id: number
        store_name: string
        person_cnpj_cpf: string
        active: boolean
      }) => ({
        value: partner.id,
        name: `${partner.store_name} - ${partner.person_cnpj_cpf}`,
        id: partner.id
      })
    )
    setPartners(partnersSelect)
    disableLoading()
  }, [activeLoading, disableLoading])

  useEffect(() => {
    getAgreementGroups()
  }, [getAgreementGroups])

  useEffect(() => {
    getPartners()
  }, [getPartners])

  const onSubmitForm = useCallback(async () => {
    activeLoading()

    try {
      const date = moment().format('DD-MM-YYYY')
      const response = await api.post('/commercial/prospects/export', {
        agreement_group_id: agreementGroupValue?.id,
        partner_id: partnerValue?.id
      })
      const blob = new Blob(['\ufeff' + response.data], {
        type: 'text/csv;charset=utf-8"'
      })
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.download = `prospects-${date}.csv`
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      handleOnClose()
      disableLoading()
      updateDataTable()
      addToast({
        type: 'success',
        title: 'Dados Exportados Com Sucesso',
        description: 'Dados exportados com sucesso'
      })
    } catch (error: any) {
      addToast({
        ...handleErrorMessage(error)
      })
      disableLoading()
    }
  }, [
    activeLoading,
    addToast,
    agreementGroupValue?.id,
    disableLoading,
    handleOnClose,
    partnerValue?.id,
    updateDataTable
  ])
  return (
    <Form onSubmit={onSubmitForm}>
      <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
        <Datalist
          className="col-6"
          data={agreementGroups}
          label="Grupo de Convênio"
          setValue={setAgreementGroupValue}
          value={agreementGroupValue}
        />
        <Datalist
          className="col-6"
          data={partners}
          label="Parceiro"
          setValue={setPartnerValue}
          value={partnerValue}
        />
      </div>
      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <button type="submit" className="btn btn-primary">
          Exportar
        </button>
      </div>
    </Form>
  )
}
