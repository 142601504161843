import React from 'react'
import { CSSProperties } from 'styled-components'
import { Container } from './styles'

interface CardSimpleProps {
  primaryColor: string
  className?: string
  textColor?: string
  text: string
  number: string
  subNumber?: string
  iconSettings?: {
    icon: string
    style?: {
      size?: string
      color?: string
    }
  }
  style?: CSSProperties
  footer?: {
    link?: string
    handleClick?: () => void
    text: string
    iconSettings?: {
      icon: string
      style?: {
        size?: string
        color?: string
      }
    }
  }
  onClick?: () => void
}

export const Card: React.FC<CardSimpleProps> = ({
  primaryColor,
  iconSettings,
  textColor,
  footer,
  style,
  className,
  number,
  text,
  onClick,
  subNumber
}) => {
  return (
    <Container
      className={`card p-0 border border-${primaryColor} ${className || ''}`}
      style={style}
      onClick={onClick}
    >
      <div className={`bg-${primaryColor}`}>
        <div className="card-body d-flex justify-content-between">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              className={`fa ${iconSettings?.icon} ${
                iconSettings?.style?.size || 'fs-1'
              }`}
              style={{ color: iconSettings?.style?.color || '#fff' }}
            ></span>
          </div>
          <div
            className={`d-flex flex-column align-items-end text-${
              textColor || 'white'
            }`}
          >
            <span className="fw-bolder fs-2hx">{number}</span>
            {subNumber && (
              <span className="fw-bolder fs-medium">{subNumber}</span>
            )}
            <span className="fw-bolder fs-6">{text}</span>
          </div>
        </div>
      </div>
      {footer && (
        <div className="py-4 px-9 ">
          <a
            href={footer?.link}
            onClick={footer?.handleClick}
            className={`text-${primaryColor} d-flex justify-content-between`}
          >
            {footer?.text}
            <span
              className={`fa ${footer?.iconSettings?.icon} ${
                footer?.iconSettings?.style?.size || 'fs-1'
              }`}
              style={{ color: footer?.iconSettings?.style?.color || '#fff' }}
            ></span>
          </a>
        </div>
      )}
    </Container>
  )
}
