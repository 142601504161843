import { SIM_TYPES_FORM } from 'common/constants/Commercial/Prospects'
import Modal from 'components/Modal'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { FiAlertCircle, FiCheckCircle, FiInfo, FiXCircle } from 'react-icons/fi'
import { handleErrorMessage } from 'utlis/handleErros'
import Form, { Input, Select } from '../../../../../components/Form'
import { Loading } from '../../../../../components/Loading'
import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'
import api from '../../../../../services/api'
import exampleSelfImage from '../../../../../assets/image/example-self.jpg'
import {
  phoneMask,
  unMaskCpfCnpj,
  unMaskPhone
} from '../../../../../utlis/mask'
import { ContainerToast, FormContainer } from './styles'
import { Alert } from 'components/Alert'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
  handleClickOnCloseParent?: () => void
}
type ProposalApiData = {
  id?: number
  prospect: {
    id: number
    partner_id: number
    cpf: string
    name: string
    status: string
    birth_date: string
    admission_date: string
    created_at: string
    updated_at: string
    contacts?: {
      pessoa: {
        cpf: string
        nome: string
        sexo: string
        fixos: {
          ddd: number
          plus: boolean
          numero: string
          ranking: number
          whatsapp: boolean
        }[]
        renda: number
        carros: {
          placa: string
          marca: string
          ano_fabricacao: number
          ano_modelo: number
          renavan: string
          chassi: string
          data_licenciamento: string
          ranking: number
        }[]
        emails: {
          email: string
          ranking: number
          possui_cookie: boolean
        }[]
        falecido: boolean
        nome_mae: string
        ocupacao?: string
        vinculos: {
          cpf_vinculo: string
          nome_vinculo: string
          tipo_vinculo: string
        }[]
        celulares: {
          ddd: number
          plus: boolean
          numero: string
          ranking: number
          whatsapp: boolean
        }[]
        enderecos: {
          uf: string
          cep: string
          tipo: string
          bairro: string
          cidade: string
          numero: string
          ranking: number
          endereco: string
          logradouro: string
          complemento?: string
          tipo_logradouro: string
          titulo_logradouro?: string
        }[]
        situacao_cpf: string
        risco_credito: {
          cpf_cnpj: string
          score_credito: string
        }
        data_nascimento: string
        participacao_societaria: {
          nome: string
          cnpj: string
          capital_social: number
          participacao_socio: number
          data_fundacao: string
          situacao_cadastral: string
        }[]
        bank: {
          accountCode?: string
          accountDigit?: string
          agencyCode?: string
          agencyDigit?: string
          bankCode?: string
          accountType?: number
        }
      }
      data_consulta: string
    }
    agreementGroup: {
      name: string
    }
  }
  Proposta: {
    ProposalId: number
  }
  prospect_query_api_id: number
}
type OptionsProps = { name: number | string; value: number | string }

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ProposalApiData & {
    idUpdate: number
  }
  type: string
}

const icons: any = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />
}

type BankOptions = {
  description: string
  id: string
}
const COUNTER_INTERVAL = 90

const ContainerForm = ({
  isOpenInModal,
  initialValues,
  type
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { handleOnClose, handleClickOnCloseParent } = isOpenInModal
  const [defaultValues, setDefaultValues] = useState<ProposalApiData>()
  const [sendToken, setSendToken] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [bankCode, setBankCode] = useState('')
  const [agencyCode, setAgencyCode] = useState('')
  const [accountCode, setAccountCode] = useState('')
  const [accountDigit, setAccountDigit] = useState('')
  const [modalExampleImage, setModalExampleImage] = useState(false)
  const [counter, setCounter] = useState(COUNTER_INTERVAL)
  const buttonSubmitRef = useRef<HTMLButtonElement>()
  const [hasToast, setHasToast] = useState({
    title: '',
    description: '',
    show: false,
    type: 'success'
  })
  const [banks, setBanks] = useState<OptionsProps[]>([])
  const { activeLoading, disableLoading } = useLoading()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
      setBankCode(initialValues?.prospect?.contacts?.pessoa?.bank?.bankCode)
      setAccountCode(
        initialValues?.prospect?.contacts?.pessoa?.bank?.accountCode
      )
      setAccountDigit(
        initialValues?.prospect?.contacts?.pessoa?.bank?.accountDigit
      )
      setAgencyCode(initialValues?.prospect?.contacts?.pessoa?.bank?.agencyCode)
    }
  }, [initialValues])
  const ddd = initialValues?.prospect?.contacts?.pessoa?.celulares[0]?.ddd
  const number = initialValues?.prospect?.contacts?.pessoa?.celulares[0]?.numero

  const loadBanks = useCallback(async () => {
    setIsLoading(true)
    try {
      const listBanksResponse = await api.get('/apis/sim/domains/banks')
      setBanks(
        listBanksResponse.data
          .sort((currentBank: BankOptions, nextBank: BankOptions) =>
            currentBank.description.localeCompare(nextBank.description)
          )
          .map(
            (bank: { id: string; description: string }) =>
              bank && { value: bank.id, name: bank.description }
          )
      )
      setIsLoading(false)
    } catch (error: any) {}
  }, [])

  useEffect(() => {
    if (
      initialValues &&
      type.toLocaleLowerCase() === SIM_TYPES_FORM.BANK_DATA &&
      !banks?.length
    ) {
      loadBanks()
    }
  }, [banks?.length, initialValues, loadBanks, type])

  const handleSendToken = useCallback(
    async (submitTokenValidateData?: { url?: string; code?: string }) => {
      const url = submitTokenValidateData?.url
      setIsLoading(true)
      try {
        await api.post(
          url || '/apis/sim/formalizations/token',
          submitTokenValidateData?.code
            ? {
                proposal: `${initialValues?.Proposta?.ProposalId}`,
                prospect_query_api_id: initialValues?.prospect_query_api_id,
                code: submitTokenValidateData.code
              }
            : {
                phone: unMaskPhone(`${ddd}${number}`),
                proposal: `${initialValues?.Proposta?.ProposalId}`,
                cpf: unMaskCpfCnpj(initialValues?.prospect?.cpf)
              }
        )
        setIsLoading(false)
        setHasToast({
          title: url ? 'Token Validado Com Sucesso' : 'Token Enviado',
          description: url
            ? 'Token validado com sucesso'
            : `Token enviado com sucesso para número ${phoneMask(
                `${ddd}${number}`
              )}`,
          show: true,
          type: 'success'
        })
        setTimeout(() => {
          setHasToast(oldHasToast => ({
            ...oldHasToast,
            show: false
          }))
        }, 3000)
      } catch (error: any) {
        setIsLoading(false)
        setHasToast({
          type: 'error',
          title: url ? 'Erro ao validar o token' : 'Erro ao enviar token',
          description:
            error.response.data.message ||
            'Ocorreu um erro ao enviar token, por favor, tente novamente.',
          show: true
        })
        setTimeout(() => {
          setHasToast(oldHasToast => ({
            ...oldHasToast,
            show: false
          }))
        }, 3000)
      }
      if (url) {
        handleOnClose()
      }
      setCounter(COUNTER_INTERVAL)
    },
    [
      ddd,
      handleOnClose,
      initialValues?.Proposta?.ProposalId,
      initialValues?.prospect?.cpf,
      initialValues?.prospect_query_api_id,
      number
    ]
  )

  useEffect(() => {
    if (initialValues && type.toLocaleLowerCase() === SIM_TYPES_FORM.TOKEN) {
      handleSendToken()
    }
  }, [handleSendToken, initialValues, type])

  const onSubmitForm = useCallback(
    async (data: any) => {
      if (type.toLocaleLowerCase() === SIM_TYPES_FORM.BANK_DATA) {
        const dataCreate = {
          customer: {
            document: unMaskCpfCnpj(defaultValues?.prospect?.cpf)
          },
          bank: {
            ...defaultValues?.prospect?.contacts?.pessoa?.bank,
            ...data,
            bankCode,
            agencyCode,
            accountCode,
            accountDigit
          },
          proposalId: `${defaultValues?.Proposta?.ProposalId}`,
          prospect_id: defaultValues?.prospect?.id
        }
        activeLoading()

        try {
          await api.post('/apis/sim/formalizations/record', dataCreate)

          addToast({
            title: 'Dados Bancários Enviado',
            type: 'success',
            description: 'Dados Bancários enviados com sucesso'
          })

          handleOnClose()
          disableLoading()
        } catch (error: any) {
          handleOnClose()
          disableLoading()
          addToast({ ...handleErrorMessage(error) })
        }
      }
      if (type.toLocaleLowerCase() === SIM_TYPES_FORM.TOKEN) {
        await handleSendToken({
          code: data.code,
          url: '/apis/sim/formalizations/validate'
        })
        return
      }
      if (type.toLocaleLowerCase() === SIM_TYPES_FORM.SELFIE) {
        const dataCreate = {
          ...data,
          documento: unMaskCpfCnpj(defaultValues?.prospect?.cpf),
          tipoDocumento: 'DOC_SELFIE',
          idProposta: `${defaultValues?.Proposta?.ProposalId}`,
          prospect_query_api_id: defaultValues?.prospect_query_api_id
        }
        activeLoading()
        const formData = new FormData()
        Object.entries(dataCreate).forEach(([key, value]: any) => {
          if (key === 'file') {
            formData.append(key, value[0])
          } else {
            formData.append(key, value)
          }
        })

        try {
          await api.post('/apis/sim/formalizations/uploads', formData)
          disableLoading()
          addToast({
            title: 'Selfie enviada',
            type: 'success',
            description: 'Selfie enviada com sucesso'
          })
          handleOnClose()
        } catch (error: any) {
          addToast({ ...handleErrorMessage(error) })
          disableLoading()
        }
        // handleOnClose()
      }
      if (type.toLocaleLowerCase() === SIM_TYPES_FORM.RG) {
        for (const file of data.files) {
          const item = Object.entries(file)[0]
          const key = item[0]
          const value = item[1]
          const dataCreate = {
            documento: unMaskCpfCnpj(defaultValues?.prospect?.cpf),
            file: value,
            tipoDocumento: key,
            idProposta: `${initialValues?.Proposta?.ProposalId}`,
            prospect_query_api_id: initialValues.prospect_query_api_id
          }
          activeLoading()
          const formData = new FormData()
          Object.entries(dataCreate).forEach(([key, value]: any) => {
            if (key === 'file') {
              formData.append(key, value[0])
            } else {
              formData.append(key, value)
            }
          })

          try {
            await api.post('/apis/sim/formalizations/uploads', formData)
            disableLoading()
            if (key === 'DOC_IDENTIDADE_VERSO') {
              addToast({
                title: 'RG enviada',
                type: 'success',
                description: 'RG enviada com sucesso'
              })
              handleOnClose()
            }
          } catch (error: any) {
            addToast({ ...handleErrorMessage(error) })
            disableLoading()
          }
        }
      }
    },
    [
      accountCode,
      accountDigit,
      activeLoading,
      addToast,
      agencyCode,
      bankCode,
      defaultValues?.Proposta?.ProposalId,
      defaultValues?.prospect?.contacts?.pessoa?.bank,
      defaultValues?.prospect?.cpf,
      defaultValues?.prospect?.id,
      defaultValues?.prospect_query_api_id,
      disableLoading,
      handleOnClose,
      handleSendToken,
      initialValues?.Proposta?.ProposalId,
      initialValues.prospect_query_api_id,
      type
    ]
  )

  useEffect(() => {
    const timer = counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  const handleClickOnClose = async () => {
    setModalExampleImage(false)
  }
  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    setHasToast({
      title: 'Operação cancelada.',
      description: 'Operação cancelada com êxito',
      show: true,
      type: 'info'
    })
    setTimeout(() => {
      setHasToast(oldHasToast => ({
        ...oldHasToast,
        show: false
      }))
    }, 3000)
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      if (buttonSubmitRef.current) {
        buttonSubmitRef.current.click()
      }
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
    }
  }

  if (type.toLocaleLowerCase() === SIM_TYPES_FORM.TOKEN) {
    return (
      <div className="card p-5">
        <ContainerToast
          hasDescription={true}
          type={hasToast.type as 'success' | 'error' | 'info'}
          isShow={hasToast.show}
        >
          {icons[hasToast.type]}

          <div>
            <strong>{hasToast.title}</strong>
            {hasToast.description && <p>{hasToast.description}</p>}
          </div>

          <button
            onClick={() =>
              setHasToast(oldHasToast => ({
                ...oldHasToast,
                show: false
              }))
            }
            type="button"
          >
            <FiXCircle size={18} />
          </button>
        </ContainerToast>
        <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
          <FormContainer className="form">
            <div className="row mb-2">
              {sendToken && (
                <Input
                  className="col-md-3"
                  name="code"
                  label="Código"
                  mask={{
                    mask: '99999'
                  }}
                  tooltip={{
                    message: 'Código enviado para o celular do cliente'
                  }}
                  controlled
                  rules={{
                    required: true,
                    minLength: {
                      value: 5,
                      message: 'Token Inválido, no mínimo 5 caracteres'
                    }
                  }}
                />
              )}

              <Loading isActive={isLoading} />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="button"
              className="btn btn-light-primary"
              onClick={() => handleClickOnCloseParent?.()}
            >
              Continuar Pelo Celular
            </button>
            <button
              type="button"
              className="btn btn-light-primary ms-3"
              onClick={() => handleSendToken()}
              disabled={counter !== 0}
            >
              Re-Enviar {counter !== 0 ? `(${counter}s)` : ''}
            </button>
            <button type="submit" className="btn btn-primary ms-3">
              Enviar
            </button>
          </div>
        </Form>
      </div>
    )
  }
  if (type.toLocaleLowerCase() === SIM_TYPES_FORM.SELFIE) {
    return (
      <div className="card p-5">
        <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
          <FormContainer className="form">
            <div className="row mb-2 d-flex">
              <Input
                className="col-md-3"
                name="file"
                label="Selfie"
                type="file"
                accept=".jpg,.pdf,.gif,.pdf"
                tooltip={{
                  message: 'Deixe o seu rosto bem centralizado na foto'
                }}
                rules={{
                  required: true
                }}
              />
              <div className="col d-flex align-items-end">
                <button
                  type="button"
                  onClick={() => setModalExampleImage(true)}
                  className="btn btn-light-primary"
                >
                  Exemplo de imagem
                </button>
              </div>
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </div>
        </Form>
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalExampleImage}
          pageTitle={'Exemplo de Fotografa'}
          Children={
            <div className="d-flex justify-content-center">
              <img className="w-400px h-auto" src={exampleSelfImage} />
            </div>
          }
        />
      </div>
    )
  }
  if (type.toLocaleLowerCase() === SIM_TYPES_FORM.RG) {
    return (
      <div className="card p-5">
        <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
          <FormContainer className="form">
            <div className="row mb-2">
              <Input
                className="col-md-3"
                name="files.0.DOC_IDENTIDADE_FRENTE"
                label="Frente da Identidade"
                type="file"
                tooltip={{
                  message:
                    'Enviei a foto somente do lado frontal da sua identidade'
                }}
                rules={{
                  required: true
                }}
              />
              <Input
                className="col-md-3"
                name="files.1.DOC_IDENTIDADE_VERSO"
                label="Verso da Identidade"
                type="file"
                tooltip={{
                  message:
                    'Enviei a foto somente do lado do verso da sua identidade'
                }}
                rules={{
                  required: true
                }}
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </div>
        </Form>
      </div>
    )
  }
  if (type.toLocaleLowerCase() === SIM_TYPES_FORM.BANK_DATA) {
    return (
      <div className="card p-5">
        <Loading isActive={isLoading} />
        <Form onSubmit={onSubmitForm}>
          <FormContainer className="form">
            <div className="row mb-2">
              <Select
                className="col-3"
                name="bankCode"
                label="Banco"
                // rules={{
                //   required: true
                // }}
                options={banks}
                value={bankCode}
                onChange={event => setBankCode(event.target.value)}
                // defaultValue=""
                // controlled
                blank
              />
              <Input
                className="col-1"
                name="agencyCode"
                label="Agência"
                value={agencyCode}
                onChange={event => setAgencyCode(event.target.value)}
                // mask={{
                //   mask: '9999999999999999'
                // }}
                // controlled
                // rules={{
                //   required: true
                // }}
              />
              <Input
                className="col-2"
                name="accountCode"
                label="Conta"
                value={accountCode}
                onChange={event => setAccountCode(event.target.value)}
                // mask={{
                //   mask: '9999999999999999'
                // }}
                // controlled
                // rules={{ required: true }}
              />
              <Input
                className="col-2"
                name="accountDigit"
                label="Dígito"
                value={accountDigit}
                onChange={event => setAccountDigit(event.target.value)}
                // mask={{
                //   mask: '9999999999999999'
                // }}
                // controlled
                // rules={{ required: true }}
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                setIsActiveAlert(oldActive => {
                  const findBank = banks.find(
                    item => item && Number(item.value) === Number(bankCode)
                  )
                  return {
                    ...oldActive,
                    name: `Você Confirmar os dados: Banco: ${findBank?.name}, Agência: ${agencyCode}, Conta: ${accountCode}, Dígito: ${accountDigit}`,
                    isActive: true
                  }
                })
              }
            >
              Enviar
            </button>
            <button
              type="submit"
              ref={buttonSubmitRef}
              className="btn btn-primary"
              style={{ display: 'none' }}
            ></button>
          </div>
        </Form>
        <Alert
          message={`${alert.name} ?`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          textConfim="Confirmar"
          onClickConfirmButton={() => {
            handlerClickButtonConfirmAlert(String(alert.id))
          }}
          isActive={alert.isActive}
        />
      </div>
    )
  }
}

export const FormalizationForm = memo(ContainerForm)
