import {
  dateInputFormat,
  optionsMaritalStatus,
  SPACING_ROW_FORM,
  states
} from 'common/constants'

import { Loading } from 'components/Loading'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IMaskInput } from 'react-imask'

import Form, { Input, Select } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'

import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'
import api from '../../../../../services/api'
import {
  formatBank,
  unMaskCpfCnpj,
  unMaskPhone,
  unMaskZipCode
} from '../../../../../utlis/mask'
type IsOpenInModalProps = {
  handleParentOnClose?: () => void
  handleOnClose: () => void
}

type ProspectData = {
  prospect_query_api_id?: number
  prospect?: {
    id?: number
    cpf?: string
    name?: string
    birth_date?: string
  }
  condicoesCredito: any
  quantidadePeriodos: any
  valorAdiantamentoSolicitado: any
}

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ProspectData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormProposalFgts = ({
  isOpenInModal,
  initialValues
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const [birthDate, setBirthDate] = useState<Date>()
  const [issueDate, setIssueDate] = useState<Date>()
  const [admissionDate, setAdmissionDate] = useState<Date>()
  const [defaultValues, setDefaultValues] = useState<any>()

  const enumValuesInputAddress = useMemo(
    () => ({
      city: {
        input: 'city',
        submit: 'cidadeRes'
      },
      district: {
        input: 'district',
        submit: 'bairroRes'
      },
      street: {
        input: 'street',
        submit: 'enderecoRes'
      },
      state: {
        input: 'state',
        submit: 'ufRes'
      }
    }),
    []
  )

  const { activeLoading, disableLoading, loading } = useLoading()
  useEffect(() => {
    if (initialValues) {
      const data = {
        ...initialValues,
        cliente: {
          nome: initialValues.prospect.name,
          dataNascimento: initialValues.prospect.birth_date,
          cpf: initialValues.prospect.cpf
        }
      }
      setDefaultValues({
        ...data
      })
    }
  }, [defaultValues?.prospect?.contacts, enumValuesInputAddress, initialValues])

  const handleSave = useCallback(
    async (data: any) => {
      const { handleOnClose } = isOpenInModal
      const enumValuesInputAddressEntries = Object.entries(
        enumValuesInputAddress
      )
      enumValuesInputAddressEntries.forEach(([, value]) => {
        data.cliente[value.submit] = data.address[value.input]
      })
      delete data.address
      activeLoading()
      try {
        const cpfString = unMaskCpfCnpj(defaultValues?.prospect?.cpf)
        data.cliente.cpf = cpfString
        data.cliente.cdCepRes = unMaskZipCode(data.cliente.cdCepRes)
        data.cliente.celular = unMaskPhone(data.cliente.celular)
        data.cliente.cdBanco = formatBank(data.cliente.cdBanco, 3)
        data.cliente.cdAgencia = formatBank(data.cliente.cdAgencia, 4)
        data.cliente.cdDigito =
          data.cliente.cdDigito === '0' ? '' : data.cliente.cdDigito
        const formData = {
          cpf: cpfString,
          ...data,
          condicoesSimuladas: {
            ...initialValues.condicoesCredito,
            listaCondicaoCredito: [
              {
                ...initialValues.condicoesCredito.listaCondicaoCredito[0],
                convenio: {
                  ...initialValues.condicoesCredito.listaCondicaoCredito[0]
                    .convenio,
                  observacao: ''
                }
              }
            ]
          },
          usuario: 'cedibra',
          quantidadePeriodos: initialValues.quantidadePeriodos,
          valorAdiantamentoSolicitado: initialValues.valorAdiantamentoSolicitado
        }
        await api.post('apis/fgts/save', formData)

        addToast({
          type: 'success',
          title: 'Proposta efetivada',
          description: 'Proposta efetivada com sucesso'
        })
        handleOnClose?.()
        disableLoading()
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Erro ao efetivar',
          description:
            error?.response?.data?.message ||
            'Ocorreu um erro ao enviar proposta'
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      defaultValues?.prospect?.cpf,
      disableLoading,
      enumValuesInputAddress,
      initialValues.condicoesCredito,
      initialValues.quantidadePeriodos,
      initialValues.valorAdiantamentoSolicitado,
      isOpenInModal
    ]
  )

  return (
    <div className="card p-5">
      <Loading isActive={loading} />
      <Form onSubmit={handleSave} defaultValues={defaultValues}>
        <div className="form">
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Input
              className="col-4"
              label="Nome"
              name="cliente.nome"
              rules={{
                required: true,
                maxLength: {
                  value: 35,
                  message: 'Nome tem que ter no máximo 35 caracteres'
                }
              }}
            />
            <DatePicker
              name="cliente.dataNascimento"
              className="col-2  "
              label="Data Nascimento"
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/AAAA"
              selected={birthDate}
              rules={{ required: true }}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(dateInputFormat)
                  }}
                  parse={value => {
                    return moment(value, dateInputFormat).toDate()
                  }}
                />
              }
              onChange={date => {
                setBirthDate(date)
              }}
              controlled
            />
            <Select
              className="col-2  "
              name="cliente.nacionalidade"
              rules={{ required: true }}
              label="Nacionalidade"
              options={[
                { name: 'Brasileira', value: 'brasileira' },
                { name: 'Estrangeira', value: 'estrangeira' }
              ]}
            />
            <Input
              className="col-2  "
              label="Naturalidade"
              name="cliente.naturalidade"
              rules={{ required: true }}
            />
            <Select
              className="col-2  "
              name="cliente.flSexo"
              label="Sexo"
              rules={{ required: true }}
              options={[
                { name: 'Masculino', value: 'M' },
                { name: 'Feminino', value: 'F' }
              ]}
            />
          </div>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Select
              className="col-2  "
              label="Estado Civil"
              name="cliente.estadoCivil"
              rules={{ required: true }}
              defaultValue=""
              blank
              options={optionsMaritalStatus}
            />
            <Input
              className="col-2  "
              label="Nº RG"
              name="cliente.cdRg"
              rules={{ required: true }}
            />
            <Input
              className="col-2  "
              label="Emissor"
              name="cliente.emissorRg"
              rules={{ required: true }}
            />
            <Select
              className="col-2  "
              label="UF de Emissão"
              name="cliente.ufEmissorRg"
              rules={{ required: true }}
              defaultValue=""
              blank
              options={[...states]}
            />
            <DatePicker
              name="cliente.dataExpedicaoRg"
              className="col-2 "
              label="Data Emissão"
              dateFormat="dd/MM/yyyy"
              selected={issueDate}
              rules={{ required: true }}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(dateInputFormat)
                  }}
                  parse={value => {
                    return moment(value, dateInputFormat).toDate()
                  }}
                />
              }
              onChange={date => {
                setIssueDate(date)
              }}
              controlled
            />
            <DatePicker
              name="cliente.dtInicioAdmissao"
              className="col-2 "
              label="Data Admissão"
              dateFormat="dd/MM/yyyy"
              selected={admissionDate}
              rules={{ required: true }}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(dateInputFormat)
                  }}
                  parse={value => {
                    return moment(value, dateInputFormat).toDate()
                  }}
                />
              }
              onChange={date => {
                setAdmissionDate(date)
              }}
              controlled
            />
          </div>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Input
              className="col-4  "
              label="Nome da mãe"
              name="cliente.nomeMae"
              rules={{ required: true }}
            />
            <Input
              className="col-2  "
              label="Celular"
              name="cliente.celular"
              rules={{ required: true }}
              mask={{
                mask: '(99) 99999-9999'
              }}
              controlled
            />
            <Input
              className="col-2  "
              label="Email"
              name="cliente.email"
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email inválido'
                }
              }}
            />
          </div>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Input
              className="col-2  "
              label="Renda (Líquida)"
              name="cliente.valorRenda"
              rules={{ required: true }}
              price
            />
          </div>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <h1 className={'mt-' + SPACING_ROW_FORM}>Dados Endereço</h1>
            <Input
              className="col-2  "
              label="CEP"
              name="cliente.cdCepRes"
              mask={{
                mask: '99999-999',
                type: 'zipCode',
                prefixInputsData: 'address.'
                // excludeFields: [stateFields.address, stateFields.birth]
              }}
              controlled
              rules={{
                required: true,
                minLength: { value: 8, message: 'Cep Inválido' }
              }}
            />
            <Input
              className="col-2  "
              label="Cidade"
              name={`address.${enumValuesInputAddress.city.input}`}
              rules={{ required: true }}
            />
            <Input
              className="col-2  "
              label="Bairro"
              name={`address.${enumValuesInputAddress.district.input}`}
              rules={{ required: true }}
              maxLength={25}
            />
            <Input
              className="col-2  "
              label="Logradouro"
              name={`address.${enumValuesInputAddress.street.input}`}
              rules={{ required: true }}
            />

            <Input
              className="col-2"
              label="Número"
              name="cliente.numeroRes"
              rules={{ required: true }}
            />
            <Select
              className="col-2"
              label="UF"
              name={`address.${enumValuesInputAddress.state.input}`}
              rules={{ required: true }}
              options={[...states]}
              blank
              defaultValue=""
            />
          </div>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Input
              className="col-4"
              label="Complemento"
              name="cliente.complementoRes"
            />
          </div>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <h1 className={'mt-' + SPACING_ROW_FORM}>
              Dados de Liberação Financeira
            </h1>
            <Input
              className="col-1 "
              name="cliente.cdBanco"
              label="Banco"
              rules={{
                required: true,
                maxLength: { value: 3, message: 'No máximo 3 caracteres' }
              }}
            />
            <Input
              className="col-1  "
              name="cliente.cdAgencia"
              label="Agência"
              rules={{
                required: true,
                maxLength: { value: 4, message: 'No máximo 4 caracteres' }
              }}
            />
            <Input
              className="col-2  "
              name="cliente.cdConta"
              label="Conta"
              rules={{ required: true }}
            />
            <Input
              className="col-2  "
              name="cliente.digitoConta"
              label="Dígito"
              rules={{ required: true }}
            />
            <Select
              className="col-2"
              label="Tipo de conta"
              name={'cliente.tipoConta'}
              rules={{ required: true }}
              options={[
                {
                  name: 'Conta Corrente',
                  value: '1'
                },
                {
                  name: 'Conta Poupança',
                  value: '013'
                }
              ]}
              blank
              defaultValue=""
            />
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end px-0">
          <div className="col-sm d-flex justify-content-end container-button">
            <button type="submit" className="btn btn-primary">
              Enviar Proposta
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}
