import {
  APIS_NAMES,
  dateInputFormat,
  SPACING_ROW_FORM,
  states
} from 'common/constants'
import { ID_CONVENIO_CREDCESTA } from 'common/constants/Commercial/Prospects'
import CustomDataTable from 'components/CustomDataTable'
import { Loading } from 'components/Loading'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IMaskInput } from 'react-imask'
import { useHistory } from 'react-router-dom'
import { handleErrorMessage } from 'utlis/handleErros'
import Form, { Input, Select } from '../../../../../components/Form'
import { Date as Datepicker } from '../../../../../components/Form/date'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'
import api from '../../../../../services/api'
import {
  BRL,
  convertValueMaskInNumberWithTwoZero,
  formatBank,
  genericMaskWithTwoZeroWithPoint,
  unMaskCpfCnpj,
  unMaskPhone
} from '../../../../../utlis/mask'
import { FormContainer } from './styles'

type IsOpenInModalProps = {
  handleParentOnClose?: () => void
  handleOnClose: () => void
}
type BankOptions = {
  description: string
  id: string
}

type ProspectContacts = {
  customer?: {
    name: string
    document: string
    dateOfBirthFoundation: string
    email: string
    address?: {
      zipCode: string
      street: string
      number: string
      complement: string
      neighborhood: string
      state: string
      city: string
      type: string
    }
    natural?: {
      gender: string
      mother: string
      monthlyIncome: string | number
      patrimony: string | number
      documentNumber: string
      documentId: number
      documentState: string
      dateOfIssue: string
      issuingBodyDocument: string
      maritalStatus: number
      birthState: string
      birthCity: string
      nationality: string
      occupation: number
      cellphone?: {
        ddd: string
        number: string
      }
    }
    incomeList?: {
      incomeType: string
      income: number
    }[]
  }
}

type ProspectData = {
  valueSimulation?: number
  matricula: number
  vlLimiteCompra: number
  convention: Convention
  installments: any[]
  opcoes: any[]
  limiteDisponivel: number
  atxResponse: {
    paymentForms?: {
      description: string
      id: number
    }[]
    firstInstallment?: {
      maximumDate: string
      minimumDate: string
      recommendedDate: string
    }
    entry?: {
      maximumValue: number
      minimumValue: number
      recommendedValue: number
    }
    terms?: {
      times: number
    }[]
  }
  uuid?: string
  prospect_query_api_id?: number
  prospect?: {
    id?: number
    cpf?: string
    name?: string
    birth_date?: string
    contacts?: {
      [APIS_NAMES.CREDCESTA]: any
      pessoa: {
        cpf: string
        nome: string
        sexo: string
        fixos: {
          ddd: number
          plus: boolean
          numero: string
          ranking: number
          whatsapp: boolean
        }[]
        renda: number
        carros: {
          placa: string
          marca: string
          ano_fabricacao: number
          ano_modelo: number
          renavan: string
          chassi: string
          data_licenciamento: string
          ranking: number
        }[]
        emails: {
          email: string
          ranking: number
          possui_cookie: boolean
        }[]
        falecido: boolean
        nome_mae: string
        ocupacao?: string
        vinculos: {
          cpf_vinculo: string
          nome_vinculo: string
          tipo_vinculo: string
        }[]
        celulares: {
          ddd: number
          plus: boolean
          numero: string
          ranking: number
          whatsapp: boolean
        }[]
        enderecos: {
          uf: string
          cep: string
          tipo: string
          bairro: string
          cidade: string
          numero: string
          ranking: number
          endereco: string
          logradouro: string
          complemento?: string
          tipo_logradouro: string
          titulo_logradouro?: string
        }[]
        situacao_cpf: string
        risco_credito: {
          cpf_cnpj: string
          score_credito: string
        }
        data_nascimento: string
        participacao_societaria: {
          nome: string
          cnpj: string
          capital_social: number
          participacao_socio: number
          data_fundacao: string
          situacao_cadastral: string
        }[]
      }
      data_consulta: string
    }
  }
  type?: string
  payment?: {
    totalValue: string
    incomeValue: string
    firstPaymentDate: string
  }
  insurance?: {
    id: string
    insurancerCode: string
  }
  fleet?: {
    id: string
  }
  vehicle?: {
    taxi: string | boolean
    adapted: string | boolean
    vehicleTypeId?: string
  }
  offers?: {
    key: string
    tab: number
    offer: string
    sourceChannel: string
  }[]
  vlLimite: number
}
type Convention = {
  idConvenio?: number
  nome?: string
  limiteManual?: boolean
  limiteSaque?: number
  limiteCompra?: number
  percentualMargemSaque?: number
  percentualMargemCompra?: number
  averbacaoObrigatoria?: boolean
  obrigatorioIdAverbacao?: boolean
  orgaos?: {
    codigo: string
    descricao: string
  }[]
  logos?: {
    cdLogo: string
    descricaoGestor: string
  }[]
  multiplicadores?: {
    valorMultiploSaque: number
    prazo: number
    idadeMinima: number
    idadeMaxima: number
    tipoDescricao: string
  }[]
}
type Simulation = {
  id: number
  nsu: string
  dataBase: string
  numeroParcelas: number
  valorFinanciado: number
  valorParcela: number
  valorPrincipal: number
  valorBruto: number
  valorLiquido: number
  valorIOC: number
  taxaCliente: number
  taxaAP: number
  taxaCET: number
  taxaNominal: number
  data1Vcto: string
  dataUltVcto: string
  taxaCETMes: number
  taxaClienteAno: number
  auditoriaDigital: boolean
  option?: Option
  queryInstallment?: QueryInstallmentsData
}
type Option = {
  qtdParcelas: number
  vlLimite: number
  vlLimiteParcela: number
}
type QueryInstallmentsData = {
  id: number
  dataBase: string
  numeroParcelas: number
  valorFinanciado: number
  valorParcela: number
  valorPrincipal: number
  valorBruto: number
  valorLiquido: number
  valorIOC: number
  taxaCliente: number
  taxaAP: number
  taxaCET: number
  taxaNominal: number
  taxaCETMes: number
  taxaClienteAno: number
  valorTroco: number
  valorDevedorTotal: number
  auditoriaDigital: boolean
}
type OptionsProps = { name: number | string; value: number | string }
type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ProspectData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormProposalCredcesta = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [isLoading, setIsLoading] = useState(false)
  const [defaultValues, setDefaultValues] = useState<ProspectData>()
  const [contractValues, setContractValues] = useState<ProspectContacts>()
  const [firstPaymentDate, setFirstPaymentDate] = useState<Date>()
  const [minFirstPaymentDate, setMinFirstPaymentDate] = useState<Date>()
  const [maxFirstPaymentDate, setMaxFirstPaymentDate] = useState<Date>()
  const [issueDocumentDate, setIssueDocumentDate] = useState<Date>()
  const [minTotalValue, setMinTotalValue] = useState(0)
  const [maxTotalValue, setMaxTotalValue] = useState(0)
  const [valueSimulation, setValueSimulation] = useState<string>()
  const [cashoutErrors, setCashoutErros] = useState<any>({})
  const [totalValue, setTotalValue] = useState('')
  const [incomeValue, setIncomeValue] = useState('')
  const [installmentQuantity, setInstallmentAmount] = useState<OptionsProps[]>(
    []
  )
  const enumValuesInputAddress = useMemo(
    () => ({
      city: {
        input: 'city',
        submit: 'cidade'
      },
      district: {
        input: 'district',
        submit: 'bairro'
      },
      street: {
        input: 'street',
        submit: 'endereco'
      },
      state: {
        input: 'state',
        submit: 'uf'
      }
    }),
    []
  )
  const [inputTotalValueError, setInputTotalValueError] = useState({
    error: false,
    message: ''
  })
  const [paymentForms, setPaymentForms] = useState<OptionsProps[]>([])
  const [nationalities, setNationalities] = useState<OptionsProps[]>([])
  const [occupations, setOccuppations] = useState<OptionsProps[]>([])
  const [addressCities, setAddressCities] = useState<OptionsProps[]>([])
  const [birthCities, setBirthCities] = useState<OptionsProps[]>([])
  const [banks, setBanks] = useState<OptionsProps[]>([])
  const [simulations, setSimulations] = useState<Simulation[]>([])
  const [simulationData, setSimulationData] = useState<any>([])
  const { activeLoading, disableLoading, loading } = useLoading()
  const stateFields: { address: string; birth: string } = {
    address: 'customer.address.state',
    birth: 'customer.natural.birthState'
  }
  const [openSteps, setOpenSteps] = useState({
    simulation: true,
    choiceInstallments: false,
    proposal: false
  })
  const [selectedSimulation, setSelectedSimulation] = useState<
    Simulation | undefined
  >()
  const [conventions, setConventions] = useState<Convention[]>([])
  const [confirmedAddress, setConfirmedAddress] = useState(true)
  const [hasUpdate, setHasUpdate] = useState(false)

  const laodConventions = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get('/apis/credcesta/listConventions')
      const conventions = [...Object.values(response.data)] as {
        id: number
        nome: string
        limiteSaque?: number
        limiteCompra?: number
        percentualMargemSaque?: number
        percentualMargemCompra?: number
      }[]
      const sortConventios = conventions.sort(
        (currentConvention, nextConvention) =>
          currentConvention.nome.localeCompare(nextConvention.nome)
      )
      setConventions(
        sortConventios.map(
          convention =>
            convention && {
              ...convention,
              limiteSaque: convention.percentualMargemSaque,
              limiteCompra: convention.percentualMargemCompra
            }
        )
      )
      disableLoading()
    } catch (error: any) {
      // handleOnClose()
      addToast({
        type: 'error',
        title: 'Erro ao buscar convênios',
        description: error.response?.data.message
      })
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading])

  useEffect(() => {
    laodConventions()
  }, [laodConventions])

  useEffect(() => {
    if (initialValues) {
      const address: any = {}
      const enumValuesInputAddressEntries = Object.entries(
        enumValuesInputAddress
      )
      enumValuesInputAddressEntries.forEach(([, value]) => {
        address[value.input] =
          defaultValues?.prospect?.contacts?.[APIS_NAMES.CREDCESTA]?.[
            value.submit
          ]
      })
      const findConvention = conventions.find(
        item => item.idConvenio === ID_CONVENIO_CREDCESTA
      )
      const margemSaque = findConvention?.percentualMargemSaque / 100
      const vlLimite = initialValues.limiteDisponivel * margemSaque

      setDefaultValues({
        ...initialValues,
        vlLimite,
        convention: {
          ...findConvention,
          limiteCompra: findConvention?.percentualMargemCompra,
          limiteSaque: findConvention?.percentualMargemSaque,
          averbacaoObrigatoria: findConvention?.obrigatorioIdAverbacao
        },
        ...defaultValues?.prospect?.contacts?.[APIS_NAMES.CREDCESTA],
        address
      })
      setValueSimulation(initialValues?.valueSimulation?.toString() || '')
      if (initialValues?.valueSimulation) {
        setSimulations(initialValues?.installments)
        setOpenSteps(oldOpenSteps => ({
          ...oldOpenSteps,
          simulation: false,
          choiceInstallments: true
        }))
      }
    }
  }, [
    conventions,
    defaultValues?.prospect?.contacts,
    enumValuesInputAddress,
    initialValues
  ])
  const optionsStates = [
    {
      value: 'AC',
      name: 'ACRE'
    },
    {
      value: 'AL',
      name: 'ALAGOAS'
    },
    {
      value: 'AM',
      name: 'AMAZONAS'
    },
    {
      value: 'AP',
      name: 'AMAPÁ'
    },
    {
      value: 'BA',
      name: 'BAHIA'
    },
    {
      value: 'CE',
      name: 'CEARA'
    },
    {
      value: 'DF',
      name: 'DISTRITO FEDERAL'
    },
    {
      value: 'ES',
      name: 'ESPIRITO SANTO'
    },
    {
      value: 'GO',
      name: 'GOIAS'
    },
    {
      value: 'MA',
      name: 'MARANHÃO'
    },
    {
      value: 'MG',
      name: 'MINAS GERAIS'
    },
    {
      value: 'MS',
      name: 'MATO GROSSO DO SUL'
    },
    {
      value: 'MT',
      name: 'MATO GROSSO'
    },
    {
      value: 'PA',
      name: 'PARA'
    },
    {
      value: 'PB',
      name: 'PARAIBA'
    },
    {
      value: 'PE',
      name: 'PERNAMBUCO'
    },
    {
      value: 'PI',
      name: 'PIAUÍ'
    },
    {
      value: 'PR',
      name: 'PARANA'
    },
    {
      value: 'RJ',
      name: 'RIO DE JANEIRO'
    },
    {
      value: 'RN',
      name: 'RIO GRANDE DO NORTE'
    },
    {
      value: 'RO',
      name: 'RONDÔNIA'
    },
    {
      value: 'RR',
      name: 'RORAIMA'
    },
    {
      value: 'RS',
      name: 'RIO GRANDE DO SUL'
    },
    {
      value: 'SC',
      name: 'SANTA CATARINA'
    },
    {
      value: 'SE',
      name: 'SERGIPE'
    },
    {
      value: 'SP',
      name: 'SAO PAULO'
    },
    {
      value: 'TO',
      name: 'TOCANTINS'
    }
  ]
  const typesOccupation = [
    { name: 'Aposentado ou Pensionista', value: 1 },
    { name: 'Assalariado (Privado)', value: 2 },
    { name: 'MEI ou Empresário', value: 3 },
    { name: 'Profissional Liberal', value: 4 },
    { name: 'Autônomo', value: 5 },
    { name: 'Assalariado (Público)', value: 6 }
  ]
  const handleSimulation = useCallback(async () => {
    if (cashoutErrors?.error) {
      addToast({
        type: 'error',
        title: 'Campos Obrigatorios Com Erros',
        description: 'Corriga os erros desses campos para continuar'
      })
      return
    }
    activeLoading()
    try {
      const cpfString = unMaskCpfCnpj(defaultValues?.prospect?.cpf)
      const copySimulations = []
      for (const queryInstallment of defaultValues?.installments) {
        const response = await api.post('/apis/credcesta/simulation', {
          cpf: cpfString,
          idConvenio: ID_CONVENIO_CREDCESTA.toString(),
          valor: convertValueMaskInNumberWithTwoZero(valueSimulation),
          parcelas: queryInstallment.numeroParcelas,
          valorMaximo: queryInstallment.valorFinanciado,
          contratos: '',
          clienteNovo: false,
          prospect_query_api_id: defaultValues?.prospect_query_api_id
          // clienteNovo: !(queryData?.limiteUtilizado > 0)
        })
        copySimulations.push({
          ...response.data,
          queryInstallment,
          option: { ...defaultValues?.opcoes[0] },
          id: queryInstallment.id
        })
      }
      setSimulations(copySimulations)
      setOpenSteps(oldOpenSteps => ({
        ...oldOpenSteps,
        choiceInstallments: true,
        simulation: false
      }))
      disableLoading()
    } catch (error: any) {
      addToast({
        type: 'error',
        title: 'Erro ao fazer simulação',
        description: error?.response?.data.message
      })
      disableLoading()
    }
  }, [
    activeLoading,
    addToast,
    cashoutErrors?.error,
    defaultValues?.installments,
    defaultValues?.opcoes,
    defaultValues?.prospect?.cpf,
    defaultValues?.prospect_query_api_id,
    disableLoading,
    valueSimulation
  ])

  const handleSave = useCallback(
    async (data: any) => {
      const { handleOnClose } = isOpenInModal
      const enumValuesInputAddressEntries = Object.entries(
        enumValuesInputAddress
      )
      enumValuesInputAddressEntries.forEach(([, value]) => {
        data[value.submit] = data.address[value.input]
      })
      delete data.address
      activeLoading()
      try {
        const cpfString = unMaskCpfCnpj(defaultValues?.prospect?.cpf)
        data.banco = formatBank(data.banco, 3)
        data.agencia = formatBank(data.agencia, 4)
        data.digito = data.digito === '0' ? '' : data.digito
        const incomeValue = convertValueMaskInNumberWithTwoZero(
          `${defaultValues?.prospect?.contacts?.pessoa?.renda}`
        )

        const valorMargem = incomeValue * 0.19
        const limiteSaque = defaultValues?.convention?.limiteSaque / 100
        const formData = {
          cpf: cpfString,
          nsu: selectedSimulation?.nsu,
          nome: defaultValues?.prospect?.name,
          valor: defaultValues?.limiteDisponivel,
          tipoSaque: 'TED',
          embossoCartao: false,
          clienteConfirmaEnderecoEmbosso: confirmedAddress,
          idConvenio: ID_CONVENIO_CREDCESTA.toLocaleString(),
          alterarDadosBancarios: hasUpdate,
          idCanalVenda: 121,
          prospect_query_api_id: defaultValues?.prospect_query_api_id,
          prospect_id: defaultValues?.prospect?.id,
          idAverbacao: data.idAverbacao || '',
          aceitaPctVantagens: true,
          designarBeneficiarioCredcesta: false,
          cpfBeneficiarioCredcesta: '',
          nomeBeneficiarioCredcesta: '',
          parentescoBeneficiarioCredcesta: '',
          dataNascimentoBeneficiarioCredcesta: moment(
            defaultValues?.prospect?.birth_date,
            'DD/MM/YYYY'
          ).format('YYYY-MM-DD'),
          ...data
        }
        await api.post('apis/credcesta/save', formData)

        addToast({
          type: 'success',
          title: 'Proposta efetivada',
          description: 'Verifique o Sms que foi enviado para número cadastrado'
        })
        handleOnClose?.()
        disableLoading()
      } catch (error: any) {
        setOpenSteps(oldOpenSteps => ({
          ...oldOpenSteps,
          choiceInstallments: false,
          proposal: true,
          simulation: false
        }))

        addToast({
          type: 'error',
          title: 'Erro ao efetivar',
          description:
            error?.response?.data?.message ||
            'Ocorreu um erro ao enviar proposta'
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      confirmedAddress,
      defaultValues?.convention?.limiteSaque,
      defaultValues?.limiteDisponivel,
      defaultValues?.prospect?.birth_date,
      defaultValues?.prospect?.contacts?.pessoa?.renda,
      defaultValues?.prospect?.cpf,
      defaultValues?.prospect?.id,
      defaultValues?.prospect?.name,
      defaultValues?.prospect_query_api_id,
      disableLoading,
      enumValuesInputAddress,
      hasUpdate,
      isOpenInModal,
      selectedSimulation?.nsu
    ]
  )

  return (
    <div className="card p-5">
      <Loading isActive={isLoading} />
      {!!openSteps.simulation && (
        <Form onSubmit={handleSimulation}>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Input
              className="col-3"
              label="Valor de Saque"
              name="valor"
              rules={{ required: true }}
              value={valueSimulation}
              hasError={cashoutErrors}
              onChange={event => {
                if (
                  convertValueMaskInNumberWithTwoZero(event.target.value) >
                  defaultValues?.vlLimite
                ) {
                  setCashoutErros({
                    error: true,
                    message: 'Valor de saque maior que o disponivel'
                  })
                }
                if (cashoutErrors) {
                  setCashoutErros(undefined)
                }
                setValueSimulation(
                  genericMaskWithTwoZeroWithPoint(event.target.value)
                )
              }}
              controlled
            />
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Simular
            </button>
          </div>
        </Form>
      )}
      {!!openSteps.choiceInstallments && (
        <>
          <CustomDataTable
            customItems={simulations}
            headers={[
              {
                name: '',
                field: 'checkbox',
                sortable: false,
                custom: true
              },
              {
                name: 'Valor Disponivel',
                field: 'valorFinanciado',
                sortable: true,
                custom: true
              },
              {
                name: 'Prazo (Meses)',
                field: 'numeroParcelas',
                sortable: true
              },
              {
                name: 'Valor Parcela',
                field: 'valorParcela',

                sortable: true,
                custom: true
              },
              {
                name: 'Valor IOF',
                field: 'valorIOC',

                sortable: true,
                custom: true
              },
              {
                name: 'Valor Principal',
                field: 'valorPrincipal',
                sortable: true,
                custom: true
              },
              {
                name: 'Juros a.m',
                field: 'taxaCliente',

                sortable: true,
                custom: true
              },
              {
                name: 'Juros a.m CET',
                field: 'taxaCETMes',

                sortable: true,
                custom: true
              },
              {
                name: '1º vencimento',
                field: 'data1Vcto',

                sortable: true,
                custom: true
              },
              {
                name: 'Ultimo vencimento',
                field: 'dataUltVcto',
                sortable: true,
                custom: true
              }
              // { name: 'Ações', field: 'actions', sortable: false }
            ]}
            // actions={[
            //   {
            //     name: 'view',
            //     title: 'Visualizar',
            //     spanIcon: 'fa fa-check',
            //     onClick: item => handleClickOnOpenModalView(item)
            //   }
            // ]}
            customHeaders={[
              {
                name: '',
                field: 'checkbox',
                sortable: true,
                element: item => {
                  return (
                    <input
                      type="checkbox"
                      checked={item?.id === selectedSimulation?.id}
                      onClick={() => {
                        setSelectedSimulation(item)
                        setOpenSteps({
                          ...openSteps,
                          choiceInstallments: true,
                          proposal: true
                        })
                      }}
                    />
                  )
                }
              },
              {
                name: 'Valor Disponivel',
                field: 'valorFinanciado',
                sortable: true,
                element: item => {
                  return <p>{BRL(item?.valorFinanciado).format()}</p>
                }
              },
              {
                name: 'Valor Parcela',
                field: 'valorParcela',
                sortable: true,
                element: item => {
                  return <p>{BRL(item?.valorParcela).format()}</p>
                }
              },
              {
                name: 'Valor IOF',
                field: 'valorIOC',
                sortable: true,
                element: item => {
                  return <p>{BRL(item?.valorIOC).format()}</p>
                }
              },
              {
                name: 'Valor Principal',
                field: 'valorPrincipal',
                sortable: true,
                element: item => {
                  return <p>{BRL(item?.valorPrincipal).format()}</p>
                }
              },
              {
                name: 'Juros a.m',
                field: 'taxaCliente',
                sortable: true,
                element: item => {
                  return (
                    <p>
                      {BRL(item?.taxaCliente, {
                        symbol: '%',
                        pattern: '# !'
                      }).format()}
                    </p>
                  )
                }
              },
              {
                name: 'Juros a.m CET',
                field: 'taxaCETMes',
                sortable: true,
                element: item => {
                  return (
                    <p>
                      {BRL(item?.taxaCETMes, {
                        symbol: '%',
                        pattern: '# !'
                      }).format()}
                    </p>
                  )
                }
              },
              {
                name: '1º vencimento',
                field: 'data1Vcto',
                sortable: true,
                element: item => {
                  return (
                    <p>{moment(item?.data1Vcto).format(dateInputFormat)}</p>
                  )
                }
              },
              {
                name: 'Ultimo vencimento',
                field: 'dataUltVcto',
                sortable: true,
                element: item => {
                  return (
                    <p>{moment(item?.dataUltVcto).format(dateInputFormat)}</p>
                  )
                }
              }
            ]}
          />
          {/* <div className="card-footer d-flex justify-content-end px-0">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if (selectedSimulation) {
                  setOpenSteps({
                    ...openSteps,
                    choiceInstallments: true,
                    proposal: true
                  })
                  return
                }
                addToast({
                  title: 'Selecione uma opção',
                  description: 'Selecione uma das opções',
                  type: 'info'
                })
              }}
            >
              Continuar
            </button>
          </div> */}
        </>
      )}
      {!!openSteps.proposal && (
        <Form onSubmit={handleSave} defaultValues={defaultValues}>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Input
              className="col-2  "
              label="Celular"
              name="celular"
              rules={{ required: true }}
              mask={{
                mask: '(99) 99999-9999'
              }}
              controlled
            />
            <Input
              className="col-2"
              label="Email"
              name="email"
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email inválido'
                }
              }}
            />
            {!!defaultValues?.convention?.averbacaoObrigatoria && (
              <Input
                className="col-2"
                label="Id. Averbação"
                name="idAverbacao"
                rules={{
                  required: defaultValues?.convention?.averbacaoObrigatoria
                }}
              />
            )}
            <Input
              className="col-1"
              label="Cod. Banco"
              name="banco"
              rules={{
                required: true,
                maxLength: { value: 3, message: 'No máximo 3 caracteres' }
              }}
            />
            <Input
              className="col-1"
              label="Agência"
              name="agencia"
              rules={{
                required: true
              }}
            />
            <Input
              className="col-2"
              label="Conta Corrente"
              name="conta"
              rules={{ required: true }}
            />
            <Input
              className="col-1"
              label="Dígito"
              name="digito"
              rules={{ required: true }}
            />
          </div>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Input
              className="col-2  "
              label="CEP"
              name="cep"
              mask={{
                mask: '99999-999',
                type: 'zipCode',
                prefixInputsData: 'address.'
                // excludeFields: [stateFields.address, stateFields.birth]
              }}
              controlled
              rules={{
                required: true,
                minLength: { value: 8, message: 'Cep Inválido' }
              }}
            />
            <Input
              className="col-2  "
              label="Cidade"
              name={`address.${enumValuesInputAddress.city.input}`}
              rules={{ required: true }}
            />
            <Input
              className="col-2  "
              label="Bairro"
              name={`address.${enumValuesInputAddress.district.input}`}
              rules={{ required: true }}
              maxLength={25}
            />
            <Input
              className="col-2  "
              label="Logradouro"
              name={`address.${enumValuesInputAddress.street.input}`}
              rules={{ required: true }}
            />

            <Input
              className="col-2"
              label="Número"
              name="numero"
              rules={{ required: true }}
            />
            <Select
              className="col-2"
              label="UF"
              name={`address.${enumValuesInputAddress.state.input}`}
              rules={{ required: true }}
              options={[...states]}
              blank
              defaultValue=""
            />
          </div>
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Input className="col-4" label="Complemento" name="complemento" />
          </div>
          <div className="card-footer d-flex justify-content-end px-0">
            <div className="col-sm form-check form-check-sm form-check-custom form-check-outline">
              <input
                className="form-check-input me-2"
                type="checkbox"
                checked={confirmedAddress}
                onChange={() => setConfirmedAddress(!confirmedAddress)}
              />
              <span>Cliente confirma endereço para envio do cartão</span>
            </div>
            <div className="col-sm d-flex justify-content-end container-button">
              <button type="submit" className="btn btn-primary">
                Enviar Proposta
              </button>
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}
