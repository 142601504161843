import styled, { css } from 'styled-components'
import { animated } from 'react-spring'

export const FormContainer = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 1rem;

  > div {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1fr 1fr 0.7fr;
    margin-left: 1rem;
  } */
`

interface ContanierProps {
  type?: 'success' | 'error' | 'info'
  hasDescription: boolean
  isShow: boolean
}

const toastTypeVariante = {
  info: css`
    background: #ebf8ff;
    color: #3172b7;
  `,
  success: css`
    background: #e6fffa;
    color: #2e656a;
  `,
  error: css`
    background: #fddede;
    color: #c53030;
  `
}

export const ContainerToast = styled(animated.div)<ContanierProps>`
  width: 320px;
  position: absolute;
  top: -40vh;
  left: 30vw;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  overflow-wrap: break-word;
  overflow: hidden;
  height: 0;
  ${props => toastTypeVariante[props.type || 'info']};
  ${props =>
    props.isShow
      ? css`
          height: 120px;
          padding: 16px 30px 16px 16px;
        `
      : ''}
  > svg {
    margin: 4px 12px 0 0;
  }
  & + div {
    margin-top: 8px;
  }

  div {
    flex: 1;
    p {
      margin-top: 4px;
      width: 80%;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${props =>
    !props.hasDescription &&
    css`
      align-items: center;
      svg {
        margin-top: 0;
      }
    `}
`
