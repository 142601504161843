export const OPTIONS_TYPE_PERSON = [
  {
    name: 'Person'
  }
]
export const SPACING_ROW_FORM = '2'
export const ROLE_PARTNER_TEAM = 'P'
export const dateInputFormat = 'DD/MM/YYYY'
export const optionsMaritalStatus = [
  {
    name: 'Solteiro (a)',
    value: 'Solteiro'
  },
  {
    name: 'Casado (a)',
    value: 'Casado'
  },
  {
    name: 'Divorciado (a)',
    value: 'Divorciado'
  },
  {
    name: 'Outros',
    value: 'Outros'
  }
]
export const PROSPECT_STATUS = [
  // {
  //   name: 'Novo',
  //   value: 'new',
  //   disabled:
  // },
  {
    name: 'Distribuído',
    value: 'distributed'
  },
  { name: 'Não Interessada', value: 'not_interested' },
  { name: 'Não perturbe', value: 'do_not_disturb' },
  { name: 'Venda Completa', value: 'completed_sale' },
  { name: 'Sem Margem', value: 'no_margin' },
  { name: 'Reprovado na Analise de Crédito', value: 'failed_credit_analysis' },
  { name: 'Em Atendimento', value: 'in_attendance' },
  {
    name: 'Cliente Não Pertence ao Convênio/Fora da Base',
    value: 'client_not_belong_agreement'
  }
].sort((previousItem, currentItem) =>
  previousItem.name.localeCompare(currentItem.name)
)

export const LOCAL_STORAGE_KEYS = {
  SELECTED_MENU: '@Cedibra:SelectedMenu',
  TOKEN: '@Cedibra:token',
  USER: '@Cedibra:user',
  MENUS: '@Cedibra:menus',
  PROSPECT_LIST: '@Cedibra:prospect-list',
  PROSPECT_HAS_HISTORY: '@Cedibra:prospect-has-history',
  LIST_PROSPECTS_FILTERS: '@Cedibra:list-prospects-filters'
}

export const MANAGER_ROLES = [1, 2, 3, 4, 5]
export const ROLE_PARTNER_ID = 7
export const ROLE_AFFILIATE_PARTNER_ID = 9
export const OLD_ROLE_PARTNER_ID = 5
export const OLD_AFFILIATE_PARTNER_ID = 8
export const ROLE_OPERATOR_ID = 8

export const MASKS = {
  DATE: {
    LOCALE: 'DD/MM/YYYY',
    ISO: 'YYYY-MM-DD'
  },
  DOCUMENT: {
    CPF: '999.999.999-99'
  }
}

export const HANDLE_ERROS_MESSAGE = [
  {
    error: 'Cpf already used',
    message: 'CPF já cadastrado'
  },
  {
    error: 'Not found.',
    message: 'CPF informado não foi encontrado na base de dados.'
  }
]

export enum APIS_NAMES {
  SIM = 'Sim',
  LEMIT = 'Lemit',
  CREDCESTA = 'Credcesta',
  FGTS = 'Fgts'
}
export const states = [
  {
    value: 'RO',
    name: 'Rondônia'
  },
  {
    value: 'AC',
    name: 'Acre'
  },
  {
    value: 'AM',
    name: 'Amazonas'
  },
  {
    value: 'RR',
    name: 'Roraima'
  },
  {
    value: 'PA',
    name: 'Pará'
  },
  {
    value: 'AP',
    name: 'Amapá'
  },
  {
    value: 'TO',
    name: 'Tocantins'
  },
  {
    value: 'MA',
    name: 'Maranhão'
  },
  {
    value: 'PI',
    name: 'Piauí'
  },
  {
    value: 'CE',
    name: 'Ceará'
  },
  {
    value: 'RN',
    name: 'Rio Grande do Norte'
  },
  {
    value: 'PB',
    name: 'Paraíba'
  },
  {
    value: 'PE',
    name: 'Pernambuco'
  },
  {
    value: 'AL',
    name: 'Alagoas'
  },
  {
    value: 'SE',
    name: 'Sergipe'
  },
  {
    value: 'BA',
    name: 'Bahia'
  },
  {
    value: 'MG',
    name: 'Minas Gerais'
  },
  {
    value: 'ES',
    name: 'Espírito Santo'
  },
  {
    value: 'RJ',
    name: 'Rio de Janeiro'
  },
  {
    value: 'SP',
    name: 'São Paulo'
  },
  {
    value: 'PR',
    name: 'Paraná'
  },
  {
    value: 'SC',
    name: 'Santa Catarina'
  },
  {
    value: 'RS',
    name: 'Rio Grande do Sul'
  },
  {
    value: 'MS',
    name: 'Mato Grosso do Sul'
  },
  {
    value: 'MT',
    name: 'Mato Grosso'
  },
  {
    value: 'GO',
    name: 'Goiás'
  },
  {
    value: 'DF',
    name: 'Distrito Federal'
  }
].sort((currentState, nextState) =>
  currentState.name.localeCompare(nextState.name)
)

export const API_NAME_ENUM = {
  SANTANDER_MARGIN: 'santander_margin',
  SANTANDER_ACCOUNT_HOLDER: 'santander_account_holder'
}

export const API_NAME_TRANSLATE_ENUM: Record<string, string> = {
  santander_margem: 'Santander margem',
  santander_correntista: 'Santander correntista',
  credcesta: 'Banco Master',
  credcesta_card: 'Titulo de capitalização Cap Já',
  lemit: 'Lemit',
  qi: 'QI'
}
