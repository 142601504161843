import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Config/Users/PermissionAssociated/List'
import Update from '../../pages/Config/Users/PermissionAssociated/Update'
import Create from '../../pages/Config/Users/PermissionAssociated/Create'
import View from '../../pages/Config/Users/PermissionAssociated/View'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PermissionsAssociatedRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/users/permissionsAssociated" exact component={List} />
    <PrivateRoutes
      path="/users/permissionsAssociated/update/:id"
      component={Update}
      exact
    />
    <PrivateRoutes
      path="/users/permissionsAssociated/create"
      component={Create}
      exact
    />
    <PrivateRoutes
      path="/users/permissionsAssociated/view/:id"
      component={View}
      exact
    />
  </CustomSwitch>
)
