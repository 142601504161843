export const headers = [
  {
    name: 'Código',
    field: 'agreement_number',
    sortable: true,
    searchable: true
  },
  { name: 'Nome', field: 'name', sortable: true, searchable: true },
  { name: 'CNPJ', field: 'cnpj', sortable: true, searchable: true },
  { name: 'Prospecções', field: 'prospects', sortable: true, type: 'monetary' },
  { name: 'Ações', field: 'actions', sortable: false }
]
