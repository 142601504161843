import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  ThemeModeSwitcher
} from '../../../partials'

const AsideFooter = () => {
  return (
    <div
      className="aside-footer d-flex flex-column align-items-center flex-column-auto"
      id="kt_aside_footer"
    >
      {/* end::Activities */}

      {/* begin::Notifications */}
      <div className="d-flex align-items-center mb-2">
        {/* begin::Menu wrapper */}
        {/* end::Menu wrapper */}
        {/* <HeaderNotificationsMenu backgrounUrl="/media/misc/pattern-1.jpg" /> */}
      </div>
      {/* end::Notifications */}

      {/* begin::User */}
      <div
        className="d-flex align-items-center mb-10"
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Menu wrapper */}
        <div
          className="cursor-pointer symbol symbol-40px"
          data-kt-menu-trigger="click"
          data-kt-menu-overflow="false"
          data-kt-menu-placement="top-start"
          title="Perfil"
        >
          <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="avatar" />
        </div>
        {/* end::Menu wrapper */}
        <HeaderUserMenu />
      </div>
      {/* end::User */}
    </div>
  )
}

export { AsideFooter }
