import styled from 'styled-components'

export const FormContainer = styled.div`
  div.hidden {
    height: 0 !important;
    overflow: hidden !important;
    width: 0 !important;
    position: absolute !important;
  }
  div.show {
    /* height: 100px !important;
    width: 100px !important; */
  }
`
