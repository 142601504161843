import { PageLink } from '../../../../../../../assets/layout/core'

export const breadcrumbView: PageLink[] = [
  {
    title: 'Permissão',
    path: 'users/permissions',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
]
