import React, { useCallback, useEffect } from 'react'

import { useLoading } from 'hooks/loading'
import api from 'services/api'
import { useParams } from 'react-router-dom'
import { useToast } from 'hooks/toast'
import { Alert } from 'components/Alert'

const VerifyEmail: React.FC = () => {
  const { token } = useParams<{ token: string }>()

  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()

  const handleRedirect = useCallback(() => {
    window.location.replace('https://app.cedibra.com/')
  }, [])

  const handleValidateEmail = useCallback(async () => {
    activeLoading()
    try {
      await api.get(`/commercial/partners/email/verify/validate/${token}`)
      setTimeout(() => {
        handleRedirect()
      }, 30 * 1000)
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error ao validar o seu email',
        description:
          'Houve um error ao validar o email, tente novamente mais tarde!'
      })
    }
    disableLoading()
  }, [activeLoading, addToast, disableLoading, handleRedirect, token])

  useEffect(() => {
    handleValidateEmail()
  }, [handleValidateEmail])

  return (
    <Alert
      message="Email validado com sucesso"
      onClickConfirmButton={handleRedirect}
      onlyConfirm
      isActive
      title="Dados do parceiro atualizado"
      textConfim="Continuar"
      style={{ minWidth: '350px' }}
    />
  )
}
export default VerifyEmail
