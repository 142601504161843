import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../..//hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import modelImportProspects from '../../../../../assets/model-import-prospects.csv'
import Papa from 'papaparse'
import { HEADERS_PROSPECT_FILE } from 'common/constants/Commercial/Prospects'
import { IMaskInput } from 'react-imask'
import { Date as DatePicker } from 'components/Form/date'
import moment from 'moment'
import { handleErrorMessage } from 'utlis/handleErros'
import { Datalist } from 'components/Datalist'
import { formatBank } from 'utlis/mask'
type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type ProspectData = {
  agreement_group_id?: string
  agency?: string
  birth_date?: string | Date
  admission_date?: string | Date
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ProspectData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

const inputMaskFormat = 'DD/MM/YYYY'

export const FormProspect = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const [type, setType] = useState(typeForm === 'create')
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [birthDate, setBirthDate] = useState<Date>()
  const [admissionDate, setAdmissionDate] = useState<Date>()
  const [defaultValues, setDefaultValues] = useState<ProspectData>()
  const [agreementGroupValue, setAgreementGroupValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [agreementGroups, setAgreementGroups] = useState<
    { id: number; value: number; name: string }[]
  >([{ id: 0, value: 0, name: '' }])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const getAgreementGroups = useCallback(async () => {
    try {
      const response = await api.get('/operational/agreementGroups')
      const agreementGroupsSelect = response.data?.map(
        (agreementGroup: { id: number; name: string }) => ({
          value: agreementGroup.id,
          name: agreementGroup.name,
          id: agreementGroup.id
        })
      )
      setAgreementGroups(agreementGroupsSelect)
    } catch (error: any) {
      const statusCode = error?.response.status
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title:
          statusCode === 403
            ? 'Você não tem permissão'
            : 'Error ao carregar os parceiros',
        description:
          statusCode === 403
            ? 'Você não tem permissão para acessar essa página'
            : 'Houve um error ao carregar os parceiros, tente novamente mais tarde!'
      })
      if (path.includes('update')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [addToast, disableLoading, history])

  useEffect(() => {
    getAgreementGroups()
  }, [getAgreementGroups])

  const onSubmitForm = async (data: ProspectData) => {
    const id = initialValues?.idUpdate
    data.agreement_group_id = `${agreementGroupValue?.id}`
    if (data.agency) {
      data.agency = formatBank(data.agency, 4)
    }
    data.birth_date = moment(data.birth_date, inputMaskFormat).format(
      inputMaskFormat
    )
    if (data.admission_date) {
      data.admission_date = moment(data.admission_date, inputMaskFormat).format(
        inputMaskFormat
      )
    }
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]: any) => {
      if (value !== 'undefined' && value) {
        if (key === 'file') {
          formData.append(key, value[0])
        } else {
          formData.append(key, value)
        }
      }
    })
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          activeLoading()
          try {
            await api.post(apiCreate(), formData)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            activeLoading()
            await api.post(apiCreate(), formData)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error: any) {
            addToast({
              ...handleErrorMessage(error)
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error: any) {
            history.push(nameActions.read.to)
            addToast({
              ...handleErrorMessage(error)
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  const handleOnChangeFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          const headers = Object.keys(results?.data?.[0])
          HEADERS_PROSPECT_FILE.every((header, index) => {
            if (headers[index] !== header) {
              addToast({
                type: 'error',
                title: 'Arquivo Invalido',
                description:
                  'Esse aquivo é invalido, faça download do arquivo modelo, ajuste os campos e tente novamente'
              })
              event.target.value = null
              return false
            }
            return header
          })
        }
      })
      return event.target.value
    },
    [addToast]
  )
  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <div className="form">
        {typeForm === 'create' && (
          <div className="row">
            <p className=" fw-bold fs-4">Como Deseja Adicionar Os Clientes?</p>
            <div className="row form-check form-check-custom form-check-solid">
              <Input
                name="type2"
                label="Importação"
                type="radio"
                className="col-1 me-5"
                inputOptions={{
                  className: 'form-check-input p-0'
                }}
                checked={type}
                onChange={() => setType(true)}
                controlled
              />
              <Input
                name="type1"
                label="Manual"
                type="radio"
                checked={!type}
                className="col-1 "
                inputOptions={{
                  className: 'form-check-input p-0'
                }}
                onChange={() => setType(false)}
                controlled
              />
            </div>
          </div>
        )}
        {type && (
          <div>
            <div className="row mb-2">
              <Datalist
                data={agreementGroups}
                label="Grupo de Convênio"
                setValue={setAgreementGroupValue}
                value={agreementGroupValue}
              />

              <Input
                name="file"
                label="Arquivo"
                type="file"
                className="col"
                accept=".csv"
                rules={{ required: true }}
                onChange={handleOnChangeFile}
                CustomLabelContainer={({ children }) => (
                  <label className="col-form-label fw-bold fs-6 d-flex">
                    {children}
                    <a
                      href={modelImportProspects}
                      download="modelo-de-importação"
                      className="ms-5 d-flex align-items-center"
                    >
                      <span className="fa fa-download me-2"></span>
                      <p>Modelo</p>
                    </a>
                  </label>
                )}
              />
            </div>
          </div>
        )}
        {!type && (
          <div>
            <div className="row mb-2">
              <Input
                name="name"
                label="Nome"
                rules={{
                  required: true,
                  minLength: { message: 'Nome e Sobrenome', value: 13 }
                }}
                className="col-6"
              />
              <Input
                name="cpf"
                label="CPF"
                mask={{
                  mask: '999.999.999-99',
                  type: 'cpf'
                }}
                controlled
                rules={{ required: true }}
                className="col"
              />
              <DatePicker
                name="birth_date"
                className="col"
                label="Data de Nascimento"
                placeholderText="DD/MM/AAAA"
                dateFormat="dd/MM/yyyy"
                selected={birthDate}
                rules={{ required: true }}
                customInput={
                  <IMaskInput
                    mask={Date}
                    pattern={'d/m/Y'}
                    format={date => {
                      return moment(date).format(inputMaskFormat)
                    }}
                    parse={value => {
                      return moment(value, inputMaskFormat).toDate()
                    }}
                    validate={(date: string) => {
                      if (date.length === 10) {
                        if (moment(date, inputMaskFormat).isAfter(moment())) {
                          return false
                        }
                        const age = moment().diff(
                          moment(date, inputMaskFormat),
                          'year'
                        )
                        if (age < 18) {
                          return false
                        }
                      }
                      return true
                    }}
                  />
                }
                onChange={date => {
                  setBirthDate(date)
                }}
                controlled
              />
            </div>
            <div className="row mb-2">
              <DatePicker
                name="admission_date"
                className="col-2"
                label="Data de Admissão"
                placeholderText="DD/MM/AAAA"
                dateFormat="dd/MM/yyyy"
                selected={admissionDate}
                maxDate={new Date()}
                customInput={
                  <IMaskInput
                    mask={Date}
                    pattern={'d/m/Y'}
                    format={date => {
                      return moment(date).format(inputMaskFormat)
                    }}
                    parse={value => {
                      return moment(value, inputMaskFormat).toDate()
                    }}
                  />
                }
                onChange={date => {
                  setAdmissionDate(date)
                }}
                controlled
              />
              <Datalist
                data={agreementGroups}
                label="Grupo de Convênio"
                setValue={setAgreementGroupValue}
                value={agreementGroupValue}
                className="col-4"
              />
              <Input
                name="agency"
                label="Agencia"
                mask={{
                  mask: '9999'
                }}
                controlled
                className="col-1"
              />
              <Input name="account" label="Conta" className="col-2" />
            </div>
          </div>
        )}
      </div>
      <div className="card-footer d-flex justify-content-end py-6 ps-9 pe-0">
        <button type="submit" className="btn btn-primary">
          Salvar
        </button>
      </div>
    </Form>
  )
}
