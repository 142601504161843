import { PageLink } from '../../../../../../assets/layout/core'

export const breadcrumbView: PageLink[] = [
  {
    title: 'Prospecção ',
    path: '/commercial/prospects',
    isSeparator: false,
    isActive: false
  },
  {
    title: ' ',
    path: ' ',
    isSeparator: true,
    isActive: false
  }
]
