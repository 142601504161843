import { SPACING_ROW_FORM } from 'common/constants'
import Container from 'components/Container'
import Form, { Input } from 'components/Form'
import { useToast } from 'hooks/toast'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'
import { StringParam, useQueryParam } from 'use-query-params'
import { AlertExportText } from './styles'
import moment from 'moment'
import { Loading } from 'components/Loading'

const CURRENT_DATE_ISO = moment().format('YYYY-MM-DD')

const ProspectExportCredcesta: React.FC = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [exportQuery] = useQueryParam('export', StringParam)
  const [agreementGroupIdQuery] = useQueryParam(
    'agreement_group_id',
    StringParam
  )
  const [defaultdValues, setDefaultedValues] = useState({})
  const handleQuery = useCallback(
    async data => {
      console.log(data)
      const formData = new FormData()
      formData.set('agreement_id', data.agreement_id)
      formData.set('file', data.file[0])
      try {
        const response = await api.post(
          '/public/credcesta/publicQuery',
          formData
        )
        addToast({
          title: 'Consulta em andamento',
          description: 'A consulta foi iniciada',
          type: 'success'
        })
        history.replace(response.data.link)
      } catch (error) {
        console.log(error)
      }
    },
    [addToast, history]
  )
  const handleExport = useCallback(async data => {
    setIsLoading(true)
    const currentDateInTimestamp = moment().valueOf()
    console.log(data)
    const formData = new FormData()
    formData.set('agreement_group_id', data.agreement_group_id)
    formData.set('file', data.file[0])
    try {
      const response = await api.post('/public/credcesta/exportQuery', formData)
      const blob = new Blob(['\ufeff' + response.data], {
        type: 'text/csv;charset=utf-8"'
      })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.download = `${currentDateInTimestamp}-consulta-lista-${CURRENT_DATE_ISO}.csv`
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    setDefaultedValues({
      agreement_group_id: agreementGroupIdQuery
    })
  }, [agreementGroupIdQuery])

  return (
    <>
      <Loading isActive={isLoading} />
      <Container pageTitle={''} breadcrumb={[]} tools={[]}>
        <Form
          onSubmit={(data: any) => {
            if (exportQuery) {
              handleExport(data)
              return
            }
            handleQuery(data)
          }}
          defaultValues={defaultdValues}
        >
          {exportQuery && (
            <div>
              <AlertExportText>
                A consulta foi iniciado, não recarregue a página ou feche a
                janela, espere a finalização da consulta e clique no botão{' '}
                {'"EXPORTAR"'}
              </AlertExportText>
            </div>
          )}
          <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
            <Input name="file" label="Arquivo" className="col-4" type="file" />
            {!exportQuery && (
              <Input name="agreement_id" label="Convenio" className="col-4" />
            )}
            {exportQuery && (
              <Input
                name="agreement_group_id"
                label="Grupo"
                className="col-4"
              />
            )}
          </div>

          <div className="mt-2 ard-footer d-flex justify-content-end py-6 ps-9 pe-0">
            <button type="submit" className="btn btn-primary">
              {exportQuery ? 'Exportar' : 'Consultar'}
            </button>
          </div>
        </Form>
      </Container>
    </>
  )
}
export default ProspectExportCredcesta
