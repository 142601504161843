import styled from 'styled-components'

export const Contanier = styled.div`
  position: fixed;
  right: 50%;
  top: 0;
  padding: 30px;
  overflow: hidden;
  transform: translateX(50%);
  z-index: 199;
`
