/* eslint-disable quote-props */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import moment from 'moment'
import {
  BRL,
  convertValueMaskInNumberWithTwoZero,
  formatBank,
  genericMaskWithTwoZeroWithPoint,
  phoneMask,
  unMaskCpfCnpj,
  unMaskPhone,
  unMaskZipCode
} from '../../../../../utlis/mask'
import { IMaskInput } from 'react-imask'
import { ID_CONVENIO_CREDCESTA } from '../../../../../common/constants/Commercial/Prospects'
import { handleErrorMessage } from 'utlis/handleErros'
import {
  dateInputFormat,
  MASKS,
  optionsMaritalStatus,
  SPACING_ROW_FORM,
  states
} from 'common/constants'
import { toDate } from 'date-fns'
import { FormFgts } from '../FormFgts'
import generateOptions from 'utlis/generateOptions'
import { useAuth } from 'hooks/auth'
type Option = {
  qtdParcelas: number
  vlLimite: number
  vlLimiteParcela: number
}
type QueryData = {
  cpf: string
  nome: string
  idConvenio: string
  convention?: Convention
  matricula: string
  vlMultiploSaque?: number
  limiteUtilizado?: number
  limiteTotal?: number
  limiteDisponivel?: number
  vlLimiteParcela?: number
  limiteParcelaUtilizado?: number
  limiteParcelaDisponivel?: number
  vlMultiploCompra?: number
  vlLimiteCompra?: number
  prazo?: string
  vlLimite?: string
  opcoes: Option[]
}

type Customer = {
  name: string
  email: string
  dateOfBirthFoundation: string
  document: string
  cellPhone: {
    ddd: number
    number: string
  }
}

type Contacts = {
  pessoa: {
    nome: string
    nome_mae: string
    sexo: string
    cpf: string
    ocupacao?: string
    emails: {
      email: string
      ranking: number
      possui_cookie: boolean
    }[]
    celulares: {
      ddd: number
      plus: boolean
      numero: string
      ranking: number
      whatsapp: boolean
    }[]
  }
}

type ProspectData = {
  id?: number
  agreement_group_id?: string
  prospect_query_api_id?: string
  name?: string
  birth_date?: string
  cpf?: string
  cnpj?: string
  customer?: Customer
  contacts?: Contacts
  quantidadePeriodos?: string
  valorAdiantamentoSolicitado?: string
}

export enum TypePerson {
  PF = 'pessoa'
}

export enum ApisOptions {
  SIM = '1',
  LEMIT = '2',
  CREDCESTA = '3',
  FGTS = '4'
}

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: ProspectData
  defaultApi?: string
  typeForm?: 'sim' | 'lemit'
}

type Convention = {
  idConvenio?: number
  nome?: string
  limiteManual?: boolean
  limiteSaque?: number
  limiteCompra?: number
  percentualMargemSaque?: number
  percentualMargemCompra?: number
  averbacaoObrigatoria?: boolean
  obrigatorioIdAverbacao?: boolean
  orgaos?: {
    codigo: string
    descricao: string
  }[]
  logos?: {
    cdLogo: string
    descricaoGestor: string
  }[]
  multiplicadores?: {
    valorMultiploSaque: number
    prazo: number
    idadeMinima: number
    idadeMaxima: number
    tipoDescricao: string
  }[]
}
type IsOpenInModalProps = {
  idParent?: number
  handleOnClose: () => void
}

export const FormQueryApi = ({
  isOpenInModal,
  initialValues,
  defaultApi
}: TypesFormProps): JSX.Element => {
  const { user } = useAuth()
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const { updateDataTable } = useUpdateDataTable()
  const [birthDate, setBirthDate] = useState<Date>()
  const [issueDate, setIssueDate] = useState<Date>()
  const [defaultValues, setDefaultValues] = useState<ProspectData>()
  const [conventions, setConventions] = useState<Convention[]>([])
  const [selectedApi, setSelectedApi] = useState<string>(defaultApi || '')
  const [clientData, setClientData] = useState<any>()
  const [valueSimulation, setValueSimulation] = useState<string>()
  const [stepsRegisterCredcesta, setStepsRegisterCredcesta] = useState({
    register: false,
    simulation: false
  })
  const { handleOnClose } = isOpenInModal
  const enumValuesInputAddress = useMemo(
    () => ({
      city: {
        input: 'city',
        submit: 'cidadeRes'
      },
      district: {
        input: 'district',
        submit: 'bairroRes'
      },
      street: {
        input: 'street',
        submit: 'enderecoRes'
      },
      state: {
        input: 'state',
        submit: 'ufRes'
      }
    }),
    []
  )

  const handleSelectedApiDefaultValues = {
    [ApisOptions.CREDCESTA]: (data: ProspectData) => {
      let celular = ''
      if (
        data?.contacts?.pessoa?.celulares?.[0]?.ddd &&
        data?.contacts?.pessoa?.celulares?.[0]?.numero
      ) {
        celular = `${data?.contacts?.pessoa?.celulares?.[0]?.ddd}${unMaskPhone(
          data?.contacts?.pessoa?.celulares?.[0]?.numero
        )}`
      }
      return {
        ...data,
        nome: data.contacts?.pessoa?.nome || data.name,
        nomeMmae: data.contacts?.pessoa?.nome_mae || '',
        flSexo: data.contacts?.pessoa?.sexo || '',
        celular,
        email: data?.contacts?.pessoa?.emails?.[0]?.email || '',
        dataNascimento: data?.birth_date || '',
        profissao: data?.contacts?.pessoa?.ocupacao || ''
      }
    },
    [ApisOptions.SIM]: (data: ProspectData) => {
      return {
        ...data,
        customer: {
          name: data?.name,
          email: data?.contacts?.pessoa?.emails[0]?.email || '',
          dateOfBirthFoundation: data?.birth_date,
          document: data?.contacts?.pessoa?.cpf,
          cellPhone: {
            ddd: data?.contacts?.pessoa?.celulares[0]?.ddd,
            number: phoneMask(data?.contacts?.pessoa?.celulares[0]?.numero, {
              whitoutDDD: true
            })
          }
        }
      }
    },
    [ApisOptions.FGTS]: (data: ProspectData) => {
      return {
        ...data
      }
    }
  }

  const [queryData, setQueryData] = useState<QueryData>()

  const queryApiUrls = useMemo(
    () => ({
      '1': '/apis/sim/listOffers',
      '2': '/apis/lemit/query',
      '3': '/apis/credcesta/queryInstallmentsPerCpf',
      '4': '/apis/fgts/simulation'
    }),
    []
  )

  useEffect(() => {
    if (initialValues) {
      if (initialValues?.birth_date) {
        setBirthDate(
          moment(initialValues?.birth_date, dateInputFormat).toDate()
        )
      }
      setDefaultValues({
        ...initialValues,
        customer: {
          name: initialValues?.name,
          email: initialValues?.contacts?.pessoa?.emails?.[0]?.email || '',
          dateOfBirthFoundation: initialValues?.birth_date,
          document: initialValues?.contacts?.pessoa?.cpf,
          cellPhone: {
            ddd: initialValues?.contacts?.pessoa?.celulares?.[0]?.ddd,
            number: phoneMask(
              initialValues?.contacts?.pessoa?.celulares?.[0]?.numero,
              {
                whitoutDDD: true
              }
            )
          }
        }
      })
    }
  }, [initialValues])

  const laodConventions = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get('/apis/credcesta/listConventions')
      const conventions = [...Object.values(response.data)] as {
        id: number
        nome: string
        limiteSaque?: number
        limiteCompra?: number
        percentualMargemSaque?: number
        percentualMargemCompra?: number
      }[]
      const sortConventios = conventions.sort(
        (currentConvention, nextConvention) =>
          currentConvention.nome.localeCompare(nextConvention.nome)
      )
      setConventions(
        sortConventios.map(
          convention =>
            convention && {
              ...convention,
              limiteSaque: convention.percentualMargemSaque,
              limiteCompra: convention.percentualMargemCompra
            }
        )
      )
      disableLoading()
    } catch (error: any) {
      // handleOnClose()
      addToast({
        type: 'error',
        title: 'Erro ao buscar convênios',
        description: error.response?.data?.message?.message
      })
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading])

  useEffect(() => {
    if (selectedApi === '3') {
      laodConventions()
    }
  }, [laodConventions, selectedApi])

  const handleQueryCpf = useCallback(async () => {
    activeLoading()
    try {
      await api.post('apis/credcesta/queryInstallmentsPerCpf', {
        cpf: unMaskCpfCnpj(defaultValues?.cpf),
        idConvenio: ID_CONVENIO_CREDCESTA.toString(),
        prospect_id: defaultValues?.id
      })
      const response = await api.post<QueryData>(
        '/apis/credcesta/queryPerCpf',
        {
          cpf: unMaskCpfCnpj(defaultValues?.cpf),
          idConvenio: ID_CONVENIO_CREDCESTA.toString(),
          prospect_id: defaultValues?.id
        }
      )
      const findMostValue = response.data.opcoes.reduce(
        (preventValue, currentValue) => {
          return Number(preventValue.qtdParcelas) >
            Number(currentValue.qtdParcelas)
            ? preventValue
            : currentValue
        }
      )
      const findConventio = conventions.find(
        item => item.idConvenio === ID_CONVENIO_CREDCESTA
      )
      const margemSaque = findConventio?.percentualMargemSaque / 100
      const vlLimite = response.data.limiteDisponivel * margemSaque
      setQueryData({
        ...response.data,
        convention: {
          ...findConventio,
          limiteCompra: findConventio.percentualMargemCompra,
          limiteSaque: findConventio.percentualMargemSaque,
          averbacaoObrigatoria: findConventio.obrigatorioIdAverbacao
        },
        prazo: findMostValue.qtdParcelas.toString(),
        vlLimite: BRL(vlLimite).format()
      })
      updateDataTable()
      handleOnClose()
      disableLoading()
    } catch (error: any) {
      const findConventio = conventions.find(
        item => item.idConvenio === ID_CONVENIO_CREDCESTA
      )
      setQueryData({
        ...queryData,
        convention: {
          ...findConventio,
          limiteCompra: findConventio?.percentualMargemCompra,
          limiteSaque: findConventio?.percentualMargemSaque,
          averbacaoObrigatoria: findConventio?.obrigatorioIdAverbacao
        }
      })
      if (
        error.response?.data?.message
          ?.toLowerCase()
          ?.includes('cliente não cadastrado')
      ) {
        setStepsRegisterCredcesta(oldStepRegisterCredcesta => ({
          ...oldStepRegisterCredcesta,
          register: true
        }))
        disableLoading()
        return
      }
      addToast({
        type: 'error',
        title: 'Erro ao buscar Cpf',
        description: error.response?.data.message
      })
      handleOnClose()
      disableLoading()
    }
  }, [
    activeLoading,
    addToast,
    conventions,
    defaultValues?.cpf,
    defaultValues?.id,
    disableLoading,
    handleOnClose,
    queryData,
    updateDataTable
  ])

  const handleCreate = useCallback(
    async (data: any) => {
      activeLoading()
      try {
        const enumValuesInputAddressEntries = Object.entries(
          enumValuesInputAddress
        )
        enumValuesInputAddressEntries.forEach(([, value]) => {
          data[value.submit] = data.address[value.input]
        })
        delete data.address
        const cpfString = unMaskCpfCnpj(defaultValues?.cpf)
        data.cdCepRes = unMaskZipCode(data.cdCepRes)
        data.celular = unMaskPhone(data.celular)
        data.cdBanco = formatBank(data.cdBanco, 3)
        data.cdAgencia = formatBank(data.cdAgencia, 4)
        data.cdDigito = data.cdDigito === '0' ? '' : data.cdDigito

        const convention = conventions.find(
          item => item.idConvenio === Number(ID_CONVENIO_CREDCESTA)
        )
        const vlRenda = convertValueMaskInNumberWithTwoZero(data.vlRenda)
        const vlMargem = vlRenda * 0.2
        const formData = {
          ...data,
          cpf: cpfString,
          gestor: '1',
          orgao: convention?.orgaos[0]?.codigo || '',
          cdLogo: convention?.logos[0]?.cdLogo || '',
          beneficiario: '0',
          enderecoCom: '',
          numeroCom: '',
          complementoCom: '',
          bairroCom: '',
          cidadeCom: '',
          ufCom: '',
          cdCepCom: '',
          foneResidencial: '',
          foneComercial: '',
          dataNascimento: birthDate?.toISOString(),
          dataExpedicaoRg: issueDate?.toISOString(),
          categoria: '',
          codigoSituacao: '',
          estabelecimento: '',
          departamento: '',
          observacao: '',
          dtInicioAdmissao: new Date().toISOString(),
          dtFinalAdmissao: new Date().toISOString(),
          vlRenda,
          dsMotivo: '',
          idConvenio: ID_CONVENIO_CREDCESTA.toString(),
          prospect_id: defaultValues?.id,
          tipoConta: 1,
          possuiRepresentanteLegal: false,
          situacaoServidor: '1',
          dadosBeneficio: {
            ufBeneficio: '',
            tipoBeneficio: {
              numero: '1',
              nome: 'INSS'
            },
            formaRecebimento:
              data.dadosBeneficio.formaRecebimento || 'contaCorrente'
          }
        }

        await api.post('/apis/credcesta/create', formData)
        await api.post('/apis/credcesta/createLimit', {
          cpf: cpfString,
          idConvenio: ID_CONVENIO_CREDCESTA.toString(),
          vlMargem
        })
        setClientData({
          ...formData,
          banco: formData.cdBanco,
          agencia: formData.cdAgencia,
          conta: formData.cdConta,
          numero: formData.numeroRes,
          idAverbacao: formData.matriculaAverbacao
        })
        await handleQueryCpf()

        addToast({
          type: 'success',
          title: 'Cliente Registrado',
          description: 'Cliente Registrado com sucesso'
        })
        disableLoading()
      } catch (error: any) {
        console.log(error)
        // handleOnClose()
        addToast({
          type: 'error',
          title: 'Erro ao Registrar',
          description:
            error?.response?.data?.message?.Mensagem ||
            'Ocorreu um erro ao registrar'
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      birthDate,
      conventions,
      defaultValues?.cpf,
      defaultValues?.id,
      disableLoading,
      enumValuesInputAddress,
      handleQueryCpf,
      issueDate
    ]
  )

  const onSubmitForm = useCallback(
    async (data: ProspectData) => {
      if (stepsRegisterCredcesta.register) {
        await handleCreate(data)
        return
      }
      const selectedApiKeyof = selectedApi as '1' | '2' | '3' | '4'
      const dataForm = {
        [ApisOptions.SIM]: {
          customer: {
            ...data?.customer,
            dateOfBirthFoundation: moment(
              defaultValues?.customer?.dateOfBirthFoundation,
              'DD/MM/YYYY'
            )
              .add(3, 'hours')
              .format('YYYY-MM-DD'),
            document: unMaskCpfCnpj(defaultValues?.cpf),
            name: defaultValues?.name
          },
          prospect_id: defaultValues?.id,
          prospect_query_api_id: defaultValues?.prospect_query_api_id
        },
        [ApisOptions.LEMIT]: {
          document: defaultValues?.cpf,
          prospect_id: defaultValues?.id,
          type: TypePerson.PF
        },
        [ApisOptions.FGTS]: {
          cpf: defaultValues?.cpf,
          dataNascimento: birthDate,
          prospect_id: defaultValues?.id
        }
      }
      activeLoading()

      try {
        if (selectedApiKeyof === ApisOptions.CREDCESTA) {
          await handleQueryCpf()
          return
        } else {
          const responseApi = await api.post(
            queryApiUrls[selectedApiKeyof],
            dataForm[selectedApiKeyof]
          )
          if (selectedApiKeyof === ApisOptions.FGTS) {
            if (responseApi.data?.status?.erros?.erro?.[0]?.mensagem) {
              addToast({
                type: 'error',
                title: 'Erro ao registrar',
                description:
                  responseApi.data?.status?.erros?.erro?.[0]?.mensagem?.replaceAll(
                    /\r\n/g,
                    ' '
                  )
              })
              handleOnClose()
              disableLoading()
              updateDataTable()
              return
            }
          }
        }
        handleOnClose()
        disableLoading()
        updateDataTable()
        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Registro criado com sucesso'
        })
      } catch (error: any) {
        if (selectedApiKeyof !== ApisOptions.CREDCESTA) {
          handleOnClose()
        }
        addToast({
          ...handleErrorMessage(error)
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      birthDate,
      defaultValues?.cpf,
      defaultValues?.customer?.dateOfBirthFoundation,
      defaultValues?.id,
      defaultValues?.name,
      defaultValues?.prospect_query_api_id,
      disableLoading,
      handleCreate,
      handleOnClose,
      handleQueryCpf,
      queryApiUrls,
      selectedApi,
      stepsRegisterCredcesta.register,
      updateDataTable
    ]
  )
  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <div className="form">
        <div className="row">
          <Select
            name=""
            label="Escolha o banco"
            options={generateOptions({
              user,
              options: [
                {
                  name: 'Sim',
                  value: ApisOptions.SIM
                },
                {
                  name: 'Lemit',
                  value: ApisOptions.LEMIT
                },
                {
                  name: 'Credcesta',
                  value: ApisOptions.CREDCESTA
                },
                {
                  name: 'Fgts',
                  value: ApisOptions.FGTS
                }
              ]
            })}
            value={selectedApi}
            onChange={event => {
              const value = event.target
                .value as keyof typeof handleSelectedApiDefaultValues
              setDefaultValues(
                handleSelectedApiDefaultValues[value]?.(initialValues) ||
                  defaultValues
              )
              setSelectedApi(value)
            }}
            blank
            className="col-4"
            controlled
            disabled={!!selectedApi}
            rules={{ required: true }}
          />
          {selectedApi === ApisOptions.FGTS && (
            <DatePicker
              name="birth_date"
              className="col-md-3"
              label="Data de Nascimento"
              placeholderText="DD/MM/AAAA"
              dateFormat="dd/MM/yyyy"
              selected={birthDate}
              rules={{ required: true }}
              customInput={
                <IMaskInput
                  mask={Date}
                  pattern={'d/m/Y'}
                  format={date => {
                    return moment(date).format(MASKS.DATE.LOCALE)
                  }}
                  parse={value => {
                    return moment(value, MASKS.DATE.LOCALE).toDate()
                  }}
                  validate={(date: string) => {
                    if (date.length === 10) {
                      if (moment(date, MASKS.DATE.LOCALE).isAfter(moment())) {
                        return false
                      }
                      const age = moment().diff(
                        moment(date, MASKS.DATE.LOCALE),
                        'year'
                      )
                      if (age < 18) {
                        return false
                      }
                    }
                    return true
                  }}
                />
              }
              onChange={date => {
                setBirthDate(date)
              }}
              controlled
            />
          )}
        </div>

        {selectedApi === ApisOptions.SIM && (
          <div>
            <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
              <Input
                name="customer.email"
                label="Email"
                rules={{
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email inválido'
                  }
                }}
                className="col-6"
              />
              <Input
                name="customer.cellPhone.ddd"
                label="DDD"
                mask={{
                  mask: '99'
                }}
                controlled
                className="col-2"
                rules={{ required: true }}
              />
              <Input
                name="customer.cellPhone.number"
                label="Número"
                mask={{
                  mask: '99999-9999'
                }}
                controlled
                className="col-2"
                rules={{
                  required: true,
                  minLength: { message: 'Número Invalido', value: 10 }
                }}
              />
            </div>
          </div>
        )}
        {selectedApi === ApisOptions.CREDCESTA &&
          stepsRegisterCredcesta.register && (
            <div className="mt-5">
              <h1 className={'mt-' + SPACING_ROW_FORM}>Cliente sem Cadastro</h1>

              <div className="form">
                <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
                  <Input
                    className="col-4"
                    label="Nome"
                    name="nome"
                    rules={{
                      required: true,
                      maxLength: {
                        value: 35,
                        message: 'Nome tem que ter no máximo 35 caracteres'
                      }
                    }}
                  />
                  <DatePicker
                    name="dataNascimento"
                    className="col-2  "
                    label="Data Nascimento"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/AAAA"
                    selected={birthDate}
                    rules={{ required: true }}
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(dateInputFormat)
                        }}
                        parse={value => {
                          return moment(value, dateInputFormat).toDate()
                        }}
                      />
                    }
                    onChange={date => {
                      setBirthDate(date)
                    }}
                    controlled
                  />
                  <Select
                    className="col-2  "
                    name="nacionalidade"
                    rules={{ required: true }}
                    label="Nacionalidade"
                    options={[
                      { name: 'Brasileira', value: 'brasileira' },
                      { name: 'Estrangeira', value: 'estrangeira' }
                    ]}
                  />
                  <Input
                    className="col-2  "
                    label="Naturalidade"
                    name="naturalidade"
                    rules={{ required: true }}
                  />
                  <Select
                    className="col-2  "
                    name="flSexo"
                    label="Sexo"
                    rules={{ required: true }}
                    options={[
                      { name: 'Masculino', value: 'M' },
                      { name: 'Feminino', value: 'F' }
                    ]}
                  />
                </div>
                <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
                  <Select
                    className="col-2  "
                    label="Estado Civil"
                    name="estadoCivil"
                    rules={{ required: true }}
                    defaultValue=""
                    blank
                    options={optionsMaritalStatus}
                  />
                  <Input
                    className="col-2  "
                    label="Nº RG"
                    name="cdRg"
                    rules={{ required: true }}
                  />
                  <Input
                    className="col-2  "
                    label="Emissor"
                    name="emissorRg"
                    rules={{ required: true }}
                  />
                  <Select
                    className="col-2  "
                    label="UF de Emissão"
                    name="ufEmissorRg"
                    rules={{ required: true }}
                    defaultValue=""
                    blank
                    options={[...states]}
                  />
                  <DatePicker
                    name="dataExpedicaoRg"
                    className="col-2 "
                    label="Data Emissão"
                    dateFormat="dd/MM/yyyy"
                    selected={issueDate}
                    rules={{ required: true }}
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern={'d/m/Y'}
                        format={date => {
                          return moment(date).format(dateInputFormat)
                        }}
                        parse={value => {
                          return moment(value, dateInputFormat).toDate()
                        }}
                      />
                    }
                    onChange={date => {
                      setIssueDate(date)
                    }}
                    controlled
                  />
                </div>
                <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
                  <Input
                    className="col-4  "
                    label="Nome da mãe"
                    name="nomeMmae"
                    rules={{ required: true }}
                  />
                  <Input
                    className="col-2  "
                    label="Celular"
                    name="celular"
                    rules={{ required: true }}
                    mask={{
                      mask: '(99) 99999-9999'
                    }}
                    controlled
                  />
                  <Input
                    className="col-2  "
                    label="Email"
                    name="email"
                    rules={{
                      required: true,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Email inválido'
                      }
                    }}
                  />
                  <Input
                    className="col-2  "
                    label="Profissão"
                    name="profissao"
                    rules={{ required: true }}
                  />
                  <Input
                    className="col-2  "
                    label="Cargo"
                    name="cargo"
                    rules={{ required: true }}
                  />
                </div>
                <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
                  <Input
                    className="col-2  "
                    label="Renda (Líquida)"
                    name="vlRenda"
                    rules={{ required: true }}
                    price
                  />
                  <Select
                    className="col-2  "
                    label="Forma de recebimento"
                    name="dadosBeneficio.formaRecebimento"
                    rules={{ required: true }}
                    defaultValue=""
                    blank
                    options={[
                      {
                        name: 'Conta corrente',
                        value: 'contaCorrente'
                      },
                      {
                        name: 'Cartão magnético',
                        value: 'cartaoMagnetico'
                      }
                    ]}
                  />
                  {!!queryData?.convention?.averbacaoObrigatoria && (
                    <Input
                      className="col-2 "
                      label="Id. Averbação"
                      name="matriculaAverbacao"
                      rules={{
                        required: queryData?.convention?.averbacaoObrigatoria
                      }}
                    />
                  )}
                </div>
                <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
                  <h1 className={'mt-' + SPACING_ROW_FORM}>Dados Endereço</h1>
                  <Input
                    className="col-2  "
                    label="CEP"
                    name="cdCepRes"
                    mask={{
                      mask: '99999-999',
                      type: 'zipCode',
                      prefixInputsData: 'address.'
                      // excludeFields: [stateFields.address, stateFields.birth]
                    }}
                    controlled
                    rules={{
                      required: true,
                      minLength: { value: 8, message: 'Cep Inválido' }
                    }}
                  />
                  <Input
                    className="col-2  "
                    label="Cidade"
                    name={`address.${enumValuesInputAddress.city.input}`}
                    rules={{ required: true }}
                  />
                  <Input
                    className="col-2  "
                    label="Bairro"
                    name={`address.${enumValuesInputAddress.district.input}`}
                    rules={{ required: true }}
                    maxLength={25}
                  />
                  <Input
                    className="col-2  "
                    label="Logradouro"
                    name={`address.${enumValuesInputAddress.street.input}`}
                    rules={{ required: true }}
                  />

                  <Input
                    className="col-2"
                    label="Número"
                    name="numeroRes"
                    rules={{ required: true }}
                  />
                  <Select
                    className="col-2"
                    label="UF"
                    name={`address.${enumValuesInputAddress.state.input}`}
                    rules={{ required: true }}
                    options={[...states]}
                    blank
                    defaultValue=""
                  />
                </div>
                <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
                  <Input
                    className="col-4"
                    label="Complemento"
                    name="complementoRes"
                  />
                </div>
                <div className={'row ' + 'mb-' + SPACING_ROW_FORM}>
                  <h1 className={'mt-' + SPACING_ROW_FORM}>
                    Dados de Liberação Financeira
                  </h1>
                  <Input
                    className="col-1 "
                    name="cdBanco"
                    label="Banco"
                    rules={{
                      required: true,
                      maxLength: { value: 3, message: 'No máximo 3 caracteres' }
                    }}
                  />
                  <Input
                    className="col-1  "
                    name="cdAgencia"
                    label="Agência"
                    rules={{
                      required: true
                    }}
                  />
                  <Input
                    className="col-2  "
                    name="cdConta"
                    label="Conta Corrente"
                    rules={{ required: true }}
                  />
                  <Input
                    className="col-2  "
                    name="cdDigito"
                    label="Dígito"
                    rules={{ required: true }}
                  />
                  <Input
                    className="col-2  "
                    label="Matrícula"
                    name="matricula"
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
      <div className="card-footer d-flex justify-content-end py-6 px-9">
        <button type="submit" className="btn btn-primary">
          {stepsRegisterCredcesta.register ? 'Registrar' : 'Consultar'}
        </button>
      </div>
    </Form>
  )
}
