import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/Reports/List'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ReportsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/reports/partners" exact component={List} />
  </CustomSwitch>
)
