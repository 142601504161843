import { ToolsContainerProps } from '../../../../../../components/Container'
import { nameActions } from '../../info'

export const toolsUpdate: ToolsContainerProps[] = [
  {
    name: 'Listar',
    to: nameActions.read.to,
    icon: nameActions.read.icon
  }
]
