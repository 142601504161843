import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/Partners/List'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PartnersRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/partners/block" exact component={List} />
  </CustomSwitch>
)
