import React, { useCallback } from 'react'

type Props = {
  title: string
  active: boolean
  index: number
  setSelectedTab: (index: number) => void
}

const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  index,
  active
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <li className="nav-item mt-2">
      <a
        onClick={onClick}
        className={`nav-link text-active-primary ms-0 me-10 py-5 ${
          active && 'active'
        }`}
        style={{ cursor: 'pointer' }}
      >
        {title}
      </a>
    </li>
  )
}

export default TabTitle
