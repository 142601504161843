import Container from 'components/Container'
import { Datalist } from 'components/Datalist'
import Form, { Input } from 'components/Form'
import { Loading } from 'components/Loading'
import { useToast } from 'hooks/toast'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
const Query = (): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()
  const [agreementGroups, setAgreementGroups] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const [agreementGroupValue, setAgreementGroupValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const getAgreementGroups = useCallback(async () => {
    setLoading(true)
    const response = await api.get('/operational/agreementGroups')
    const agreementGroupsSelect = response.data?.map(
      (agreementGroup: { id: number; name: string }) => ({
        value: agreementGroup.id,
        name: agreementGroup.name,
        id: agreementGroup.id
      })
    )
    setAgreementGroups(agreementGroupsSelect)
    setLoading(false)
  }, [])

  useEffect(() => {
    getAgreementGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = useCallback(
    async data => {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', data?.file?.[0])
      formData.append('agreement_id', data?.agreement_id)
      formData.append('agreement_group_id', agreementGroupValue?.id?.toString())
      try {
        const date = moment().format('DD-MM-YYYY')
        // await api.post('/apis/credcesta/query', formData)
        const response = await api.post('/apis/credcesta/exportquery', formData)
        const blob = new Blob(['\ufeff' + response.data], {
          type: 'text/csv;charset=utf-8"'
        })
        const url = window.URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.download = `consulta-banco-master-${date}.csv`
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch (error: any) {
        console.log(error)
        addToast({
          title: 'Erro ao exportar',
          description: 'Erro ao exportar',
          type: 'error'
        })
      }
      setLoading(false)
    },
    [addToast, agreementGroupValue?.id]
  )

  if (loading) {
    return <Loading isActive />
  }
  return (
    <Container pageTitle="Listagem" breadcrumb={[]} tools={[]}>
      <Form onSubmit={onSubmit}>
        <div className="row mb-5">
          <Datalist
            className="col-3"
            data={agreementGroups}
            label="Grupo de Convênio"
            setValue={setAgreementGroupValue}
            value={agreementGroupValue}
          />
          <Input
            className="col-md-3"
            name="agreement_id"
            label="Cod. Convênio"
          />
          <Input
            className="col-md-3"
            name="file"
            label="Arquivo"
            rules={{ required: true }}
            type="file"
          />
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </Form>
    </Container>
  )
}
export default Query
