import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useToast } from '../../hooks/toast'
export const NotFoundPage: React.FC = () => {
  const { addToast } = useToast()
  useEffect(() => {
    addToast({
      title: 'Página não Encontrada',
      description: 'A Página que tentou acessar não foi encontrada',
      type: 'error'
    })
  }, [])
  return <Redirect to="/" />
}
